import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router';
import { API } from '../../../../../../api';
import ActiveUser from '../../../home/cards/ActiveUser';
import ProgramRoi from '../../../home/cards/ProgramRoi';
import GoalProgress from '../../../home/cards/GoalProgress';
import ExperiencePoints from '../../../home/cards/ExperiencePoints';
import { getUserDetails } from '../../../../../../utils/orgName';
import { useAppSelector } from '../../../../../../redux/hooks';
import MySkeleton from '../../../../../../components/Skeleton';

interface EngagementMetricsProps {
  showActiveUser?: boolean;
  showProgramRoi?: boolean;
  showGoalProgress?: boolean;
  showExperiencePoints?: boolean;
}

const EngagementMetrics: React.FC<EngagementMetricsProps> = ({
  showActiveUser = true,
  showProgramRoi = true,
  showGoalProgress,
  showExperiencePoints = true
}) => {
  const groupData = useAppSelector((state) => state.groupDetails);
  const [currencySymbol, setCurrencySymbol] = useState('');
  const { location } = getUserDetails();
  const [goalProgressValue, setGoalProgressValue] = useState(0);
  const [activeUserCount, setActiveUserCount] = useState(0);
  const [programRoiLoading, setProgramRoiLoading] = useState(true);
  const [programRoiValue, setRoiValue] = useState(0);
  const [xpPointsValue, setXpPoints] = useState(0);
  const orgId = useParams().orgId || '';
  let grpId = useParams().id || '';
  if (grpId === 'admin') {
    grpId = '';
  }

  const orgCurrency = useAppSelector((state) => state.orgDetails.data?.currency);

  const [hasShowGoalProgressValue, setHasShowGoalProgressValue] = useState<any>();
  const [overviewLoader, setOverviewLoader] = useState<boolean>(true);

  const fetchProgressChartValue = async (orgId: string, grpId: string, loc: string) => {
    setProgramRoiLoading(true);
    try {
      const data = { orgId, grpId, loc };
      const response = await API.getGroupsOverview(data); // Replace with your API call
      if (response.status === 200) {
        setOverviewLoader(false);
        setRoiValue(response?.data?.data?.programROI || 0);
        setGoalProgressValue(response?.data?.data?.goalProgress || 0);
        setActiveUserCount(response?.data?.data?.activeUsers || 0);
        setXpPoints(response?.data?.data?.totalXpPoint || 0);
        setHasShowGoalProgressValue(response?.data?.data?.showGoalProgress);
      }
    } catch (e) {
    } finally {
      setProgramRoiLoading(false);
    }
  };

  useEffect(() => {
    setCurrencySymbol(orgCurrency);
  }, [orgCurrency]);

  useEffect(() => {
    if (groupData?.data) {
      const { currency } = groupData.data;
      // setCurrencySymbol(orgCurrency);
    }
    fetchProgressChartValue(orgId, grpId, location);
  }, [groupData.data, grpId, location, orgId]);

  /** Count the number of true values */
  const trueValues = [showActiveUser, showProgramRoi, hasShowGoalProgressValue, showExperiencePoints].filter(
    Boolean
  ).length;
  const loading = overviewLoader;

  /** Skeleton loader default we made 4 cards */
  const skeletonLoader = () =>
    Array.from({ length: 4 }).map((_, index) => (
      <React.Fragment key={index}>
        <Grid item xs={12} md={6} lg={12 / 4}>
          <MySkeleton variant="rounded" height={245} />
        </Grid>
      </React.Fragment>
    ));

  return (
    <>
      {loading ? (
        skeletonLoader()
      ) : (
        <>
          {showActiveUser && (
            <Grid item xs={12} md={6} lg={12 / trueValues}>
              <ActiveUser activeUserCount={activeUserCount} />
            </Grid>
          )}
          {showProgramRoi && (
            <Grid item xs={12} md={6} lg={12 / trueValues}>
              <ProgramRoi
                programRoi={programRoiValue}
                programRoiLoading={programRoiLoading}
                currencySymbol={currencySymbol}
              />
            </Grid>
          )}
          {/* Goal progress chart */}
          {hasShowGoalProgressValue && (
            <Grid item xs={12} md={6} lg={12 / trueValues}>
              <GoalProgress goalProgressValue={goalProgressValue} />
            </Grid>
          )}
          {showExperiencePoints && (
            <Grid item xs={12} md={6} lg={12 / trueValues}>
              <ExperiencePoints value={xpPointsValue} />
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default EngagementMetrics;
