/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/iframe-missing-sandbox */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useEffect } from 'react';
import _ from 'lodash';
import { Box, Link, Badge, Stack, Button, Divider, Typography } from '@mui/material';
import {
  EyeIcon,
  // ShareIcon,
  ThumbsupFullGreyIcon
} from '../../../../../utils/Icons';
import MyChip from '../../../components/MyChip';
import { CommonStyle } from '../../../../../utils/muiStyle';
import { IArticleData } from '../LibraryTab';
import handthumbsdown from './../../../../../assets/images/hand-thumbs-down.svg';
import handthumbsupfill from './../../../../../assets/images/hand-thumbs-up-fill.svg';
import handthumbsup from './../../../../../assets/images/hand-thumbs-up.svg';
import handthumbsdownfill from './../../../../../assets/images/hand-thumbs-down-fill.svg';
import AudioPlayer from './AudioPlayer';
import { FileOutlined } from '@ant-design/icons';
import HelpConcern from '../../Help/HelpConcern';
import { useXpPoints } from '../../../components/menteeXpPoints';
import useProfileDetails from '../../../../../hooks/useProfileDetails';
import { useAppSelector } from '../../../../../redux/hooks';
import { LibraryRightBarLoader } from './LibraryLoader';
import ChatAvatarComponent from '../../chat/ChatAvatarComponent';
import { formatNumberWithCommas, industryArray, topicArray } from '../../../components/common';

interface LibraryDetailsProps {
  articleDetails: IArticleData | undefined;
  selectedArticleId: string;
  handleLikeUp: () => Promise<void>;
  handleLikeDown: () => Promise<void>;
  likeStatus: string;
  isVoted: any;
  loading: boolean;
  loadingDown: boolean;
  isconcernData?: boolean;
  articleDetailsLoading: boolean;
}

const returnArticleText = (articleDetails: any) => {
  const html: string = articleDetails ? articleDetails?.articleDescription : '';

  if (!html) {
    return '';
  }

  const urlRegex =
    /(?<!['"])(http(s)?:\/\/)?[a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}[-a-zA-Z0-9@:%_\+.~#?&=,;\/-]*(?![^<]*>)/g;
  // Replace matched URLs with anchor tags
  const linkifiedHTML = html.replace(urlRegex, (url: string) => {
    const link: string = url.startsWith('http') ? url : `http://${url}`;
    return `<a href="${link}" target="_blank">${url}</a>`;
  });

  return linkifiedHTML;
};

const LibraryDetails = ({
  articleDetails,
  handleLikeUp,
  handleLikeDown,
  likeStatus,
  isVoted,
  loading,
  loadingDown,
  isconcernData,
  articleDetailsLoading
}: LibraryDetailsProps) => {
  const classes = CommonStyle();
  const getXpPoints = useXpPoints();
  const { mentee } = useProfileDetails();
  const selectedGroup = useAppSelector((state) => state.selectedGroup.data);

  useEffect(() => {
    if (articleDetails && selectedGroup?.role === 'mentee') {
      getXpPoints('mentee');
    }
  }, [articleDetails]);

  if (articleDetailsLoading) {
    return <LibraryRightBarLoader />;
  }

  if (isconcernData) {
    return (
      <>
        <HelpConcern />
      </>
    );
  }
  const topics = topicArray();
  const industries = industryArray();
  const camelCaseToCapital = (topicsArray: string[]) => {
    return topicsArray.map((tag: string) => {
      const index = topics.findIndex((each) => each.name === tag);
      if (index !== -1) {
        return topics[index].label;
      } else {
        return tag;
      }
    });
  };
  const camelCaseToCapitalIndustries = (industriesArray: string[]) => {
    return industriesArray.map((tag: string) => {
      const index = industries.findIndex((each) => each.name === tag);
      if (index !== -1) {
        return industries[index].label;
      } else {
        return tag;
      }
    });
  };
  return (
    <>
      {articleDetails && (
        <Box p={2}>
          <Typography variant="h3">{articleDetails?.articleName}</Typography>
          {articleDetails.accessFor !== 'Public' && (
            <Badge
              sx={{
                padding: '2px 9px',
                borderRadius: '5px',
                border: '1px solid #E99940',
                color: '#E99940',
                fontFamily: 'Open Sans',
                fontSize: '12px',
                textTransform: 'capitalize',
                fontWeight: '600',
                mt: 1
              }}
            >
              Community Exclusive Content
            </Badge>
          )}
          <Typography mt={1}>
            {articleDetails?.createdAt && (
              <span>
                {new Date(articleDetails.createdAt * 1000).toLocaleDateString('en-US', {
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric'
                })}
              </span>
            )}
            &nbsp; • &nbsp;{articleDetails?.readTime} read
          </Typography>
          <Box my={1}>
            {articleDetails && (
              <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1}>
                {articleDetails?.tags &&
                  articleDetails?.industries &&
                  camelCaseToCapital(articleDetails?.tags)
                    .concat(camelCaseToCapitalIndustries(articleDetails?.industries))
                    .map((ele: any, index: number) => (
                      <React.Fragment key={ele}>
                        <MyChip label={ele} color="#68717A" backgroundColor="#EFF0F4" />
                      </React.Fragment>
                    ))}
              </Stack>
            )}
          </Box>
          <Stack direction="row" alignItems="center" spacing={1} mt={2}>
            <Box>
              <ChatAvatarComponent
                image={articleDetails?.coverImageUrl}
                firstLetter={articleDetails?.authorName?.slice(0, 1)}
                width="28px"
                height="28px"
                cursor="pointer"
                type="noStatus"
              />
            </Box>
            <Box>
              <Typography color="primary">{articleDetails?.authorName}</Typography>
            </Box>
          </Stack>

          <Divider className="my-20" />

          <Box>
            <Box mb={2}>
              {/* Image */}
              {/* {articleDetails?.type === 'Text' && (
                <img src={articleDetails?.coverImageUrl} alt="resumeimg" className="LibraryImage" />
              )} */}
              {/* Video */}
              {articleDetails?.type === 'Video' && (
                <Box width="50%">
                  <iframe
                    title="LibraryVideo"
                    className="LibraryVideoAndImage"
                    src={articleDetails?.fileUrl} // url for the video file
                    frameBorder="0"
                    allowFullScreen
                  />
                </Box>
              )}
              {/* Audio */}
              {articleDetails?.type === 'Audio' && <AudioPlayer src={articleDetails?.fileUrl} />}
            </Box>

            {/* Url or file */}
            {articleDetails?.type === 'Text' && articleDetails?.fileUrl && _.size(articleDetails?.fileUrl) && (
              <Box mt={2}>
                <Link
                  onClick={() =>
                    articleDetails?.fileUrl?.includes('http') ? window.open(articleDetails?.fileUrl, '_blank') : {}
                  }
                  className='color-blue'
                >
                  <FileOutlined /> {articleDetails?.articleName}
                </Link>
              </Box>
            )}

            <Box mt={2}>
              <Box
                className="ArticleBodyContent preview-article-body-content"
                dangerouslySetInnerHTML={{
                  __html: returnArticleText(articleDetails)
                }}
              />
            </Box>
          </Box>
          {articleDetails?.embeddedLinks && (
            <Box mt={2}>
              {articleDetails.embeddedLinks.split(',').map((link, index) => {
                const trimmedLink = link.trim();
                const extension = trimmedLink.split('.').pop()?.toLowerCase();

                if (extension === 'jpg' || extension === 'jpeg' || extension === 'png') {
                  return (
                    <Box key={index}>
                      <img src={trimmedLink} alt={`Embedded Image ${index}`} className="LibraryVideoAndImage" />
                    </Box>
                  );
                } else {
                  return (
                    <Box key={index}>
                      <iframe
                        title={`Embedded Document ${index}`}
                        src={trimmedLink}
                        className="LibaryEmbeddedDocument"
                        allowFullScreen
                      />
                    </Box>
                  );
                }
              })}
            </Box>
          )}

          <Stack direction="row" alignItems="center" spacing={1} mt={2}>
            <Button className={classes.LikeViewShareButton}>
              <ThumbsupFullGreyIcon width={12} height={15} /> {formatNumberWithCommas(articleDetails?.upVotes)}
            </Button>
            <Button className={classes.LikeViewShareButton}>
              <ThumbsupFullGreyIcon width={12} height={15} style={{ transform: 'rotate(180deg)' }} />
              {formatNumberWithCommas(articleDetails?.downVotes)}
            </Button>
            <Button className={classes.LikeViewShareButton}>
              <EyeIcon size={17} /> {formatNumberWithCommas(articleDetails?.views)}
            </Button>
            {/* <Button className={classes.LikeViewShareButton}>
              <ShareIcon size={13} /> Share
            </Button> */}
          </Stack>

          <Divider className="my-20" />

          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography color="primary">
              Was this content helpful?
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              {/* Like */}
              {likeStatus === 'upVote' ? (
                loading ? (
                  <img src={handthumbsupfill} className="cursor-pointer" alt="hand-thumbs-down-fill" />
                ) : (
                  <img
                    src={handthumbsupfill}
                    onClick={handleLikeUp}
                    className="cursor-pointer"
                    alt="hand-thumbs-down-fill"
                  />
                )
              ) : loading ? (
                <img src={handthumbsup} alt="hand-thumbs-down-fill" className="cursor-pointer opacity-25" />
              ) : (
                <img
                  src={handthumbsup}
                  onClick={handleLikeUp}
                  alt="hand-thumbs-down-fill"
                  className="cursor-pointer opacity-25"
                />
              )}

              {/* Dislike */}
              {likeStatus === 'downVote' ? (
                loadingDown ? (
                  <img src={handthumbsdownfill} className="cursor-pointer" alt="hand-thumbs-down-fill" />
                ) : (
                  <img
                    src={handthumbsdownfill}
                    onClick={handleLikeDown}
                    className="cursor-pointer"
                    alt="hand-thumbs-down-fill"
                  />
                )
              ) : loadingDown ? (
                <img src={handthumbsdown} alt="hand-thumbs-down-fill" className="cursor-pointer opacity-25" />
              ) : (
                <img
                  src={handthumbsdown}
                  onClick={handleLikeDown}
                  alt="hand-thumbs-down-fill"
                  className="cursor-pointer opacity-25"
                />
              )}
            </Stack>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default LibraryDetails;
