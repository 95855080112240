/* eslint-disable react-hooks/exhaustive-deps */
import { Navigate } from 'react-router-dom'; // Updated import
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../redux/hooks';
import AppLoader from '../components/AppLoader';
import { getUserDetails } from '../utils/orgName';
import { breadCrumbsActions } from '../redux/slices/breadcrumbs/breadcrumbsSlice';
import ROLES from '../utils/roles';
import ErrorPage from '../components/ErrorPage';
// import { updateSelectedId } from '../redux/slices/selectedGroupSlice';
import { AGILE_ORG } from '../utils/constants';
import { groupLoaderActions } from '../redux/slices/groupLoaderSlice';

const RoleRedirect = () => {
  const orgLevelGroup = useAppSelector((state) => state.orgLevelGroup);
  const userProfile = useAppSelector((state) => state.userProfile);
  const dispatch = useAppDispatch();
  const groupList = useAppSelector((state) => state.groupRoleList.data);
  const oid = useAppSelector((state) => state.orgDetails.data?.oid);
  const redirect = () => {
    const { orgId, coId } = getUserDetails();
    const dispatchBreadCrumbs = (id?: string) => {
      const grpId = id ? id : 'admin';
      dispatch(
        breadCrumbsActions.removeBreadCrumbs({
          id: grpId,
          name: 'Program',
          url: `/app/programdetails/${orgId}/${coId}/${grpId}/overview`
        })
      );
    };

    const role = localStorage.getItem('role') || '';

    if (role === ROLES.platAdmin) return <Navigate to="/app/organizations" />;
    // if (role === ROLES.orgAdmin) {
    //   if (orgLevelGroup.loading) return <AppLoader />;
    //   if (orgLevelGroup.error) return <ErrorPage message={orgLevelGroup.errorText} />;
    //   if (orgLevelGroup.data) {
    //     dispatchBreadCrumbs(orgLevelGroup.data.grpId);
    //     return <Navigate to={`/app/programdetails/${orgId}/${coId}/${orgLevelGroup.data.grpId}/overview`} />;
    //   }
    //   return <ErrorPage message="Something Went Wrong" />;
    // }

    if (role === ROLES.member || role === ROLES.orgAdmin) {
      if (userProfile.loading) return <AppLoader />;
      if (userProfile.error) return <ErrorPage message={userProfile.errorText} />;
      if (userProfile.data) {
        const userLastseen = localStorage.getItem('lastseen');
        const lastseen = userLastseen ? JSON.parse(userLastseen) : null;
        let [id, role, active] = ['', '', true];
        if (lastseen && Object.keys(lastseen).length > 0) {
          if (lastseen.id && lastseen.role) {
            const index = groupList?.findIndex((each) => each.id === lastseen.id && each.role === lastseen.role);
            if (index !== -1) {
              id = lastseen.id || '';
              role = lastseen.role || '';
              active = _.get(lastseen, 'active', true);
            } else {
              localStorage.removeItem('lastseen');
            }
          }
        } else {
          id = userProfile.data.lastseen?.id || '';
          role = userProfile.data.lastseen?.role || '';
          active = _.get(userProfile, 'data.lastseen.active', true);
        }
        if (!active) {
          return <Navigate to="/app/deactivated" />;
        }
        if (role) {
          if (role === 'admin' && id === '') {
            dispatchBreadCrumbs();
            dispatch(groupLoaderActions.updateState(true));
            return <Navigate to={`/app/programdetails/${orgId}/${coId}/admin/overview`} />;
          }
          if (role === 'admin' && id) {
            if (orgLevelGroup.loading) return <AppLoader />;
            if (orgLevelGroup.error) return <ErrorPage message={orgLevelGroup.errorText} />;
            if (orgLevelGroup.data) {
              dispatchBreadCrumbs(orgLevelGroup.data.grpId);
              dispatch(groupLoaderActions.updateState(true));
              return <Navigate to={`/app/programdetails/${orgId}/${coId}/${orgLevelGroup.data.grpId}/overview`} />;
            }
          }
          if (role === 'mentee' || (role === 'mentor' && id)) {
            // dispatch(updateSelectedId(userProfile.data.lastseen));
            if (oid === AGILE_ORG && role === 'mentee') {
              return <Navigate to="/app/community" />;
            }
            return <Navigate to={`/app/home/${role}`} />;
          }
        }
        const userRole = localStorage.getItem('role');
        if (userRole === ROLES.orgAdmin) {
          if (orgLevelGroup.loading) return <AppLoader />;
          if (orgLevelGroup.error) return <ErrorPage message={orgLevelGroup.errorText} />;
          if (orgLevelGroup.data) {
            dispatchBreadCrumbs(orgLevelGroup.data.grpId);
            dispatch(groupLoaderActions.updateState(true));
            return <Navigate to={`/app/programdetails/${orgId}/${coId}/${orgLevelGroup.data.grpId}/overview`} />;
          }
          return <ErrorPage message="Something Went Wrong" />;
        }
        if (_.isArray(userProfile?.data?.admins) && userProfile?.data?.admins?.length > 0) {
          dispatchBreadCrumbs();
          dispatch(groupLoaderActions.updateState(true));
          return <Navigate to={`/app/programdetails/${orgId}/${coId}/admin/overview`} />;
        } else if (_.isArray(userProfile?.data?.mentors) && userProfile?.data?.mentors?.length > 0) {
          return <Navigate to="/app/home/mentor" />;
        } else if (_.isArray(userProfile?.data?.mentees) && userProfile?.data?.mentees?.length > 0) {
          if (oid === AGILE_ORG) {
            return <Navigate to="/app/community" />;
          }
          return <Navigate to="/app/home/mentee" />;
        } else {
          return <Navigate to="/app/chat" replace />;
        }
      }
      // return null;
      return <ErrorPage message="Failed to get member profile" />;
    }

    return <ErrorPage message="This member does not have a role associated" />;
  };

  return redirect();
};

export default RoleRedirect;
