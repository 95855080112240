import * as amplitude from '@amplitude/analytics-browser';
import ROLES from './roles';
import { taskCompleted } from '@reduxjs/toolkit/dist/listenerMiddleware/exceptions';
import { getUserDetails } from './orgName';

export const events = {
  messageSent: 'Message Sent',
  matchRequested: 'Match Requested',
  matchByAdmin: 'Match Created (Admin)',
  matchByRequest: 'Match Created (Request)',
  profileViewed: 'Profile Viewed',
  articleCreated: 'Content Article Created',
  taskCompleted: 'Task Completed',
  levelCompleted: 'Level Completed',
  meetingScheduled: 'Meeting Scheduled'
};

export const blockedMails = ['@yopmail.com', '@mailinator.com', '@tempmail.com', '@dosen.io'];

export const eventTracking = (eventName: string, eventProperties: any) => {
  const role = localStorage.getItem('role') || '';
  const { email } = getUserDetails();
  if (role && role !== ROLES.platAdmin && !blockedMails.some((mail) => email.includes(mail))) {
    amplitude.track(eventName, eventProperties);
  }
};
