import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { Button, Typography, DialogContent, DialogActions } from '@mui/material';
import { API } from '../../../api';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { getUserDetails } from '../../../utils/orgName';
import { dialogActions } from '../../../redux/slices/dialog-slice/dialogSlice';
import { useQueryClient } from '@tanstack/react-query';
import { acsMessageActions } from '../../../redux/slices/acs-message/acsMessage';

const AcsDeleteMessageDialog = () => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.dialogActions.dialogDetails);
  const selectedChatUser = useAppSelector((state) => state.selectedChatUser.data);

  const handleMessageDelete = async () => {
    const messageId = _.get(data, 'message.id', '');
    try {
      const { status } = await API.deleteMessage({
        orgId: getUserDetails().orgId,
        threadId: _.get(data, 'threadId'),
        acsToken: _.get(data, 'acsToken'),
        messageId,
        tag: selectedChatUser.communicationId ? '#admin' : '#self'
      });
      if (status === 200) {
        dispatch(acsMessageActions.atnClearMessage([]));
        queryClient.fetchQuery({ queryKey: ['recieveMessages', _.get(data, 'threadId')] });
        queryClient.fetchQuery({ queryKey: ['getAcsChatThreads'] });
        dispatch(dialogActions.atnCloseDialog());
      }
    } catch (err) {
      toast.error(_.get(err, 'err.response.data', 'Unable to delete message'));
    }
  };

  return (
    <>
      <DialogContent>
        <Typography variant="h5" textAlign="center">
          Are you sure want to delete
          <br />
          this message?
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 0 }}>
        <Button color="primary" variant="contained" fullWidth onClick={handleMessageDelete}>
          Delete
        </Button>
      </DialogActions>
    </>
  );
};

export default AcsDeleteMessageDialog;
