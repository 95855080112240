import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import circleicon from '../../../../../assets/images/circleicon.svg';
import MyTooltip from '../../../components/MyTooltip';
import { formatNumberWithCommas } from '../../../components/common';

interface ExperiencePointsProps {
  value?: number;
  component?: string;
}

const ExperiencePoints: React.FC<ExperiencePointsProps> = ({ value, component }) => {
  const ChartTitle = component === 'MenteeTask' ? 'Mentee experience points' : 'Experience Points';
  const showTooltip = ChartTitle === 'Experience Points';

  return (
    <Box className="CardBox" p={2} height={'100%'}>
      <Box mb={1}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row">
            <Typography variant="h6">{ChartTitle}</Typography>
            {showTooltip && (
              <MyTooltip
                gapleft={0.5}
                iconColor='#152536'
                title='The total number of Experience points earned by members in this group'
              />
            )}
          </Stack>
          {/* <Stack direction="row" justifyContent="center" alignItems="baseline">
            <MyCardMedia image={uparrow} title="uparrow" />
            <Typography className='CardRightTopText'>---</Typography>
          </Stack> */}
        </Stack>
        {/* <Typography variant='body2' color='#808080'>Last 30 days</Typography> */}
      </Box>
      <Box display="flex" alignItems="center" height="178px" justifyContent="center" gap="8px">
        <img src={circleicon} alt="logo" width="50px" height="50px" className='p-0 m-0' />
        <Typography variant="h5" fontSize="60px" noWrap maxWidth="calc(100% - 58px)">
          {value ? formatNumberWithCommas(value) : '---'}
        </Typography>
      </Box>
    </Box>
  );
};

export default ExperiencePoints;
