/* eslint-disable no-duplicate-imports */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { API } from '../../../../../../api';
import {
  Box,
  Grid,
  Stack,
  Button,
  Divider,
  Accordion,
  IconButton,
  Typography,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { toast } from 'react-toastify';
import { Close } from '@mui/icons-material';
import MyChip from '../../../../components/MyChip';
import { appColors } from '../../../../../../utils/theme';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RoleTypeTag from '../../../../components/RoleTypeTag';
import { useLoader } from '../../../../components/useLoader';
import ChatProfile from '../../../chat/chat-main/ChatProfile';
import { GetSocialIcon } from '../../../../components/SocialIcon';
import { countries } from '../../../../../profile-page/countryData';
import ChatAvatarComponent from '../../../chat/ChatAvatarComponent';
import LevelQuestionAnswers from '../../Dialogs/LevelQuestionAnswers';
// import copyicon from '../../../../../../assets/images/copyicon.svg';
import useProfileDetails from '../../../../../../hooks/useProfileDetails';
import profilebgimg from '../../../../../../assets/images/profilebgimg.png';
import ProgramUserDetailsLoader from '../../../../../../Loader/ProgramUserDetailsLoader';
import { GroupBasedRoleFormatter, roleNameFormatter } from '../../../../components/common';

interface Props {
  actievUserId: string;
  setShowProfile: any;
  grpId: any;
  setScrollToUserCall: any;
  handleUserClosedId: any;
  handleOpenTasks: any;
}

const ProgramUserDetails = ({
  actievUserId,
  setShowProfile,
  grpId,
  setScrollToUserCall,
  handleUserClosedId,
  handleOpenTasks
}: Props) => {
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [completeUserDetails, setCompleteUserDetails] = useState<any>();
  const [userDetails, setUserDetails] = useState<any>();
  const [loading, setLoading] = useState<any>(true);
  const [open, setOpen] = React.useState(false);
  const [latestEducation, setLatestEducation] = useState<any>();
  const [latestWorkHistory, setLatestWorkHistory] = useState<any>();
  const [copied, setCopied] = useState<any>('');
  const copiedUserDetails = {
    ...userDetails,
    id: userDetails?.personal_details,
    bio: { education: latestEducation, workHistory: latestWorkHistory },
    education: { updatedData: userDetails?.professional_details?.education },
    workHistory: { updatedData: userDetails?.professional_details?.workHistory }
    // digital_intro: userDetails?.digital_intro?.videoUrl
  };
  const mentorRoleTextLowerCase = GroupBasedRoleFormatter('mentor', 'mentor');
  const menteeRoleTextLowerCase = GroupBasedRoleFormatter('mentee', 'mentee');
  // const assessmentsAns = ['Arts & Design', 'Entrepreneurship', 'Legal'];
  const cat: any = userDetails?.personal_details.category;
  const userProfileDetails = useProfileDetails();
  const employedStatus = latestWorkHistory?.currentlyWorking === true ? 'Yes' : 'No';
  const getSocialData: any = {
    linkedIn: userDetails?.personal_details?.social?.linkedIn
  };

  useEffect(() => {
    fetchUserDetails(grpId, actievUserId);
  }, []);

  useEffect(() => {
    const clear = setTimeout(() => {
      setCopied('');
    }, 200);

    return () => {
      clearTimeout(clear);
    };
  }, [copied]);

  useEffect(() => {
    setLatestEducation(
      userDetails?.professional_details?.education?.sort((a: any, b: any) => b?.graduation_date - a?.graduation_date)[0]
    );
  }, [userDetails]);

  useEffect(() => {
    setLatestWorkHistory(
      userDetails?.professional_details?.workHistory
        ?.filter((a: any) => a.currentlyWorking === true)
        ?.sort((a: any, b: any) => b.start_date - a.start_date)[0]
    );
  }, [userDetails]);

  const getCountry = () => {
    const value = countries?.find((each) => each.code === userDetails?.personal_details?.country);
    if (value) {
      return value.label;
    }
    return '-';
  };

  const fetchUserDetails = async (grpId: string, actievUserId: string) => {
    try {
      const response = await API.getUserProfileInAGroup(grpId, actievUserId);
      if (response.status === 200 && response.statusText === 'OK') {
        setCompleteUserDetails(response?.data);
        setUserDetails(response?.data?.userDetails);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error('Something went wrong');
      }
    } catch (e) {
      setLoading(false);
      toast.error('Something went wrong');
    }
  };

  const getDays = () => {
    const currentDate: any = new Date();
    const unixTimestamp: any = userDetails?.lastLogin * 1000;
    const targetDate: any = new Date(unixTimestamp);
    const timeDifferenceMs = currentDate - targetDate;
    const daysDifference = Math.floor(timeDifferenceMs / (24 * 60 * 60 * 1000));
    return daysDifference;
  };

  const handleClickOpen = () => {
    setOpen(true);
    setButtonDisabled(true);
  };

  // const handleCopy = (url: any) => {
  //   navigator.clipboard.writeText(url);
  //   setCopied('Copied');
  // };

  type InformationItem = { label: string; value: any; type?: string };

  /** Personal information data */
  const personalInformation: InformationItem[] = [
    {
      label: 'Major',
      value: latestEducation?.major
    },
    {
      label: 'Year',
      value: latestEducation?.graduation_date
    },
    {
      label: 'Country',
      value: getCountry()
    },
    {
      label: 'Program role',
      value: roleNameFormatter(completeUserDetails?.roleInGroup?.role, mentorRoleTextLowerCase, menteeRoleTextLowerCase)
    }
  ];

  /** Profile Information */
  const profileInformation: InformationItem[] = [
    {
      label: 'Department',
      value: latestEducation?.department
    },
    {
      label: 'Year',
      value: latestEducation?.graduation_date
    },
    {
      label: 'Member type',
      value: userDetails?.personal_details?.category
    },
    {
      label: 'College',
      value: latestEducation?.university
    },
    {
      label: 'Major',
      value: latestEducation?.major
    },
    {
      label: 'Minor',
      value: latestEducation?.minor
    },
    {
      label: 'Linkedin URL',
      value: getSocialData,
      type: 'link'
    }
    // {
    //   label: 'Dosen URL',
    //   value: '',
    //   type: 'link'
    // },
  ];

  /** Other information data */
  const otherInformation: InformationItem[] = [
    {
      label: 'Days since last login',
      value: `${getDays()} days`
    },
    {
      label: 'Employed',
      value: employedStatus
    }
  ];

  const showLoader = useLoader(loading, 1000);

  if (showLoader || loading) {
    return <ProgramUserDetailsLoader />;
  }

  return (
    <Box height={'100%'} overflow={'hidden'}>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <IconButton
          onClick={() => {
            setShowProfile(false);
            setScrollToUserCall(true);
            handleUserClosedId(actievUserId);
          }}
        >
          <Close color="primary" />
        </IconButton>
      </Box>
      <Grid container height={'calc(100% - 40px)'}>
        <Grid item xs height={'100%'}>
          <Box height={'100%'} overflow={'auto'}>
            <Box bgcolor={'#FFFFFF'} border={'1px solid #EFF0F4'} borderRadius={'8px'}>
              <Box
                p={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderRadius="8px 8px 0px 0px"
                sx={{
                  backgroundImage: `url(${profilebgimg})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                }}
              >
                <Box display="flex" alignItems="center" columnGap="20px">
                  <Box>
                    <ChatAvatarComponent
                      height="80px"
                      width="80px"
                      firstLetter={userDetails?.personal_details?.firstName?.slice(0, 1)}
                      image={userDetails?.personal_details?.headshot}
                      type="noStatus"
                    />
                  </Box>
                  <Box display="flex" flexDirection="column" alignItems="flex-start">
                    <Box display={'flex'} alignItems={'center'}>
                      <Typography variant="h1" mr={2}>
                        {userDetails?.name}
                      </Typography>
                      <RoleTypeTag category={cat} />
                    </Box>
                    <Typography mt={0.5}>
                      {userDetails?.email}
                      {userDetails?.personal_details.phoneNumber && (
                        <>
                          <span className="color-FFF px-1">|</span>
                          {userDetails?.personal_details.phonePrefix} {userDetails?.personal_details.phoneNumber}
                        </>
                      )}
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" gap={1}>
                  {userDetails?.mentees?.length > 0 && userDetails?.mentees?.includes(grpId) && (
                    <Button
                      variant="outlined"
                      onClick={() => handleOpenTasks(actievUserId)}
                      sx={{
                        // width: '134px',
                        height: '35px',
                        fontWaight: 600,
                        fontSize: '14px',
                        padding: '8px 20px',
                        border: '1px solid',
                        borderRadius: '8px',
                        color: appColors.blue1,
                        borderColor: appColors.blue1,
                        '&:hover': {
                          borderColor: appColors.blue1
                        }
                      }}
                      disabled={isButtonDisabled}
                    >
                      Development Progress
                    </Button>
                  )}

                  <Button
                    color="info"
                    variant="contained"
                    sx={{
                      boxShadow: 'none',
                      padding: '8px 20px',
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: 'normal',
                      '&:hover': {
                        background: '#0071A9'
                      }
                    }}
                    disabled={isButtonDisabled}
                    onClick={handleClickOpen}
                  >
                    Public Profile
                  </Button>
                </Box>
              </Box>
              <Divider />
              {/* Personal information */}
              <Box p={2} display={'flex'} alignItems={'flex-start'}>
                <Grid container spacing={2}>
                  {personalInformation.map((info, index) => (
                    <Grid key={index} item xs={6} md={3} lg={2}>
                      <Typography variant="body2" noWrap>
                        {info.label}
                      </Typography>
                      {info.value !== undefined && info.value !== null && info.value !== '' ? (
                        info.type && info.type === 'image' ? (
                          <img src={info.value} alt={info.label} width={'24'} height={'24'} />
                        ) : (
                          <Typography variant="h6" noWrap>
                            {info.value}
                          </Typography>
                        )
                      ) : (
                        <Typography variant="h6" noWrap>
                          {' '}
                          —{' '}
                        </Typography>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Box>
              <Divider />
              {/* Bio */}
              {userDetails?.personal_details?.bio ? (
                <>
                  <Box p={2} bgcolor="#FFFFFF">
                    <Typography variant="h5">Bio</Typography>
                    <Box pt={2}>
                      {userDetails?.personal_details?.bio ? (
                        <Typography fontStyle={'italic'} whiteSpace={'pre-line'}>
                          {userDetails?.personal_details?.bio}
                        </Typography>
                      ) : (
                        <Typography>No Bio Feild</Typography>
                      )}
                    </Box>
                  </Box>
                  <Divider />
                </>
              ) : null}
              {/* Programs */}
              <Box p={2}>
                <Typography variant="h5" mb={2}>
                  Programs
                </Typography>
                <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1}>
                  {completeUserDetails?.allGroupsUserBelongsTo?.map((each: any, index: any) => (
                    <MyChip label={each} backgroundColor="#0071A9" color="#fff" border="1px solid #2955C7" />
                  ))}
                </Stack>
              </Box>
            </Box>

            {/* Profile Information */}
            <Box className="CardBox" mt={2}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="Profile-Info" id="Profile-Info">
                  <Typography variant="h5">Profile Information</Typography>
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                  <Grid container spacing={2}>
                    {profileInformation.map((info, index) => (
                      <Grid key={index} item xs={6} md={3} lg={2}>
                        <Typography variant="body2" noWrap>
                          {info.label}
                          {/* {info.type === 'link' &&
                            <>
                              {info.value !== undefined && info.value !== null && info.value !== '' && (
                                <img
                                  src={copyicon}
                                  alt="copy"
                                  width={'13'}
                                  height={'13'}
                                  style={{
                                    cursor: 'pointer',
                                    marginLeft: '5px'
                                  }}
                                  onClick={() => {
                                    handleCopy(getSocialData?.linkedIn);
                                  }}
                                />
                              )}
                              {copied && <span>{copied}</span>}
                            </>
                          } */}
                        </Typography>
                        {info.value !== undefined && info.value !== null && info.value !== '' ? (
                          info.type && info.type === 'image' ? (
                            <img src={info.value} alt={info.label} width={'24'} height={'24'} />
                          ) : info.type && info.type === 'link' ? (
                            <GetSocialIcon data={info.value} location="adminUserInfo" />
                          ) : (
                            <Typography variant="h6" noWrap>
                              {info.value}
                            </Typography>
                          )
                        ) : (
                          <Typography variant="h6" noWrap>
                            {' '}
                            —{' '}
                          </Typography>
                        )}
                      </Grid>
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>

            {/* View Assessments */}
            {/* <Box className="CardBox" mt={2}>
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="Assessments-Info" id="Assessments-Info">
                  <Typography variant="h5">View Assessments</Typography>
                </AccordionSummary>
                <Divider />
                <AccordionDetails>
                  <Box>
                    <Box>
                      <Typography pb={1} color={'primary'}>What is your intention after this academic year?</Typography>
                      <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1}>
                        {assessmentsAns.length > 0 ? (
                          assessmentsAns.map((category, index) => (
                            <MyChip key={index} label={category} backgroundColor="#6C757D" color="#fff" />
                          ))
                        ) : (
                          <Typography>No data found</Typography>
                        )}
                      </Stack>
                    </Box>
                    <Box mt={2}>
                      <Typography pb={1} color={'primary'}>Which sectors are you interested in working in?</Typography>
                      <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1}>
                        {assessmentsAns.length > 0 ? (
                          assessmentsAns.map((category, index) => (
                            <MyChip key={index} label={category} backgroundColor="#6C757D" color="#fff" />
                          ))
                        ) : (
                          <Typography>No data found</Typography>
                        )}
                      </Stack>
                    </Box>
                    <Box mt={2}>
                      <Typography pb={1} color={'primary'}>What types of jobs/roles are you interested in pursuing?</Typography>
                      <Stack direction="row" useFlexGap flexWrap="wrap" spacing={1}>
                        {assessmentsAns.length > 0 ? (
                          assessmentsAns.map((category, index) => (
                            <MyChip key={index} label={category} backgroundColor="#6C757D" color="#fff" />
                          ))
                        ) : (
                          <Typography>No data found</Typography>
                        )}
                      </Stack>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box> */}

            {/* Other Information */}
            <Box className="CardBox" mt={2}>
              <Box p={2}>
                <Typography variant="h5">Other Information</Typography>
              </Box>
              <Divider />
              <Box p={2} display="flex" alignItems="flex-start">
                <Grid container spacing={2}>
                  {otherInformation.map((info, index) => (
                    <Grid key={index} item xs={6} md={3} lg={2}>
                      <Typography variant="body2" noWrap>
                        {info.label}
                      </Typography>
                      {info.value !== undefined && info.value !== null && info.value !== '' ? (
                        info.type && info.type === 'image' ? (
                          <img src={info.value} alt={info.label} width={'24'} height={'24'} />
                        ) : (
                          <Typography variant="h6" noWrap>
                            {info.value}
                          </Typography>
                        )
                      ) : (
                        <Typography variant="h6" noWrap>
                          {' '}
                          —{' '}
                        </Typography>
                      )}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>

        {open ? (
          <Grid item width="350px" height={'100%'}>
            <ChatProfile
              workHistory={copiedUserDetails?.workHistory}
              educationDetails={copiedUserDetails?.education}
              chatProfile={copiedUserDetails}
              onCloseChatProfile={() => {
                setOpen(false);
                setButtonDisabled(false);
              }}
              setActiveChat={() => {}}
              parentComponent="sideSmallProfile"
              digitalIntro={true}
              callBookingBtn={true}
              chatbtn={true}
              // userEdit={true}
              // loading={true}
            />
          </Grid>
        ) : null}
      </Grid>
    </Box>
  );
};

export default ProgramUserDetails;
