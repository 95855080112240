import React, { useEffect, useState } from 'react';
import {
  Grid,
  TextField,
  InputLabel,
  Typography,
  FormControl,
  Autocomplete,
  DialogContent,
  DialogActions,
  FormHelperText
} from '@mui/material';
import _ from 'lodash';
import { LoadingButton } from '@mui/lab';
import { sortedCountries } from './common';
import useProfileDetails from '../../../hooks/useProfileDetails';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { popupCountActions } from '../../../redux/slices/poupsCountsSlice';
import { Controller, SubmitHandler, FieldValues, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { API } from '../../../api';
import { getUserDetails } from '../../../utils/orgName';
import { toast } from 'react-toastify';
import { userActions } from '../../../redux/slices/user-profile/userProfieSlice';

interface CompanyOption {
  label: string;
  value: string;
}

const Company = () => {
  //   const schema = yup.object({
  //     company: yup.string().nullable().required('Company name is required'),
  //     location: yup.string().nullable().required('Location is required')
  //   });

  const [companyName, setCompanyName] = useState<any>(null);
  const [companyLocation, setCompanyLocation] = useState<any>(null);
  const [errors, setErrors] = useState({ company: '', location: '' });
  const { settings } = useProfileDetails();

  const { ftue } = useProfileDetails();
  //   const {
  //     handleSubmit,
  //     control,
  //     formState: { errors },
  //     setValue,
  //     getValues
  //   } = useForm({
  //     resolver: yupResolver(schema)
  //   });
  const storedValues = localStorage.getItem('company');

  const dispatch = useAppDispatch();
  const popuplength = useAppSelector((state) => state.popupsCount.popuplength);
  const count = useAppSelector((state) => state.popupsCount.count);
  const nextPopUpOrNot = popuplength - count;
  const checkErrors = () => {
    let isError = false;
    if ((settings.company || companyLocation) && !companyName?.trim()) {
      setErrors((prev) => ({ ...prev, company: 'Company name is required' }));
      isError = true;
    }
    if ((settings.company || companyName) && !companyLocation) {
      isError = true;
      setErrors((prev) => ({ ...prev, location: 'Company location is required' }));
    }
    return isError;
  };
  const { userId } = getUserDetails();
  const [ftueLoading, setFtueLoading] = useState(false);
  const updateFtue = async () => {
    setFtueLoading(true);
    try {
      const response = await API.updateUserFtue({ ftue: false, userId });
      const data = localStorage.getItem('education');
      let response2 = 200;
      if (data) {
        const formData = JSON.parse(data);
        const response = await API.addEducationAndSkills({ location, userId, formData });
        response2 = response.status;
      }
      if (response.status === 200 && response2 === 200 && response.statusText === 'OK') {
        dispatch(userActions.userFtueUpdate(0));
        setFtueLoading(false);
      }
    } catch (e) {
      toast.error(`Couldn't update profile details`);
      setFtueLoading(false);
    }
  };

  const onSubmit = async () => {
    if (checkErrors()) {
      return;
    }
    localStorage.setItem('company', JSON.stringify({ name: companyName || '', location: companyLocation || '' }));
    const nextPopUpOrNot = popuplength - count;
    if (ftue && nextPopUpOrNot !== 1) {
      dispatch(popupCountActions.increment(0));
    }
    if (nextPopUpOrNot === 1) {
      await updateFtue();
    }
  };

  /** Companies  */
  const companyOptions: CompanyOption[] = [
    { label: 'Dosen', value: 'Dosen' },
    { label: 'Company A', value: 'Company A' },
    { label: 'Company B', value: 'Company B' }
  ];

  const [data, setData] = useState<{ name: string; location: string }[]>([]);
  console.log(companyName, companyLocation, data);

  const value = data?.find((each) => each?.name?.toLowerCase() === companyName?.toLowerCase());

  const { location } = getUserDetails();

  const getCompanyList = async () => {
    try {
      const response = await API.getCompanyList(location);
      if (response.status === 200 && response.statusText === 'OK') {
        setData(response.data.data);
        localStorage.setItem('companyData', JSON.stringify(response.data.data));
      }
    } catch (e) {
      toast.error(_.get(e, 'response.data.message', `Couldn't get company list`));
    }
  };
  // to re render the auto complete after company name changes to show the location

  const [autocompleteKey, setAutocompleteKey] = useState(0);

  useEffect(() => {
    if (storedValues) {
      setCompanyName(JSON.parse(storedValues).name);
      setCompanyLocation(JSON.parse(storedValues).location);
    }
  }, []);
  useEffect(() => {
    getCompanyList();
  }, []);

  useEffect(() => {
    const data = localStorage.getItem('companyData');
    if (data) {
      setData(JSON.parse(data));
    }
  }, []);

  /* Set field values when component mounts */

  /** Dialog actions */
  const getDialogActions = () => {
    return (
      <DialogActions>
        {ftue && (
          <LoadingButton
            variant="outlined"
            color="secondary"
            fullWidth
            onClick={() => dispatch(popupCountActions.decrement(0))}
          >
            Back
          </LoadingButton>
        )}

        <LoadingButton
          color="primary"
          variant="contained"
          fullWidth
          loading={ftueLoading}
          onClick={onSubmit}
          id="companySubmit"
        >
          {nextPopUpOrNot === 1 ? 'Finish' : nextPopUpOrNot > 1 ? 'Next' : 'Save'}
        </LoadingButton>
      </DialogActions>
    );
  };
  console.log(companyName, companyLocation);

  return (
    <>
      <DialogContent>
        <Typography variant="subtitle1" pb={2}>
          Add the company you're joining your program with
        </Typography>
        <Grid container spacing={2}>
          {/* Company Name */}
          <Grid item xs={12}>
            <InputLabel>Company Name {settings?.company && '*'}</InputLabel>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                size="small"
                id="company"
                value={data?.find((each) => each.name.toLowerCase() === companyName?.toLowerCase()) || companyName}
                options={data}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
                autoHighlight
                onChange={(_, e) => {
                  setCompanyName(e?.name ? e.name : e);
                  if ((settings.company || companyLocation) && !e?.name) {
                    setErrors({ ...errors, company: 'Company name is required' });
                  } else {
                    setCompanyLocation(e?.location);
                    setErrors({ location: '', company: '' });
                  }
                }}
                freeSolo
                defaultValue={storedValues ? JSON.parse(storedValues).location : null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    placeholder="Choose Company Name"
                    autoComplete="false"
                    inputProps={{
                      ...params.inputProps
                    }}
                  />
                )}
                onInputChange={(_, e) => {
                  setCompanyName(e);
                  if (e) {
                    setErrors({ ...errors, company: '' });
                  }
                }}
              />
            </FormControl>
            <FormHelperText error>{errors.company}</FormHelperText>
          </Grid>
          {/* Company Location */}
          <Grid item xs={12}>
            <InputLabel>Company Location {settings?.company && '*'}</InputLabel>
            <FormControl fullWidth>
              <Autocomplete
                fullWidth
                size="small"
                id="location"
                value={sortedCountries.find((each) => each.label === companyLocation)}
                key={companyLocation}
                options={sortedCountries}
                getOptionLabel={(option) => option.label}
                onChange={(_, e) => {
                  setCompanyLocation(e?.label ? e.label : e);
                  if ((settings.company || companyName) && !e) {
                    setErrors({ ...errors, location: 'Location is required' });
                  } else {
                    setErrors({ ...errors, location: '' });
                  }
                }}
                defaultValue={storedValues ? JSON.parse(storedValues).company : null}
                autoHighlight
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    placeholder="Choose Company Location"
                    autoComplete="false"
                    inputProps={{
                      ...params.inputProps
                    }}
                  />
                )}
                disabled={value ? true : false}
              />
            </FormControl>
            <FormHelperText error>{errors.location}</FormHelperText>
          </Grid>
        </Grid>
      </DialogContent>
      {/* Action */}
      {getDialogActions()}
    </>
  );
};

export default Company;
