import React, { useState } from 'react';
import {
  Box,
  Rating,
  Dialog,
  Divider,
  TextField,
  Typography,
  InputLabel,
  IconButton,
  DialogActions,
  DialogContent
} from '@mui/material';
import _ from 'lodash';
import { API, IReviewData } from '../../../../api';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import VoteIcons from '../../components/VoteIcons';
import { getUserDetails } from '../../../../utils/orgName';
import blankstar from '../../../../assets/images/blankratingsrtar.svg';
import filledstar from '../../../../assets/images/filledratingstar.svg';
import CommonModalHeader from '../../../../components/DialogUi/CommonModalHeader';
import useResponsiveScreenSwitch from '../../../../utils/useResponsiveScreenSwitch';
import { inputCharacterCount, showCharacterCountWarning } from '../../components/common';
import useProfileDetails from '../../../../hooks/useProfileDetails';
import ROLES from '../../../../utils/roles';

interface PostCallPopupProps {
  title: string;
  meetingId: string;
  reviewParticipant: any;
  openPostCallPopup: boolean;
  handleClosePostCallPopup: () => void;
  name: string;
}

const PostCallPopup: React.FC<PostCallPopupProps> = ({
  title,
  meetingId,
  reviewParticipant,
  openPostCallPopup,
  handleClosePostCallPopup,
  name
}) => {
  const openModal = openPostCallPopup;
  const { forMobile } = useResponsiveScreenSwitch();
  const { location, userId } = getUserDetails();
  const [isLoading, setLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [upDownVoteValue, setUpDownVoteValue] = useState(0);
  const [value, setValue] = React.useState<number>(0);
  const { mentee, admins } = useProfileDetails();
  const role = localStorage.getItem('role') || '';
  const isVisible = mentee.length > 0 || admins.length > 0 || role === ROLES.orgAdmin;

  /** Review Count */
  const maxCharacterCount = 100;
  const totalCharactersReview = inputCharacterCount(description, maxCharacterCount);
  const handleDescriptionChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    showCharacterCountWarning(newValue, maxCharacterCount);
    setDescription(newValue);
  };

  const handleVote = (voteValue: number) => {
    setUpDownVoteValue(voteValue);
    // if (voteValue === 0) {
    //   // closes the modal
    //   handleSubmit(voteValue);
    // }
  };

  const handleSubmit = async (isReviewed: boolean) => {
    handleClosePostCallPopup(); // Close the current popup
    const receivedVoteIds: string[] = reviewParticipant.map((reviewParticipant: { mid: any }) => reviewParticipant.mid);
    let data: IReviewData = {
      vote: upDownVoteValue,
      score: value,
      isReviewed,
      participantUsers: receivedVoteIds
    };
    if (isVisible) {
      data = { ...data, review: description };
    }
    try {
      const response = await API.addReview({ data, meetingId });
      // if (response) {
      //   if (isReviewed) {
      //     toast.success('Review added successfully');
      //   }
      // }
    } catch (e) {
      //toast.error(_.get(e, 'response.data.message', `Couldn't update review`));
      // Close the current popup on error
    }
  };

  const handleBackdropClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // Prevent the dialog from closing when clicking on the backdrop
    event.stopPropagation();
  };

  return (
    <Dialog open={openModal} onClose={() => { }} disableEscapeKeyDown onClick={handleBackdropClick}>
      <CommonModalHeader handleCloseModal={() => handleSubmit(false)} title={title} />
      <DialogContent>
        <Box
          my={1}
          gap="8px"
          display="flex"
          alignItems="center"
          textAlign="center"
          justifyContent="space-between"
          flexDirection={forMobile ? 'column' : 'row'}
        >
          <Box>
            <Typography variant="h3">Was this call helpful?</Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={'8px'}>
            <IconButton onClick={() => handleVote(1)}>
              <VoteIcons voteValue={upDownVoteValue} />
            </IconButton>
            <IconButton onClick={() => handleVote(2)}>
              <VoteIcons voteValue={upDownVoteValue} icon="dislike" />
            </IconButton>
          </Box>
        </Box>
        <Divider className="my-20" />
        <Box
          my={1}
          gap={'8px'}
          display="flex"
          alignItems="center"
          textAlign="center"
          justifyContent={'space-between'}
          flexDirection="column" //{forMobile ? 'column' : 'row'}
        >
          <Box>
            <Typography variant="h3">How satisfied are you with the advice you got?</Typography>
          </Box>
          <Box mt={1}>
            <Rating
              name="simple-controlled"
              sx={{ gap: '8px' }}
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue ? newValue : 0);
              }}
              icon={<img src={filledstar} alt="" height={25} width={25} />}
              emptyIcon={<img src={blankstar} alt="" height={25} width={25} />}
            />
          </Box>
        </Box>
        {isVisible && (
          <>
            <Divider className="my-20" />
            <Box>
              <Box display="flex" justifyContent="space-between">
                <InputLabel>Call review</InputLabel>
                <InputLabel>{`${totalCharactersReview?.textValueLength}/${totalCharactersReview?.maxCharacterCount}`}</InputLabel>
              </Box>
              <TextField
                rows={4}
                multiline
                id="review"
                name="review"
                value={description}
                onChange={handleDescriptionChange}
                InputProps={{
                  inputProps: {
                    maxLength: totalCharactersReview?.maxCharacterCount
                  }
                }}
                // placeholder={`you can write review your call with ${name} in details.`}
                placeholder="If you enjoyed the call, you can leave a review for your Mentor and Admin to see"
              />
            </Box>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          fullWidth
          variant="contained"
          onClick={() => handleSubmit(true)}
          loading={isLoading}
          disabled={isLoading}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PostCallPopup;
