/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-invalid-this */
/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Link,
  Dialog,
  Divider,
  TextField,
  Typography,
  InputLabel,
  Autocomplete,
  DialogContent,
  DialogActions,
  FormHelperText
} from '@mui/material';
import { useParams } from 'react-router-dom';
import Upvotes from '../../../components/Upvotes';
import ChatAvatarComponent from '../../chat/ChatAvatarComponent';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { groupUsersActions } from '../../../../../redux/slices/group-users/groupUsersSlice';
import _ from 'lodash';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, FieldValues, useForm } from 'react-hook-form';
import { API } from '../../../../../api';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import countryCodeArray from '../../../../../constants/countrycode';
import { validatePhoneNumber } from '../../../../../constants/PhoneNumberValidator';
import PhoneInput from '../../../../../constants/PhoneInput';
import { ValidationError } from 'yup';
import {
  GroupBasedRoleFormatter,
  capitalizeFirstLetter,
  formatRoleFromDisplayRoles,
  groupNameArray,
  roleNameFormatter,
  rolesArray
} from '../../../components/common';
import useRoleNameFormatter from '../../../../../hooks/useRoleNameFormatter';
import CommonModalHeader from '../../../../../components/DialogUi/CommonModalHeader';
import { fetchAllGroupsList } from '../../../../../redux/slices/getAllGroupsListSlice';
import useGroupSettings from '../../../../../hooks/useGroupSettigs';

interface Props {
  handleClose: any;
  data?: any;
  openDialog: boolean;
  setOpenDialog: any;
  type: string;
}

interface CountryType {
  name: string;
  dial_code: string;
  code: string;
}
interface SelectedValue {
  groupId: any;
  groupName: string;
  userRole: any;
}

const InviteUserDialog = ({ handleClose, data, openDialog, setOpenDialog, type }: Props) => {
  const grpId = useParams().id || '';
  const orgId = useParams().orgId || '';
  const [inviteUserLoading, setInviteUserLoading] = useState(false);
  const dispatch = useAppDispatch();
  const groupUsers = useAppSelector((state) => state.groupUsers.data?.users);
  const invitedUsers = useAppSelector((state) => state.groupUsers.data?.invitedUsers);
  const mentorRoleText = GroupBasedRoleFormatter('mentor', 'mentor');
  const menteeRoleText = GroupBasedRoleFormatter('mentee', 'mentee');
  const allGroupsData = useAppSelector((state: any) => state.groupData.groups);
  const updatedGroupsData = allGroupsData.filter((group: any) => group.grpId !== grpId);
  const [roleData, setRoleData] = useState([]);
  const groupsDataStructure = updatedGroupsData.map(
    (group: { grpId: any; name: any; logo: any; enrolledCount: any; displayRoles: any; settingsUpdate: boolean }) => ({
      id: group.grpId,
      name: group.name,
      logo: group.logo,
      enrolled: group.enrolledCount,
      displayRoles: group.displayRoles,
      settingsUpdate: group.settingsUpdate
    })
  );

  const [selectedGroups, setSelectedGroups] = useState<
    {
      enrolled: any;
      logo: any;
      id: any;
      name: string;
      displayRoles: any;
      settingsUpdate: boolean;
    }[]
  >([]);
  const [availableGroups, setAvailableGroups] = useState(groupsDataStructure);
  const [selectedValues, setSelectedValues] = useState<SelectedValue[]>([]);
  const [removeGroupId, setRemoveGroupId] = useState();
  const selectedGroupsInfo = selectedValues.map((group) => ({
    grpId: group.groupId,
    role: group.userRole
  }));

  const addUsersdetails = Yup.object({
    firstName: Yup.string()
      .required('First name is required')
      .test('no-empty-spaces', 'First name cannot be all empty spaces', (value) => {
        if (value) {
          return !/^[\s]+$/.test(value);
        }
        return true;
      })
      .min(1, 'Name must be at least 1 character')
      .max(25, 'Maximum 25 characters'),
    lastName: Yup.string()
      .required('Last name is required')
      .test('no-empty-spaces', 'Last name cannot be all empty spaces', (value) => {
        if (value) {
          return !/^[\s]+$/.test(value);
        }
        return true;
      })
      .min(1, 'Name must be at least 1 character')
      .max(25, 'Maximum 25 characters'),
    email: Yup.string()
      .transform((value) => value.trim())
      .matches(/^[A-Za-z0-9]/, 'Email must start with either Alphabet or number')
      .required('Email is required')
      .email('Email must be a valid email'),
    role: Yup.array().of(Yup.string()).min(1, 'Role is required').nullable().required('Role is required'),
    // mobileNumber: Yup.string().matches(
    //   /^(\d{10})?$/,
    //   "Enter 10 digit Mobile Number or be it Empty"
    // ),
    mobileNumber: Yup.string()
      .transform((value) => value.trim())
      .test('is-valid-phone-number', 'Invalid phone number', async function (value) {
        if (!value) {
          // Return true for empty phone number
          return true;
        }
        const { path, createError } = this;
        const validationResult = await validatePhoneNumber(value, selectedCountry);
        if (validationResult) {
          const { isValid, error } = validationResult;
          return isValid || createError({ path, message: error } as ValidationError);
        }
        return createError({
          path,
          message: 'Validation error'
        } as ValidationError);
      })
    // group: Yup.array()
    //   .of(
    //     Yup.object({
    //       // id: Yup.number().required('Group ID is required'),
    //       name: Yup.string().required('Group Name is required'),
    //     })
    //   )
    //   .min(1, 'Group is required')
    //   .required('Group is required'),
  });

  selectedGroups.forEach(({ id, name }: any) => {
    const userRole = `groupUserRole_${id}`;
    (addUsersdetails.fields as any)[userRole] = Yup.mixed()
      .transform((value, originalValue) => (originalValue === null ? undefined : value))
      .nullable()
      .when(['role'], (roleFieldValue, schema) => {
        return schema.test('is-required', `Role is required`, (value: string | any[]) => {
          return (Array.isArray(value) && value.length > 0) || typeof value === 'string';
        });
      });
  });

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    reset
  } = useForm({
    resolver: yupResolver(Yup.object(addUsersdetails.fields))
  });

  const selectedCountryObject =
    data && data.phonePrefix ? countryCodeArray.find((country) => country.dial_code === data.phonePrefix) : null;
  // const [selectedCountry, setSelectedCountry] = React.useState<CountryType | null>(countryCodeArray[0] || null);
  const [selectedCountry, setSelectedCountry] = React.useState<CountryType | null>(selectedCountryObject || null);
  const handleCountryChange = (value: CountryType | null) => {
    setSelectedCountry(value);
  };

  const onSubmit: SubmitHandler<FieldValues> = async (values) => {
    setInviteUserLoading(true);
    const formData = {
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      role: values.role,
      email: values.email.trim(),
      mobileNumber: values.mobileNumber ? values.mobileNumber.trim() : values.mobileNumber,
      // phonePrefix: selectedCountry,
      phonePrefix: selectedCountry?.dial_code,
      groups: selectedGroupsInfo
    };
    if (values.mobileNumber && selectedCountry) {
      const phoneNumberValidationResult = await validatePhoneNumber(values.mobileNumber, selectedCountry);
      if (phoneNumberValidationResult && !phoneNumberValidationResult.isValid) {
        // Handle invalid phone number...
        // console.log("Invalid phone number");
        return;
      }
    }

    try {
      const response = await API.inviteUserToAGroup({
        formData,
        orgId,
        grpId
      });

      if (response?.status === 200 && response?.statusText === 'OK') {
        if (response.data.userDetails) {
          const index = groupUsers?.findIndex((each) => each.userId === response.data.userDetails?.userId);
          if (index !== -1) {
            dispatch(groupUsersActions.updateUser(response.data.userDetails));
          } else {
            dispatch(groupUsersActions.updateGroupUsers(response.data.userDetails));
          }
        } else if (type === 'Add' && response.data.invitedUser) {
          const index = invitedUsers?.find((each) => each.userId === response.data.invitedUser?.userId);
          if (index) {
            dispatch(groupUsersActions.updateInvitedUser(response.data.invitedUser));
          } else {
            dispatch(groupUsersActions.updateGroupInvitedUsers(response.data.invitedUser));
          }
        } else {
          dispatch(groupUsersActions.updateInvitedUser(response.data.invitedUser));
        }
        // toast.success(_.get(response, 'data.message', 'Successful'));
        toast.success('Invitation sent successfully');
      }
    } catch (error) {
      toast.error(_.get(error, 'response.data.message', 'Somthing went wrong'));
      setInviteUserLoading(false);
    } finally {
      handleCloseDialog();
      reset();
      setInviteUserLoading(false);
      setSelectedValues([]);
      handleClose();
    }
  };

  const checkError = (fieldName: string) => Boolean(errors[fieldName]);
  const getError = (fieldName: string) => errors[fieldName]?.message;
  const groupSettings = useGroupSettings();
  // let roleOptions: any[] = groupSettings ? rolesArray() : ['Admin'];
  let roleOptions: any[] = rolesArray();

  const handleAutocompleteChange = (groupId: any, groupName: string, newValue: any) => {
    const updatedValues = [...selectedValues];
    const index = updatedValues.findIndex((item) => item.groupId === groupId);

    if (index !== -1) {
      updatedValues[index].userRole = newValue;
    } else {
      updatedValues.push({ groupId, groupName, userRole: newValue });
    }
    setSelectedValues(updatedValues);
  };

  const handleGroupChange = (
    event: React.SyntheticEvent,
    newValues: { id: any; name: string; logo: any; enrolled: any; displayRoles: any; settingsUpdate: boolean }[]
  ) => {
    setValue(`groupUserRole_${removeGroupId}`, []);
    setSelectedGroups(newValues);
  };

  const handleRemoveGroup = (groupId: any) => {
    setRemoveGroupId(groupId);
    setValue(`groupUserRole_${groupId}`, []);
    // const removedGroup = selectedGroups.find((group) => group.id === groupId) as {
    //   id: any;
    //   name: string;
    //   logo: any;
    //   enrolled: any;
    // };
    //setAvailableGroups((prevAvailableGroups: any) => [...prevAvailableGroups, removedGroup]);
    const updatedGroups = selectedGroups.filter((group) => group.id !== groupId);
    setSelectedGroups(updatedGroups);
    const updatedValues = selectedValues.filter((item) => item.groupId !== groupId);
    setSelectedValues(updatedValues);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    reset();
    setSelectedGroups([]);
    setAvailableGroups(groupsDataStructure);
  };

  useEffect(() => {
    if (type === 'Add') {
      reset();
      setSelectedValues([]);
    } else {
      reset(data);
      setRoleData(data);
      const groupIds = data.groups.map((group: { grpId: any }) => group.grpId);
      const groupsToSelect = availableGroups.filter((group: { id: any }) => groupIds.includes(group.id));
      setValue('group', groupsToSelect);
      setSelectedGroups(groupsToSelect);
      const selectedGroupsInfo = data.groups.map((group: { grpId: any; role: any }) => ({
        groupId: group.grpId,
        userRole: group.role
      }));
      setSelectedValues(selectedGroupsInfo);
    }
  }, [openDialog]);

  /** 19Dec/2023: New group row design */
  const GroupRow: React.FC<{
    groupId: number;
    groupName: string;
    groupLogo: any;
    groupEnrolled: any;
    isLast: boolean;
    displayRoles: any;
    settingsUpdate: boolean;
  }> = ({ groupId, groupName, groupLogo, groupEnrolled, isLast, displayRoles, settingsUpdate }) => {
    const userRole = `groupUserRole_${groupId}`;
    let displayRolesArrayForSpecificGroup: (string | undefined)[] =
      (
        roleData as { groups?: { grpId: number; role: (string | undefined)[]; displayRoles: { [x: string]: any } }[] }
      )?.groups
        ?.filter((group) => group.grpId === groupId)
        .map((group) => group.role)?.[0] ?? [];

    if (displayRolesArrayForSpecificGroup.length > 0) {
      displayRolesArrayForSpecificGroup = displayRolesArrayForSpecificGroup
        .filter((role): role is string => typeof role === 'string' && role.trim() !== '')
        .map((role) => _.startCase(role))
        .filter((role): role is string => typeof role === 'string');
    } else {
      displayRolesArrayForSpecificGroup = [];
    }

    let transformedArray = displayRolesArrayForSpecificGroup;
    console.log(displayRoles);
    let menteeRoleTextGroupWise = formatRoleFromDisplayRoles('mentee', 'Mentee', displayRoles);
    let mentorRoleTextGroupWise = formatRoleFromDisplayRoles('mentor', 'Mentor', displayRoles);

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box display="flex" gap="8px">
              <Box>
                <ChatAvatarComponent
                  image={groupLogo}
                  type="noStatus"
                  firstLetter={groupName.charAt(0).toUpperCase()}
                  width="50px"
                  height="50px"
                />
              </Box>
              <Box width="calc(100% - 58px)">
                <Box display="flex" alignItems="center" justifyContent="space-between" gap="5px">
                  <Typography variant="h5" noWrap>
                    {groupName}
                  </Typography>
                </Box>
                <Box pt={1}>
                  <Upvotes likes={groupEnrolled} icon="group" />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box>
              <InputLabel>Member role *</InputLabel>
              <Controller
                name={userRole}
                control={control}
                defaultValue={transformedArray}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    multiple
                    options={settingsUpdate ? roleOptions : ['Admin']}
                    getOptionLabel={(option) =>
                      _.startCase(roleNameFormatter(option, mentorRoleTextGroupWise, menteeRoleTextGroupWise))
                    }
                    value={value}
                    id={userRole}
                    onChange={(event, newValue) => {
                      handleAutocompleteChange(groupId, groupName, newValue);
                      onChange(newValue);
                    }}
                    disableCloseOnSelect
                    renderInput={(params) => <TextField {...params} fullWidth placeholder="Select Role" />}
                  />
                )}
              />
              <FormHelperText error={!!errors[`groupUserRole_${groupId}`]?.message}>
                {errors[`groupUserRole_${groupId}`]?.message as ReactNode}
              </FormHelperText>
            </Box>
            <Box display="flex" justifyContent="end" width="100%">
              <Link color="error" fontSize="10px" fontWeight="600" onClick={() => handleRemoveGroup(groupId)}>
                Remove
              </Link>
            </Box>
          </Grid>
        </Grid>
        {!isLast && <Divider sx={{ my: 2 }} />}
      </>
    );
  };

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog} scroll="body" fullWidth>
      <CommonModalHeader
        title={'Invite member'}
        //title={type === 'Add' ? 'Add User' : 'Invite User'}
        handleCloseModal={handleCloseDialog}
      />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputLabel>First name *</InputLabel>
            <Controller
              name="firstName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  name="firstName"
                  type="text"
                  onChange={onChange}
                  value={value || ''}
                  id="userFirstName"
                  error={checkError('firstName')}
                  helperText={getError('firstName')?.toString()}
                  placeholder="John"
                  fullWidth
                  style={{
                    width: '100%',
                    WebkitAppearance: 'none', // Remove Safari-specific styling
                    MozAppearance: 'none', // Remove Mozilla-specific styling
                    appearance: 'none' // Remove default browser styling
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel>Last name *</InputLabel>
            <Controller
              name="lastName"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  name="lastName"
                  type="text"
                  onChange={onChange}
                  value={value || ''}
                  id="userLastName"
                  error={checkError('lastName')}
                  helperText={getError('lastName')?.toString()}
                  placeholder="Smith"
                  // style={{ width: '100%' }}
                  style={{
                    width: '100%',
                    WebkitAppearance: 'none', // Remove Safari-specific styling
                    MozAppearance: 'none', // Remove Mozilla-specific styling
                    appearance: 'none' // Remove default browser styling
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel>Email *</InputLabel>
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  required
                  name="email"
                  type="text"
                  placeholder="john.smith@gmail.com"
                  onChange={onChange}
                  error={checkError('email')}
                  helperText={getError('email')?.toString()}
                  value={value || ''}
                  id="userEmail"
                  style={{
                    width: '100%',
                    WebkitAppearance: 'none', // Remove Safari-specific styling
                    MozAppearance: 'none', // Remove Mozilla-specific styling
                    appearance: 'none' // Remove default browser styling
                  }}
                  disabled={type !== 'Add'}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PhoneInput
              countryCodeArray={countryCodeArray}
              data={data}
              control={control}
              errors={errors}
              onChange={handleCountryChange}
              name="mobileNumber"
              showMark={false}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Program role *</InputLabel>
            <Controller
              name="role"
              control={control}
              defaultValue={[]} // Initialize with an empty array
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  multiple
                  options={groupSettings ? roleOptions : ['Admin']}
                  getOptionLabel={(option) => _.startCase(roleNameFormatter(option, mentorRoleText, menteeRoleText))}
                  value={value || []}
                  id="role"
                  onChange={(_, newValue) => onChange(newValue)} // Pass the new value array
                  disableCloseOnSelect
                  isOptionEqualToValue={(option, value) => option === capitalizeFirstLetter(value)}
                  renderInput={(params) => <TextField {...params} fullWidth placeholder="Choose role" />}
                />
              )}
            />
            <FormHelperText error>{getError('role')?.toString()}</FormHelperText>
          </Grid>

          <Grid item xs={12}>
            <InputLabel>Group </InputLabel>
            <Controller
              name="group"
              control={control}
              defaultValue={selectedGroups}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  multiple
                  options={availableGroups}
                  value={selectedGroups}
                  getOptionLabel={(option) => option.name}
                  id="group"
                  onChange={(_, newValue) => {
                    onChange(newValue);
                    handleGroupChange(_, newValue);
                  }}
                  disableCloseOnSelect
                  renderInput={(params) => <TextField {...params} fullWidth placeholder="Add to more groups" />}
                />
              )}
            />
            <FormHelperText error>{getError('group')?.toString()}</FormHelperText>
          </Grid>
        </Grid>

        {/* 19Dec/2023: New Group List */}
        {selectedGroups.length > 0 && (
          <Box mt={2}>
            {selectedGroups.map((group, index) => (
              <GroupRow
                key={group.id}
                groupId={group.id}
                groupName={group.name}
                groupLogo={group.logo}
                groupEnrolled={group.enrolled}
                isLast={index === selectedGroups.length - 1}
                displayRoles={group.displayRoles}
                settingsUpdate={group.settingsUpdate}
              />
            ))}
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <LoadingButton
          color="primary"
          variant="contained"
          fullWidth
          onClick={handleSubmit(onSubmit)}
          loading={inviteUserLoading}
        >
          {type === 'Add' ? 'Add' : 'Resend Invite'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default InviteUserDialog;
