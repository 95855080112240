/* eslint-disable react-hooks/exhaustive-deps */
/** Home page custom style */
import { useEffect, useState } from 'react';
import { Box, Divider, FormControl, Menu, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { IRoleObj, groupRoleListUpdate } from '../../../../redux/slices/groupRolesListSlice';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import { breadCrumbsActions } from '../../../../redux/slices/breadcrumbs/breadcrumbsSlice';
import { updateSelectedId } from '../../../../redux/slices/selectedGroupSlice';
import ROLES from '../../../../utils/roles';
import { API } from '../../../../api';
import { userInfoUpdate } from '../../../../redux/slices/user-profile/userProfieSlice';
import { toast } from 'react-toastify';
import useResponsiveScreenSwitch from '../../../../utils/useResponsiveScreenSwitch';
import { setSelectedValue } from '../../../../redux/slices/selectedValueSlice';
import groupRoleMappings from '../../../../utils/groupRoleMappings';
import { GroupBasedRoleFormatter, roleNameFormatter } from '../../components/common';
import { fetchMentorTasks } from '../../../../redux/slices/mentorTasksSlice';
import { AGILE_ORG } from '../../../../utils/constants';
import { getUserDetails } from '../../../../utils/orgName';
import { groupLoaderActions } from '../../../../redux/slices/groupLoaderSlice';
import { saveAnswerActions } from '../../../../redux/slices/saveAnswersSlice';
import * as amplitude from '@amplitude/analytics-browser';

/** Added extra headshots */

function GroupList() {
  const mentorRoleText = GroupBasedRoleFormatter('mentor', 'Mentor');
  const menteeRoleText = GroupBasedRoleFormatter('mentee', 'Mentee');
  const grpRoleListState = useAppSelector((state) => state.groupRoleList);
  const data = useAppSelector((state) => state.groupRoleList.data) || [];
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedId = useAppSelector((state) => state.selectedGroup.data);
  const [value, setValue] = useState(selectedId.id + '_' + selectedId.role);
  const oid = useAppSelector((state) => state.orgDetails.data?.oid);
  // const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);

  useEffect(() => {
    dispatch(setSelectedValue(value));
  }, [value]);

  const { orgId } = getUserDetails();
  /** For responsive */
  const { forMobile } = useResponsiveScreenSwitch(); // Use the hook

  /** Style */
  const selectSize = '200px'; // Select field size
  const useStyles = makeStyles({
    FormControlSize: {
      maxWidth: selectSize,
      width: selectSize
    },
    MenuItemSize: {
      maxWidth: selectSize,
      width: selectSize,
      textWrap: 'wrap !important'
    }
  });
  const classs = useStyles();
  const location = useLocation();

  const changeRoute = (role: IRoleObj) => {
    dispatch(updateSelectedId(role));
    if (location.pathname === '/app/profile/assessment' && role.role === 'mentor') {
      navigate(`/app/profile/assessment`);
    } else {
      navigate(`/app/home/${role.role}`);
    }
  };
  const dispatchBreadCrumbs = (id?: string) => {
    const grpId = id ? id : 'admin';
    const { orgId, coId } = getUserDetails();
    dispatch(
      breadCrumbsActions.removeBreadCrumbs({
        id: grpId,
        name: 'Program',
        url: `/app/programdetails/${orgId}/${coId}/${grpId}/overview`
      })
    );
    dispatch(groupLoaderActions.updateState(true));
    navigate(`/app/programdetails/${orgId}/${coId}/${grpId}/overview`);
  };
  const userProfileData = useAppSelector((state) => state.userProfile.data);
  const onChange = (event: SelectChangeEvent) => {
    dispatch(saveAnswerActions.update(false));
    const [id, role] = event.target.value.split('_');
    setValue(event.target.value);
    const selectedObj = data?.find((each) => each.id === id && each.role === role) || {
      id: '',
      name: '',
      role: '',
      active: true
    };
    const identify = new amplitude.Identify();
    identify.set('Program', selectedObj.name);
    identify.set('Member role', _.capitalize(selectedObj.role));
    amplitude.identify(identify);
    localStorage.setItem('lastseen', JSON.stringify(selectedObj));

    dispatch(updateSelectedId(selectedObj));
    if (role === 'mentor' || role === 'consultant') {
      dispatch(fetchMentorTasks({ groupId: selectedObj?.id }));
    }

    if (!selectedObj.active) {
      return navigate('/app/deactivated');
    }
    const selectedValue = event.target.value;
    dispatch(setSelectedValue(selectedValue));

    const fetchUserGroupList = async () => {
      try {
        dispatch(groupRoleListUpdate.upadateState({ ...grpRoleListState, loader2: true }));
        const response = await API.groupsListForUser(orgId);
        if (response.status === 200 && response.statusText === 'OK') {
          const updateLastSeen = async () => {
            try {
              const response = await API.updateLastseen(selectedObj);
              if (response.status === 200 && response.statusText === 'OK') {
                dispatch(userInfoUpdate({ ...userProfileData, lastseen: selectedObj }));
              }
            } catch (e) {
              toast.error(_.get(e, 'response.data.message', `couldn't update last seen`));
            }
          };
          updateLastSeen();

          dispatch(
            groupRoleListUpdate.upadateState({
              loading: false,
              data: response.data.data || [],
              error: false,
              errroText: '',
              loader2: false
            })
          );
        }
      } catch (e) {
        dispatch(
          groupRoleListUpdate.upadateState({
            loading: false,
            data: undefined,
            error: true,
            errroText: _.get(e, 'response.data.message', `Failed to get user group role list`),
            loader2: false
          })
        );
      }
    };
    fetchUserGroupList();
    if (selectedObj.role !== 'admin') {
      changeRoute(selectedObj);
    } else {
      redirectAdmin(selectedObj);
    }
  };

  const redirectAdmin = (selectedObj: any) => {
    dispatchBreadCrumbs(selectedObj?.id);
  };

  useEffect(() => {
    setValue(selectedId.id + '_' + selectedId.role);
  }, [selectedId]);
  const role = localStorage.getItem('role');

  if (role !== ROLES.platAdmin && selectedId.role !== '') {
    return (
      <>
        <Box px={forMobile ? 2 : ''} pt={forMobile ? 1 : ''}>
          {forMobile ? (
            <Box mb={1}>
              <Typography variant="caption" color="secondary">
                ROLE
              </Typography>
            </Box>
          ) : null}

          <FormControl className={classs.FormControlSize}>
            <Select value={String(value)} onChange={onChange}>
              {data &&
                data.length > 0 &&
                data.map((each) => {
                  let uniqueId = each.id + '_' + each.role;
                  if (each.role === 'admin') {
                    return (
                      <MenuItem className={classs.MenuItemSize} key={uniqueId} value={uniqueId} title="Admin">
                        Admin
                      </MenuItem>
                    );
                  }
                  if (each.role === 'mentee' && oid === AGILE_ORG) {
                    return null;
                  }
                  const name = each.name.length > 30 ? each.name.slice(0, 30) + ' ...' : each.name;
                  return (
                    <MenuItem className={classs.MenuItemSize} key={uniqueId} value={uniqueId} title={each.name}>
                      {_.startCase(roleNameFormatter(each?.displayRole, mentorRoleText, menteeRoleText)) + ' - ' + name}
                      {/* Indira Gandhi National Open University (IGNOU) from India - Mentee */}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Box>

        {forMobile ? <Divider sx={{ mt: 2, mb: 1 }} /> : null}
      </>
    );
  }
  return null;
}

export default GroupList;
