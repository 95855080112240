/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {
  Box,
  Stack,
  Dialog,
  Button,
  Divider,
  Typography,
  IconButton,
  DialogContent,
  DialogActions
} from '@mui/material';
import _ from 'lodash';
import { makeStyles } from '@mui/styles';
import { Close } from '@mui/icons-material';
import { EditIcon } from '../../../../../utils/Icons';
import ChatAvatarComponent from '../ChatAvatarComponent';
import '../../../../../assets/style/sass/chatProfile.scss';
import { useAppSelector } from '../../../../../redux/hooks';
// import { getUserDetails } from '../../../../../utils/orgName';
import RemoveUserFromGroupModal from './RemoveUserFromGroupModal';
import ChatGroupDetailsLoader from '../../../../../Loader/ChatGroupDetailsLoader';
import CommonModalHeader from '../../../../../components/DialogUi/CommonModalHeader';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

interface IChatGroupDetails {
  setopenGroupDetails: any;
  acsChannelParticipants: any;
  onClickOnUserIcon: any;
  setChatInfoForm: any;
  workHistory: any;
  educationDetails: any;
  chatProfile: any;
  selectedMember: any;
  channel: any;
  channelImage: any;
  chatType: any;
  type?: 'self' | 'other' | 'home' | 'no status';
  handleAddPersonToThread: any;
  removeParticipantFromThread: any;
  loading?:boolean
}

const useStyles = makeStyles({
  participantBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    '&:hover $removeText': {
      opacity: 1
    }
  },
  removeText: {
    opacity: 0,
    color: '#DC3545',
    transition: 'opacity 0.3s ease',
    cursor: 'pointer'
  },
  memberCircleInner: {
    width: '48px',
    height: '48px',
    borderRadius: '50%'
  },
  GroupAdminBadge: {
    fontFamily: 'Open Sans',
    fontSize: '10px',
    fontWeight: '600',
    lineHeight: '13.62px',
    color: '#11895E',
    borderColor: '#11895E',
    border: '1px solid',
    padding: '2px 9px',
    borderRadius: '5px'
  }
});

const ChatGroupDetails: React.FC<IChatGroupDetails> = ({
  setopenGroupDetails,
  acsChannelParticipants,
  onClickOnUserIcon,
  selectedMember,
  channel,
  type = 'other',
  channelImage,
  setChatInfoForm,
  chatType,
  handleAddPersonToThread,
  removeParticipantFromThread,
  loading
}) => {
  const classes = useStyles();
  // const userFirstLetter = getUserDetails()?.name?.slice(0, 1);
  const acsCommunityUsers = useAppSelector((state) => state.acsCommunityUsers.data);
  const [openLeaveModal, setOpenLeaveModal] = React.useState(false);
  const selectedChatUser = useAppSelector((state) => state.selectedChatUser.data);

  const handleClickOpenLeaveModal = () => {
    setOpenLeaveModal(true);
  };

  const handleCloseLeaveModal = () => {
    setOpenLeaveModal(false);
  };

  /** Remove member from group Modal */
  const [RemoveUser, setRemoveUserOpen] = useState(false);
  const handleRemoveUser = (flag: boolean) => setRemoveUserOpen(flag);
  return (
    loading ? <ChatGroupDetailsLoader /> :
    <>
      <Box className="GroupDetailsBox" height={'100%'}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" p={2}>
          <Typography variant="h5">Group Details</Typography>
          <Close
            color="primary"
            className="cursor-pointer"
            onClick={() => {
              setopenGroupDetails(false);
            }}
          />
        </Stack>
        <Divider />
        <Box height={'calc(100% - 126px)'} overflow={'auto'} p={2}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap="8px" width="calc(100% - 30px)">
              {/* <Box
                style={{ cursor: onClickOnUserIcon ? 'pointer' : '' }}
                justifyContent="center"
                alignItems="center"
                display="flex"
              >
                {_.size(channelImage) ? (
                  <img
                    src={channelImage || ''}
                    referrerPolicy="no-referrer"
                    alt="Group image" width='48' height='48'
                    style={{
                      borderRadius: '50%',
                      objectFit: 'cover'
                    }}
                    loading="lazy"
                  />
                ) : (
                  <>
                    {type === 'self' ? (
              <Typography color="inherit">{_.capitalize(userFirstLetter)}</Typography>
            ) : (
              <Typography
                color="inherit"
                fontSize={fontSize ? fontSize : '16px'}
                sx={{ color: '#fff', fontWeight: '600' }}
              >
                {channel.slice(0, 1)?.toUpperCase()}
              </Typography>
            )}
                  </>
                )}
              </Box> */}
              <ChatAvatarComponent image={channelImage || ''} firstLetter={channel.slice(0, 1)} />
              <Typography variant="h5" noWrap>
                {channel}
              </Typography>
            </Box>
            {!selectedChatUser.communicationId && (
              <Box>
                <IconButton
                  onClick={() => {
                    onClickOnUserIcon();
                    if (chatType === 'Groups') {
                      setChatInfoForm(true);
                    }
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Box>
            )}
          </Box>

          {/* Group Description  */}
          {/* <Typography pt={2}>
            University student who has finished high school and has commenced their first year of college.
          </Typography> */}

          <Divider sx={{ my: 2 }} />

          <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
            <Typography fontSize="12px" fontWeight="600">
              {acsChannelParticipants.length} MEMBERS
            </Typography>
            {!selectedChatUser.communicationId && (
              <IconButton onClick={handleAddPersonToThread}>
                <AddCircleOutlineOutlinedIcon sx={{ color: '#0071A9' }} width="20" height="20" />
              </IconButton>
            )}
          </Box>

          {/* User row */}
          {acsChannelParticipants?.map((each: any, index: number) => {
            let cat: any = each?.category;
            return (
              <React.Fragment key={index}>
                <Box
                  className={classes.participantBox}
                  // onClick={()=>{setSelectedMember(each)}}
                  // onClick={() => { onClickOnUserIcon();selectedMember(each) }}
                  onClick={() => {
                    onClickOnUserIcon();
                    selectedMember(
                      acsCommunityUsers?.find(
                        (acsmem: any) =>
                          each?.id?.communicationUserId === acsmem?.id?.communicationUserId ||
                          each?.displayName === acsmem?.displayName
                      )
                    );
                  }}
                >
                  <Box className={classes.memberCircleInner}>
                    <ChatAvatarComponent
                      height="48px"
                      width="48px"
                      firstLetter={each?.displayName?.slice(0, 1)}
                      image={
                        acsCommunityUsers?.find(
                          (acsmem: any) => each?.id?.communicationUserId === acsmem?.id?.communicationUserId
                        )?.id?.headshot
                      }
                      // type="noStatus"
                    />
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    whiteSpace="nowrap"
                    justifyContent="space-between"
                    width="calc(100% - 58px)"
                    gap="8px"
                  >
                    <Typography className="cursor-pointer" variant="h5" noWrap>
                      {each?.displayName}
                    </Typography>
                    {/* <Typography variant='body2' className={classes.removeText} onClick={() => handleRemoveUser(true)}>Remove</Typography> */}
                    {/* <Badge className={classes.GroupAdminBadge}>Group Admin</Badge> */}
                  </Box>
                </Box>
                {index !== acsChannelParticipants.length - 1 && <Divider sx={{ my: 1 }} />}
              </React.Fragment>
            );
          })}
        </Box>
        <Box onClick={handleClickOpenLeaveModal} p={2}>
          <Typography className="cursor-pointer" color={'#DC3545'}>
            Leave Group
          </Typography>
        </Box>
      </Box>

      {/* Leave Group modal */}
      <Dialog open={openLeaveModal} onClose={handleCloseLeaveModal} fullWidth>
        <CommonModalHeader title={`Confirmation`} handleCloseModal={handleCloseLeaveModal} />
        <DialogContent>
          <Typography variant="h5" textAlign={'center'}>
            Are you sure you want to leave <br /> this group?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button fullWidth variant="outlined" color="secondary" onClick={handleCloseLeaveModal}>
            No
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              removeParticipantFromThread();
              handleCloseLeaveModal();
            }}
          >
            Yes, leave
          </Button>
        </DialogActions>
      </Dialog>

      {/* Remove member from group modal */}
      <RemoveUserFromGroupModal RemoveUser={RemoveUser} handleRemoveUser={handleRemoveUser} />
    </>
  );
};
export default ChatGroupDetails;
