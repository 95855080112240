/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Grid,
  Link,
  Radio,
  Divider,
  TextField,
  IconButton,
  InputLabel,
  RadioGroup,
  Typography,
  Autocomplete,
  FormControlLabel
} from '@mui/material';
import { toast } from 'react-toastify';
import ContentsModal from './ContentsModal';
import { lockIcon, topicArray } from '../../../../../components/common';
import { AdminTaskPreview } from '../../../../../../../utils/muiStyle';
import { EditIcon, PlusCircleIcon } from '../../../../../../../utils/Icons';
import personmentor from '../../../../../../../assets/images/personmentor.svg';
import { useAppDispatch, useAppSelector } from '../../../../../../../redux/hooks';
import { IAdminTask, ITaskData } from '../../../../../../../redux/slices/tasksSlice';
import { IArticleData, fetchAllArticles } from '../../../../../../../redux/slices/article/getAllArticleSlice';
import { getUserDetails } from '../../../../../../../utils/orgName';
import { useParams } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface Row {
  id: number;
}

interface Props {
  selectedTask: IAdminTask;
  setTempTasks?: any;
  levelId?: string;
  tempTasks: ITaskData | null;
}

const TaskPreviewRightBar: React.FC<Props> = ({ selectedTask, setTempTasks, levelId, tempTasks }) => {
  const isPublished = useAppSelector((state) => state.groupDetails.data?.goalPublished) || false;
  const menteeAnsOptions = [
    { label: 'Text', value: 'Open input field' },
    { label: '1-5 Rating', value: '1-5 rating' },
    { label: 'Yes or No', value: 'Yes, No' },
    { label: 'List of Industries', value: 'List of industries' },
    { label: 'List of Roles', value: 'List of roles' }
  ];
  const [editing, setEditing] = useState(false);
  const classes = AdminTaskPreview({ editing: editing });
  const params = useParams();
  const grpId = params.id || '';
  const orgId = params.orgId || '';
  const [selectedArticles, setSelectedArticles] = useState<IArticleData[]>([]);
  const groupTopics = useAppSelector((state) => state.groupDetails.data?.topics) || [];
  const [taskTopics, setTaskTopics] = useState<string[]>([]);
  const [menteeQues, setMenteeQues] = useState('');
  const [description, setDescription] = useState('');
  const [taskName, setTaskName] = useState('');
  const [menteeAnsType, setMenteeAnsType] = useState('');
  const data = useAppSelector((state) => state.tasks.data);
  const task = data?.levels.find((level) => level._id === levelId)?.tasks.find((task) => task._id === selectedTask._id);
  const [isTaskNameEditing, setIsTaskNameEditing] = useState(false);
  const topics = topicArray();
  const mappedTopics = groupTopics.map((topic: string) => {
    const index = topics.find((each) => each.name === topic);
    if (index) {
      return index.label;
    }
    return topic;
  });
  const articleData = useAppSelector((state) => state.allArticle.data);
  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setDescription(selectedTask.taskDescription || '');
    setEditing(false);
    setMenteeQues(selectedTask.menteeQues || '');
    setMenteeAnsType(selectedTask.menteeAnsType || '');
    setSelectedArticles(selectedTask.libraryArticles || []);
    setTaskTopics(selectedTask.tags || []);
    setTaskName(selectedTask.taskName || '');
    setIsTaskNameEditing(false);
    setEditing(false);
  }, [selectedTask._id, levelId]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [scrollRef.current, selectedTask._id, levelId]);

  const convertStringToHtml = (text: string) => {
    return text.replace(/\n/g, '<br />');
  };

  const updateTask = () => {
    const tags = taskTopics.map((topic: string) => {
      const index = topics.find((each) => each.label === topic);
      if (index) {
        return index.name;
      }
      return topic;
    });
    if (tempTasks && tempTasks.levels.length > 0) {
      const newTasks = tempTasks?.levels.map((level) => {
        if (level._id === levelId) {
          return {
            ...level,
            tasks: level.tasks.map((task, index) => {
              const isValid = (task.menteeAnsType && task.menteeQues) || (!task.menteeAnsType && !task.menteeQues);

              const validData =
                task.error && task.taskName && isValid && task.taskDescription?.trim() && task?.tags?.length !== 0;
              // if (task.taskName === 'New') {
              //   console.log(
              //     'validData',
              //     validData,
              //     task.error,
              //     task.taskName,
              //     isValid,
              //     task.taskDescription?.trim(),
              //     task?.tags?.length !== 0
              //   );
              // }

              if (task._id === selectedTask._id) {
                return {
                  ...task,
                  taskDescription: convertStringToHtml(description),
                  menteeAnsType,
                  menteeQues,
                  libraryArticles: selectedArticles,
                  tags,
                  taskName: taskName.trim() ? taskName : selectedTask.taskName,
                  error: validData ? false : task.error
                };
              }
              return task;
            })
          };
        }
        return level;
      });
      setTempTasks({ ...tempTasks, levels: newTasks });
    }
  };

  const convertLineBreaksToBr = (text: string) => {
    return text.replace(/\n/g, '<br/>');
  };

  const convertBreaksToLine = (text: string) => {
    return text.split('<br />').join('\n');
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      updateTask();
    }, 1000);
    return () => clearTimeout(timer);
  }, [description, menteeAnsType, menteeQues, selectedArticles, taskTopics, taskName]);

  const [showPlus, setShowPlus] = useState<boolean>(false);
  const isQuestion = (!menteeQues && !menteeAnsType) || (menteeQues && menteeAnsType);

  useEffect(() => {
    if (selectedTask.menteeAnsType || selectedTask.menteeQues) {
      setShowPlus(false);
    } else {
      setShowPlus(true);
    }
  }, [selectedTask._id]);

  const addTaskQuestions = () => {
    const handleRemoveRow = (idToRemove: number) => {
      setShowPlus(true);
      setMenteeQues('');
      setMenteeAnsType('');
    };

    /** Add questions */
    return (
      <>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box gap={0.5} display="flex" alignItems="center">
            <Typography variant="h5">Add questions</Typography>
            <Typography fontWeight={600}>(optional)</Typography>
            {selectedTask.error && !isQuestion && (
              <IconButton style={{ marginLeft: '10px' }} title="Required">
                <ErrorOutlineIcon fontSize="small" color="error" />
              </IconButton>
            )}
          </Box>
          {showPlus && (
            <IconButton size="small" onClick={() => setShowPlus(false)}>
              <PlusCircleIcon size={34} />
            </IconButton>
          )}
        </Box>

        {showPlus ? (
          <Box textAlign="center" pb={2}>
            <Link color="#0071A9" variant="caption" onClick={() => setShowPlus(false)}>
              + Add questions
            </Link>
          </Box>
        ) : (
          // addTaskRows.map((row) => (
          <Box pb={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} lg={7} md={8} sm={12}>
                <InputLabel>Question title</InputLabel>
                <TextField
                  fullWidth
                  placeholder="Question title"
                  value={menteeQues}
                  onChange={(e) => setMenteeQues(e.target.value)}
                  disabled={isPublished && (task?.menteeQues ? true : false)}
                />
              </Grid>
              <Grid item xs={12} lg={4} md={4} sm={12}>
                <InputLabel>Answer format </InputLabel>
                <Autocomplete
                  options={menteeAnsOptions}
                  getOptionLabel={(option) => option.label}
                  value={menteeAnsOptions.find((option) => option.value === menteeAnsType) || null}
                  renderInput={(params) => <TextField {...params} fullWidth placeholder="Answer Format" />}
                  onChange={(e, value) => {
                    setMenteeAnsType(value?.value || '');
                  }}
                  disabled={isPublished && (task?.menteeAnsType ? true : false)}
                />
              </Grid>
              {!(isPublished && (task?.menteeQues || task?.menteeAnsType)) && (
                <Grid item xs={12} lg={1} md={12} sm={12} mt={{ xs: 0, lg: 2 }} textAlign="right">
                  <Typography
                    color="error"
                    variant="caption"
                    className="cursor-pointer"
                    onClick={() => handleRemoveRow(1)}
                  >
                    Remove
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
          // ))
        )}
      </>
    );
  };

  const taskComplete = ({ disabled = false }) => {
    return (
      <Box>
        <Box py={2} display="flex" alignItems="center" gap={0.5} className={disabled ? 'opacity-25' : ''}>
          <Typography variant="h5">Have you completed this task?</Typography>
          {disabled && lockIcon({})}
        </Box>

        <Box pb={2}>
          <RadioGroup name="use-radio-group" defaultValue="yes" id="">
            <Box border="1px solid #EFF0F4" borderRadius="8px" width="50%" mb={1} px={2}>
              <FormControlLabel
                value="yes"
                label="Yes"
                control={<Radio />}
                disabled={disabled}
                className={disabled ? 'opacity-25' : ''}
              />
            </Box>
            <Box border="1px solid #EFF0F4" borderRadius="8px" width="50%" px={2}>
              <FormControlLabel
                value="no"
                label="No"
                control={<Radio />}
                disabled={disabled}
                className={disabled ? 'opacity-25' : ''}
              />
            </Box>
          </RadioGroup>
        </Box>
      </Box>
    );
  };

  const noArticle = (index: any) => (
    <Box className="second-resume-sec" key={index}>
      <Box borderRadius="8px" bgcolor="#EFF0F4" height="79px" minWidth="118px" />
      <Box width="160px">
        <Typography className="onlyShowTwoLine" variant="h5">
          Article Title
        </Typography>
        <Typography>Read Time</Typography>
      </Box>
    </Box>
  );

  const buildUpYourKnowledge = () => {
    const articles = selectedArticles?.map((articleId: any) => {
      const index = articleData?.ArticleList.find((each: any) => each.articleId === articleId);
      return index || { articleId: '', articleName: '', coverImageUrl: '', readTime: '' };
    });
    return (
      <Box className="CardBox" my={2}>
        <Box gap={1} display="flex" justifyContent="space-between" textAlign="center" alignItems="center" px={2}>
          <Typography variant="h5" py={2}>
            Build up your knowledge
          </Typography>
          <IconButton size="small" onClick={handleClickOpenContents}>
            <EditIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box className="resume-main-sec">
          {articles.length > 0
            ? articles.map((article: any, index: number) => {
                if (article.coverImageUrl) {
                  return (
                    <Box className="second-resume-sec" key={index}>
                      {/* <img borderRadius="8px" bgcolor="#EFF0F4" height="79px" minWidth="118px" /> */}

                      <Box borderRadius="8px" bgcolor="#EFF0F4" minWidth="118px">
                        <img
                          src={article.coverImageUrl}
                          alt="error"
                          height="79px"
                          width="100%"
                          className='br-8'
                        />
                      </Box>
                      <Box width="160px">
                        <Typography className="onlyShowTwoLine" variant="h5">
                          {article.articleName}
                        </Typography>
                        <Typography>{article.readTime}</Typography>
                      </Box>
                    </Box>
                  );
                }
                return noArticle(index);
              })
            : [1, 2, 3].map((index) => noArticle(index))}
        </Box>
      </Box>
    );
  };

  const getAdviceFrom = () => {
    return (
      <Box className="CardBox">
        <Box p={2}>
          <Typography variant="h5">Get advice from people in your community</Typography>
        </Box>
        <Divider />
        <Box display="flex" width="100%" minHeight="auto" overflow="auto">
          <Box width="400px" minWidth="40%">
            <Box height="100%">
              <Box display="flex" pt={2} px={2}>
                <img src={personmentor} alt="Trophybadge" width="24" height="24" />
                <Typography className="my-mentor-text">My mentors</Typography>
              </Box>
              <Box display="flex" p={2} gap={1}>
                <Box borderRadius="8px" bgcolor="#EFF0F4" height="50px" minWidth="50px" />
                <Box>
                  <Typography variant="h5">Name</Typography>
                  <Typography>Member will be display here...</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider sx={{ height: '100px', my: 2 }} />
          <Box>
            <Box display="flex" pt={2} px={2}>
              <img src={personmentor} alt="Trophybadge" width="24" height="24" />
              <Typography className="my-mentor-text">Others with relevant experience</Typography>
            </Box>
            <Box display="flex">
              {Array.from({ length: 4 }, (_, index) => (
                <Box key={index} display="flex" p={2} gap={1} minWidth="300px">
                  <Box borderRadius="8px" bgcolor="#EFF0F4" height="50px" minWidth="50px" />
                  <Box>
                    <Typography variant="h5">Name</Typography>
                    <Typography>Member will be display here...</Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  };

  const selectTopics = () => {
    return (
      <Box className="CardBox" my={2}>
        <Box px={2} pt={2} gap={0.5} display="flex" alignItems="center">
          <Typography variant="h5">Select topics relevant to this task</Typography>
          <Typography fontWeight={600}>(only visible to admin)</Typography>
          {selectedTask.error && taskTopics?.length === 0 && (
            <IconButton style={{ marginLeft: '10px' }} title="Required">
              <ErrorOutlineIcon fontSize="small" color="error" />
            </IconButton>
          )}
        </Box>
        <Box p={2}>
          <Autocomplete
            multiple
            options={mappedTopics}
            getOptionLabel={(option: string) => option}
            id="taskTopics"
            renderInput={(params) => (
              <TextField {...params} variant="outlined" size="small" placeholder="Select topics" />
            )}
            onChange={(_event, value) => setTaskTopics(value)}
            value={taskTopics.map((each) => {
              return topics.find((topic) => topic.name === each)?.label || each;
            })}
          />
        </Box>
      </Box>
    );
  };

  const handleEditClick = () => {
    setEditing(true);
  };

  /** Contents Modal */
  const [openContents, setOpenContents] = React.useState(false);
  const dispatch = useAppDispatch();

  const handleClickOpenContents = () => {
    setOpenContents(true);
  };
  const handleCloseContents = () => {
    setOpenContents(false);
  };

  const handleTaskTitleClick = () => {
    setIsTaskNameEditing(true);
  };
  const taskNameRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    if (isTaskNameEditing && taskNameRef.current) {
      taskNameRef.current.focus();
      // to keep the cursor at the end of the text
      if (taskNameRef.current) {
        const { length } = taskNameRef.current.value;
        taskNameRef.current.setSelectionRange(length, length);
      }
    }
  }, [isTaskNameEditing]);

  const descriptionRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (editing && descriptionRef.current) {
      descriptionRef.current.focus();
      // to keep the cursor at the end of the text
      if (descriptionRef.current) {
        const { length } = descriptionRef.current.value;
        descriptionRef.current.setSelectionRange(length, length);
      }
    }
  }, [editing]);

  const updateHTMLWithCorrectLink = (t: any) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(t, 'text/html');

    const link = doc.querySelector('a[href="app.joinhandshake.com"]');
    if (link) {
      let href: any = link.getAttribute('href');
      if (!href.startsWith('http') && !href.startsWith('//')) {
        href = 'https://' + href;
        link.setAttribute('href', href);
      }
    }
    if (link) {
      if (!link.hasAttribute('target')) {
        link.setAttribute('target', '_blank');
      }
    }

    return doc.body.innerHTML;
  };

  return (
    <Box height="100%">
      <Box px={2} className="CardBox">
        <Box gap={1} display="flex" justifyContent="space-between" textAlign="center" alignItems="center">
          {!isTaskNameEditing ? (
            <>
              <Typography variant="h5" py={2} ref={scrollRef}>
                {taskName || 'Add task title'}
              </Typography>
              {selectedTask?.error && taskName === '' && (
                <IconButton style={{ marginLeft: '10px' }} title="Required">
                  <ErrorOutlineIcon fontSize="small" color="error" />
                </IconButton>
              )}
              <IconButton size="small" onClick={handleTaskTitleClick}>
                <EditIcon />
              </IconButton>
            </>
          ) : (
            <TextField
              variant="outlined"
              size="small"
              className="w-100"
              placeholder="Add TasK Title"
              value={taskName}
              onChange={(e) => setTaskName(e.target.value)}
              style={{ margin: '10px 0px 10px 0px' }}
              onBlur={() => {
                if (taskName.trim() === '') {
                  setTaskName(selectedTask?.taskName || 'Add Task Title');
                }
                setIsTaskNameEditing(false);
              }}
              inputRef={taskNameRef}
            />
          )}
        </Box>
        <Box position="relative" onClick={() => handleEditClick()} style={{ width: '100%' }} bgcolor={'error'}>
          {editing && (
            <TextField
              multiline
              minRows={4}
              defaultValue={convertBreaksToLine(description)}
              id="addTaskDescription"
              placeholder="Add Task Description"
              className={classes.taskDescriptionInput}
              value={convertBreaksToLine(description)}
              onChange={(e) => setDescription(e.target.value)}
              inputRef={descriptionRef}
              onBlur={() => {
                if (description.trim() === '') {
                  setDescription(selectedTask?.taskDescription || '');
                }
                setEditing(false);
              }}
            />
          )}

          {/* For placeholder */}
          {!editing && (
            <Box style={{ display: 'flex' }} alignItems="center">
              <Box
                dangerouslySetInnerHTML={{
                  __html: convertLineBreaksToBr(description) || 'Add Task Description'
                }}
              >
                {/* <div>{getDetailTask?.taskDescription}</div> */}
              </Box>
              {selectedTask.error && description === '' && (
                <IconButton style={{ marginLeft: '10px' }} title="Required">
                  <ErrorOutlineIcon fontSize="small" color="error" />
                </IconButton>
              )}
            </Box>
          )}
        </Box>
        <Divider className="my-20" />

        {/* Add Task Questions */}
        {addTaskQuestions()}
        <Divider />

        {/* Have you completed this task */}
        {taskComplete({ disabled: true })}
      </Box>

      {/* Build up your knowledge */}
      {buildUpYourKnowledge()}

      {/* Get advice from people in your community */}
      {getAdviceFrom()}

      {/* Select topics relevant to this task */}
      {selectTopics()}

      {/* Contents Modal */}
      <ContentsModal
        openContents={openContents}
        onClose={handleCloseContents}
        setArticles={setSelectedArticles}
        articlesSelected={selectedArticles}
      />
    </Box>
  );
};

export default TaskPreviewRightBar;
