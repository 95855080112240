import { Box, Icon, IconButton, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useAppSelector } from '../../../../../../../redux/hooks';
import { useDrag, useDrop } from 'react-dnd';

interface TaskItemProps {
  task: any;
  isActive: boolean;
  setSelectedTask: (task: any) => void;
  handleDeleteTask: (task: any) => void;
  level: any;
  setLevelId: any;
  moveTask: any;
  taskIndex: number;
  levelIndex: number;
  isLast: boolean;
}

const TaskItem: React.FC<TaskItemProps> = ({
  task,
  isActive,
  setSelectedTask,
  handleDeleteTask,
  level,
  setLevelId,
  moveTask,
  taskIndex,
  levelIndex,
  isLast
}) => {
  const [ishover, setIshover] = useState(false);
  const isPublished = useAppSelector((state) => state.groupDetails.data?.goalPublished);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (scrollRef.current && isActive) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [task._id, isActive]);
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'TASK',
    item: { ...task, levelId: level._id, taskIndex, levelIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));

  return isDragging ? null : (
    <Box
      // pb={isLast ? 0 : 1}
      onMouseEnter={() => setIshover(true)}
      onMouseLeave={() => setIshover(false)}
      ref={scrollRef}
      // pt={isOver ? 1 : 0}
    >
      <Box
        pl={3}
        pr={2}
        gap="5px"
        height="34px"
        display="flex"
        alignItems="center"
        className="clickable"
        bgcolor={isActive ? '#0071A9' : '#FFFFFF'}
        onClick={() => {
          setSelectedTask(task);
          setLevelId(level._id);
        }}
        ref={drag}
        style={{ cursor: 'grab' }}
      >
        <DragHandleIcon fontSize="small" className="secondary-light" />
        <Typography noWrap fontWeight={isActive ? '900' : '400'} color={isActive ? '#FFFFFF' : '#0071A9'}>
          {task.taskName}
        </Typography>
        {task.error && !ishover && (
          <IconButton style={{ marginLeft: 'auto' }}>
            <ErrorOutlineIcon fontSize="small" color="error" />
          </IconButton>
        )}

        {ishover && (!isPublished || task.isNewTask) && (
          <IconButton
            style={{ marginLeft: 'auto' }}
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteTask({ levelId: level._id, taskId: task._id || '' });
            }}
          >
            <DeleteOutlineIcon style={{ color: isActive ? 'white' : 'grey', width: '20px', height: '20px' }} />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

export default TaskItem;
