import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Box,
  Stack,
  Radio,
  Dialog,
  // Checkbox,
  // FormGroup,
  TextField,
  Typography,
  InputLabel,
  RadioGroup,
  FormControl,
  DialogActions,
  DialogContent,
  FormControlLabel
} from '@mui/material';
import { inputCharacterCount, showCharacterCountWarning } from '../../../components/common';
import { LoadingButton } from '@mui/lab';
import CommonModalHeader from '../../../../../components/DialogUi/CommonModalHeader';
import { API } from '../../../../../api';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { getAcsUserByCommunicationId } from '../../../../../routes/helpers';
import { User, useAuth0 } from '@auth0/auth0-react';
import { acsMessageActions } from '../../../../../redux/slices/acs-message/acsMessage';
import { fetchCommunityMember } from '../../../../../redux/slices/communityMembers/communityMembersSlice';
import { getUserDetails } from '../../../../../utils/orgName';
import { eventTracking, events } from '../../../../../utils/amplitude';
import { fetchAcsToken } from '../../../../../redux/slices/acs-token/acsTokenSlice';
import { checkAzureSession } from '../../../../../utils/checkAzureSession';

const maxCharacterCount = 200;

/** Request Match popup */
interface RequestMatchProps {
  openRequestMatch: boolean;
  handleRequestMatch: (flag: boolean) => void;
  requestingId?: string;
  requestedId?: string;
  roles?: any;
  communicationUserId?: any;
  senderComId?: any;
  requestGroups?: any;
  requestMade?: any;
  receiverFirstName?: string;
}

export const RequestMatch: React.FC<RequestMatchProps> = ({
  openRequestMatch,
  handleRequestMatch,
  requestingId,
  requestedId,
  roles,
  communicationUserId,
  senderComId,
  requestGroups,
  requestMade,
  receiverFirstName
}) => {
  const acsToken = useAppSelector((state) => state.acsToken.data.token);
  const dispatch = useAppDispatch();
  const personalThreadsArr = useAppSelector((state) => state.acsChannels.personalThreadsArr);
  const { user } = useAuth0();
  const senderFirstName = getUserDetails()?.firstName;
  const acsCommunityUsers = useAppSelector((state) => state.acsCommunityUsers.data);
  const [sendLoading, setSendLoading] = useState(false);
  const [requestFieldValue, setRequestFieldValue] = useState('');
  const [selectedRole, setSelectedRole] = useState('');

  const totalCharactersRequest = inputCharacterCount(requestFieldValue, maxCharacterCount);
  const handleChangeRequest = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    showCharacterCountWarning(newValue, maxCharacterCount);
    setRequestFieldValue(newValue);
  };

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRole(event.target.value);
  };

  const handleSubmit = async () => {
    if (!selectedRole) {
      toast.error('Please select a role.');
      return;
    }
    if (!requestFieldValue.trim()) {
      toast.error('Please enter a request message.');
      return;
    }
    const commId = senderComId;
    // payload section
    let menteeId = requestedId;
    let mentorId = requestingId;
    if (selectedRole === 'mentee') {
      menteeId = requestingId; // requesting user wants to be a mentee
      mentorId = requestedId;
    }
    const requestMessage = 'Mentorship request - ' + requestFieldValue;
    setSendLoading(true);
    try {
      const response = await API.sendMatchRequest({
        requestMessage,
        menteeId,
        mentorId,
        requestGroups,
        commId
      });
      if (response.status === 200 && response.statusText === 'OK') {
        eventTracking(events.matchRequested, {});
        requestMade();
        toast.success('Match request message sent successfully');
        if (acsToken) {
          sendMessage(requestMessage, communicationUserId);
        }
        handleRequestMatch(false);
        setSendLoading(false);
      } else {
        setSendLoading(false);
      }
    } catch (e) {
      toast.error('Failed to send match request');
      setSendLoading(false);
    }
  };

  const sendMessage = async (content: string, communicationUserId: string) => {
    const contentMessage = '<p>' + content + '</p>';
    if (!communicationUserId) {
      return;
    }
    const file = '';
    let acsChannelParticipants;
    const contentId = new Date().getTime();
    // Extract the communicationUserId values using map
    const channel = personalThreadsArr?.find((each: { displayNames: string | any[] }) =>
      each?.displayNames?.includes(communicationUserId)
    );
    let threadId = '';
    if (channel) {
      // thread exists
      threadId = _.get(channel, 'id');
    } else {
      const participant = getAcsUserByCommunicationId(acsCommunityUsers, communicationUserId);
      // create thread
      const { orgId } = getUserDetails();
      const modifiedValues = {
        participants: [
          {
            ...participant,
            displayName: _.get(participant, 'id.communicationUserId', ''),
            id: {
              communicationUserId: _.get(participant, 'id.communicationUserId', '')
            }
          }
        ],
        topic: '#personal',
        azureToken: acsToken
      };
      try {
        const { status, data } = await API.createACSThread(acsToken, modifiedValues, orgId);
        if (status === 200) {
          threadId = data?.threadId;
        }
      } catch (e) {
        setSendLoading(false);
        return;
      }
    }
    try {
      const { status, data } = await API.getACSThreadParticipants(acsToken, threadId);
      if (status === 200) {
        acsChannelParticipants = data;
      }
    } catch (e) {
      setSendLoading(false);
    }
    const displayNames = acsChannelParticipants.map((participant: any) => participant.id.communicationUserId);

    try {
      const topic = '#personal';
      const { status } = await API.sendMessageToACSThread(
        acsToken,
        threadId,
        contentMessage,
        file,
        contentId,
        topic,
        displayNames
      );
      if (status === 200) {
        setSendLoading(false);
        handleRequestMatch(false);
      }
    } catch (e) {
      setSendLoading(false);
    }
  };

  return (
    <Dialog open={openRequestMatch} onClose={() => handleRequestMatch(false)} scroll="body">
      <CommonModalHeader title="Request a Match" handleCloseModal={() => handleRequestMatch(false)} />
      <DialogContent>
        <Box pb={1}>
          <Typography variant="h5">What role would you like to play in this relationship?</Typography>
          <FormControl>
            {/* For radio */}
            <RadioGroup row value={selectedRole} onChange={handleRoleChange}>
              {/* <FormControlLabel value="mentee" control={<Radio />} label="Mentee" />
              <FormControlLabel value="mentor" control={<Radio />} label="Mentor" /> */}
              {roles?.map((role: any) => (
                <FormControlLabel
                  key={role}
                  value={role}
                  control={<Radio />}
                  label={role.charAt(0).toUpperCase() + role.slice(1)} // Capitalize the first letter of role
                />
              ))}
            </RadioGroup>
            {/* For Checkbox */}
            {/* <FormGroup row>
              <FormControlLabel control={<Checkbox />} label="Mentee" />
              <FormControlLabel control={<Checkbox />} label="Mentor" />
            </FormGroup> */}
          </FormControl>
        </Box>

        <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
          <InputLabel>Let the person know why you’d like to be matched with them</InputLabel>
          <InputLabel>
            {`${totalCharactersRequest?.textValueLength}/${totalCharactersRequest?.maxCharacterCount}`}
          </InputLabel>
        </Stack>

        <TextField
          multiline
          InputProps={{
            inputProps: {
              maxLength: totalCharactersRequest?.maxCharacterCount
            }
          }}
          value={requestFieldValue}
          minRows={8}
          onChange={handleChangeRequest}
          placeholder={`Hi ${receiverFirstName}, my name is ${senderFirstName} and I’d like to be matched with you to learn more about`}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={sendLoading} fullWidth variant="contained" onClick={handleSubmit}>
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

/** Request Match Reject popup */
interface RequestMatchRejectProps {
  openRequestMatchReject: boolean;
  handleRequestMatchReject: (flag: boolean) => void;
  requestId: string;
  groupId: string;
  mentorId: string;
  menteeId: string;
  doReload?: () => void;
  commId: any;
}

export const RequestMatchReject: React.FC<RequestMatchRejectProps> = ({
  openRequestMatchReject,
  handleRequestMatchReject,
  requestId,
  groupId,
  mentorId,
  menteeId,
  doReload,
  commId
}) => {
  const dispatch = useAppDispatch();
  const personalThreadsArr = useAppSelector((state) => state.acsChannels.personalThreadsArr);
  // const acsChannelParticipants: IAcsUsers[] = useAppSelector((state) => state.acsChannelParticipants.data);
  const { user } = useAuth0();
  const userData = useAppSelector((state) => state.userProfile.data);
  const senderComId = userData?.personal_details?.communicationId;
  const acsCommunityUsers = useAppSelector((state) => state.acsCommunityUsers.data);
  const [rejectFieldValue, setRejectFieldValue] = useState('');
  const [rejectLoading, sendRejectLoading] = useState(false);
  const totalCharactersReject = inputCharacterCount(rejectFieldValue, maxCharacterCount);
  const acsToken = useAppSelector((state) => state.acsToken.data.token);
  const handleChangeReject = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    showCharacterCountWarning(newValue, maxCharacterCount);
    setRejectFieldValue(event.target.value);
  };

  const handleSubmit = async () => {
    if (!rejectFieldValue.trim()) {
      toast.error('Please enter a reject message.');
      return;
    }
    const grpId = groupId;
    // payload section
    const rejectMessage = rejectFieldValue;
    sendRejectLoading(true);
    try {
      const response = await API.rejectMatchRequest({ grpId, requestId, rejectMessage });
      if (response.status === 200 && response.statusText === 'OK') {
        toast.success('Match request has been rejected successfully');
        if (acsToken && !checkAzureSession(acsToken)) {
          sendMessage(rejectMessage, commId);
        }
        handleRequestMatchReject(false);
        sendRejectLoading(false);
        if (doReload) {
          doReload();
        }
      } else {
        sendRejectLoading(false);
      }
    } catch (e) {
      toast.error('Failed to send match reject request');
      sendRejectLoading(false);
    }
  };

  const sendMessage = async (content: string, communicationUserId: string) => {
    const contentMessage = '<p>' + content + '</p>';
    if (!communicationUserId) {
      return;
    }
    const file = '';
    let acsChannelParticipants: any;
    const contentId = new Date().getTime();
    // Extract the communicationUserId values using map
    const channel = personalThreadsArr?.find((each: { displayNames: string | any[] }) =>
      each?.displayNames?.includes(communicationUserId)
    );
    let threadId = '';
    if (channel) {
      // thread exists
      threadId = _.get(channel, 'id');
    } else {
      const participant = getAcsUserByCommunicationId(acsCommunityUsers, communicationUserId);
      // create thread
      const { orgId } = getUserDetails();
      const modifiedValues = {
        participants: [
          {
            ...participant,
            displayName: _.get(participant, 'id.communicationUserId', ''),
            id: {
              communicationUserId: _.get(participant, 'id.communicationUserId', '')
            }
          }
        ],
        topic: '#personal',
        azureToken: acsToken
      };

      const createThread = async () => {
        try {
          const { status, data } = await API.createACSThread(acsToken, modifiedValues, orgId);
          if (status === 200) {
            threadId = data?.threadId;
          }
        } catch (e) {
          sendRejectLoading(false);
          return;
        }
      };
      if (!acsToken || checkAzureSession(acsToken)) {
        dispatch(fetchAcsToken({ communicationId: communicationUserId || '', tag: '#self' })).then(() => {
          createThread();
        });
      } else {
        createThread();
      }
    }
    const displayNames = acsChannelParticipants.map((participant: any) => participant.id.communicationUserId);

    const chatApis = async () => {
      try {
        const { status, data } = await API.getACSThreadParticipants(acsToken, threadId);
        if (status === 200) {
          acsChannelParticipants = data;
        }
      } catch (e) {
        sendRejectLoading(false);
      }

      try {
        const topic = '#personal';
        const { status, data } = await API.sendMessageToACSThread(
          acsToken,
          threadId,
          contentMessage,
          file,
          contentId,
          topic,
          displayNames
        );
        if (status === 200) {
          sendRejectLoading(false);
        }
      } catch (e) {
        sendRejectLoading(false);
      }
    };
    if (!acsToken || checkAzureSession(acsToken)) {
      chatApis();
    } else {
      dispatch(fetchAcsToken({ communicationId: communicationUserId || '', tag: '#self' })).then(() => {
        chatApis();
      });
    }
  };

  return (
    <Dialog open={openRequestMatchReject} onClose={() => handleRequestMatchReject(false)} scroll="body">
      <CommonModalHeader title="Request a Match Reject" handleCloseModal={() => handleRequestMatchReject(false)} />
      <DialogContent>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
          <InputLabel>Reason for your rejection</InputLabel>
          <InputLabel>
            {`${totalCharactersReject?.textValueLength}/${totalCharactersReject?.maxCharacterCount}`}
          </InputLabel>
        </Stack>
        <TextField
          multiline
          InputProps={{
            inputProps: {
              maxLength: totalCharactersReject?.maxCharacterCount
            }
          }}
          value={rejectFieldValue}
          minRows={8}
          onChange={handleChangeReject}
          placeholder="Please send a reason for your rejection"
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={rejectLoading} fullWidth variant="contained" onClick={handleSubmit}>
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
