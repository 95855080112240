import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Box,
  Divider,
  Typography,
} from '@mui/material';
import { API } from '../../../../api';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { GetMenteeOptions } from './GetMenteeOptions';
import { Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks';
import { fetchMentorTasks } from '../../../../redux/slices/mentorTasksSlice';
import useResponsiveScreenSwitch from '../../../../utils/useResponsiveScreenSwitch';
import AssessmentQuestionsLoader from '../../../../Loader/AssessmentQuestionsLoader';

const AssessmentQuestions = () => {
  const selectedGroupId = useAppSelector((state) => state.selectedGroup.data);
  const groupId: any = selectedGroupId.id || '';
  const [btnLoading, setBtnLoading] = useState(false);
  const tasksData = useAppSelector((state) => state.mentorTasks);
  const loading = tasksData.loading;
  const data = useAppSelector((state) => state.mentorTasks.data.data);
  const dispatch = useAppDispatch();
  const [asmtAnswers, setAsmtAnswers] = useState<any>([]);
  const selectedRole = useAppSelector((state) => state.selectedGroup.data.role);
  const { forMobile } = useResponsiveScreenSwitch(); // Use the hook

  useEffect(() => {
    const [CardBox] = window.document.getElementsByClassName('CardBoxs');
    if (CardBox) {
      CardBox.scrollTo(0, 0);
    }
  }, [groupId]);

  /** Save action */
  const handleSave = () => {
    // data.map((item) => {
    //   if (item.ans.length < item.minMentorAns || item.ans.length > item.maxMentorAns) {
    //     toast.error('Please answer the question');
    //   }
    // });
    setBtnLoading(true);

    const payload = data.map((each) => {
      return { levelId: each.levelId, taskId: each.taskId, ans: each.ans };
    });

    API.saveMentorMultiAnswers({ groupId, tasks: payload })
      .then((response) => {
        toast.success('Answers saved successfully when a Mentor edits their Assessment questions within their profile');
        // setFetchMentorTasks(true);
      })
      .catch((error) => {
        if (error.response.data.message.includes('empty')) {
          toast.error('Please answer all the questions');
        } else {
          toast.error(error.response.data.message);
        }
      })
      .finally(() => {
        setBtnLoading(false);
      });
  };

  useEffect(() => {
    if((selectedRole === 'mentor' || selectedRole === 'consultant')){
    dispatch(fetchMentorTasks({ groupId }));
    }
  }, []);

  /** Take answers */
  const takeAnswers = (answer: any, type: string, taskId: string, levelId: string) => {
    let existingAnswers = [...asmtAnswers];
    const findLevelIdAndTaskIdIndex = existingAnswers.findIndex(
      (each) => each.taskId === taskId && each.levelId === levelId
    );

    if (findLevelIdAndTaskIdIndex !== -1) {
      // Check if the answer type is 'List of industries' or 'List of roles'
      if (type === 'List of industries' || type === 'List of roles') {
        // Merge the new answers with the existing ones, removing duplicates
        existingAnswers[findLevelIdAndTaskIdIndex].answer = Array.from(
          new Set([...existingAnswers[findLevelIdAndTaskIdIndex].answer, ...answer])
        );
      } else {
        // Replace the answer for other types
        existingAnswers[findLevelIdAndTaskIdIndex].answer = answer;
      }
    } else {
      // Create a new entry for the task and level
      const newEntry = {
        answer: type === 'List of industries' || type === 'List of roles' ? answer : answer,
        type,
        taskId,
        levelId
      };
      existingAnswers.push(newEntry);
    }
    setAsmtAnswers(existingAnswers);
  };

  /** Extra */
  if (selectedRole !== 'mentor') {
    return <Navigate to="/" />;
  } else if (loading) {
    return <AssessmentQuestionsLoader />;
  } else if (tasksData.error) {
    return <div>{tasksData.errorText}</div>;
  }

  return (
    <Box width="100%" minHeight={'100%'} display={'inline-flex'}>
      <Box className="CardBox" minHeight={'100%'} width={'100%'}>
        <Box p={2}>
          <Typography variant="h5">Assessment Questions</Typography>
        </Box>
        <Divider />
        
        {(tasksData.data.data.length > 0) ? (
          <Box p={2}>
            {data.map((eachMentorTask: any, index: number) => {
              return (
                <Box key={eachMentorTask}>
                  <Box>
                    <Typography variant="h5" mb={2}>
                      {eachMentorTask?.question}
                    </Typography>
                    <GetMenteeOptions
                      type={eachMentorTask.ansType}
                      ansOptions={eachMentorTask.answerOptions}
                      answerGiven={eachMentorTask.ans}
                      maxMenteeAns={eachMentorTask.maxMentorAns}
                      minMenteeAns={eachMentorTask.minMentorAns}
                      index={index}
                    />
                  </Box>
                  {index !== data.length - 1 && <Divider sx={{ my: 2 }} />}
                </Box>
              );
            })}

            <Box mt={2}>
              <LoadingButton
                fullWidth
                variant="contained"
                onClick={handleSave}
                loading={btnLoading}
                className={'max-w-246px h-50px'}
              >
                Save
              </LoadingButton>
            </Box>
          </Box>
        ) : (
          <Box p={ forMobile ? 2 : 10 } height={'calc(100% - 62px)'} display={'flex'} alignItems={'center'}>
            <Typography textAlign={'center'}>
              You have no assessment questions to answer at the moment. Assessment questions are set by your program manager and help other members know how you can help them
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AssessmentQuestions;
