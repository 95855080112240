/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ChatAvatarComponent from '../ChatAvatarComponent';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import _ from 'lodash';
import { ChatStyle } from '../ChatStyling';
import { acsMessageNotificationActions } from '../../../../../redux/slices/acs-message-notifications/acsMessageNotificationsSlice';
import ChatUnreadNotificationsBox from '../ChatUnreadNotificationsBox';
import { API } from '../../../../../api';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

/** Chat person */
interface IChatPerson {
  type: 'personal-thread' | 'personal-user' | 'group-thread';
  name: string;
  threadId?: string;
  communicationUserId?: string;
  activeThreadId: string;
  activeCommId?: string;
  image?: string;
  index?: any;
  lastMessageReceivedOn?: string;
  lstMessage: any;
  lstMessageUserName: any;
  loading?: boolean;
  unReadMessageCount: any;
  deleted?: boolean;
  messageType?: string;
}

const ChatPerson: React.FC<IChatPerson> = ({
  type,
  name,
  threadId,
  communicationUserId,
  activeThreadId,
  activeCommId,
  image,
  index,
  lstMessage,
  lstMessageUserName,
  lastMessageReceivedOn,
  unReadMessageCount,
  loading,
  deleted,
  messageType = 'text'
}) => {
  const classes = ChatStyle();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [activeChatPersonIndex, setActiveChatPersonIndex] = useState(0);
  const handleClickOnChannel = async () => {
    switch (type) {
      case 'personal-thread':
        localStorage.setItem('acsdetails', JSON.stringify({ name: name }));
        dispatch(acsMessageNotificationActions.atnSetChatKind('chatDetails'));
        navigate(`/app/chat/?threadid=${threadId}&name=${name}&type=Chats`);
        await API.deleteMsgCount({ threadId: threadId });
        break;
      case 'personal-user':
        dispatch(acsMessageNotificationActions.atnSetChatKind('chatDetails'));
        navigate(`/app/chat/?commId=${communicationUserId}`);
        // console.log('communicationUserId');
        // await API.deleteMsgCount({ threadId: communicationUserId }).then((res) => console.log('ChatPereson-res', res));

        break;
      case 'group-thread':
        dispatch(acsMessageNotificationActions.atnSetChatKind('chatDetails'));
        navigate(`/app/chat/?threadid=${threadId}&name=${name}&type=Groups`);
        await API.deleteMsgCount({ threadId: threadId });
        break;
      default:
        break;
    }
  };

  // console.log('unReadMessageCount', unReadMessageCount);
  function extractLastLine(text: any) {
    const decodedText = text.replace(/&nbsp;/g, ' '); // Decode &nbsp; to space
    const strippedText = decodedText.replace(/<[^>]+>/g, ''); // Remove HTML tags
    const cleanedText = strippedText.replace(/\s+/g, ' ').trim(); // Condense spaces

    // if (cleanedText.length > 15) {
    //   return cleanedText.substring(0, 15) + '...'; // Show up to 15 characters followed by '...'
    // } else {
    //   return cleanedText; // Return the original text if it's less than or equal to 15 characters
    // }

    return cleanedText;
  }

  let totalChatPersons: any;
  // useEffect(() => {
  //   const handleKeyDown = (event: KeyboardEvent) => {
  //     if (event.key === 'Enter') {
  //       event.preventDefault();
  //       if (type === 'group-thread') {
  //         navigate(`/app/chat/?threadid=${threadId}&name=${name}&type=Groups`);
  //       } else if (type === 'personal-thread') {
  //         navigate(`/app/chat/?threadid=${threadId}&name=${name}&type=Chats`);
  //       }
  //     } else if (event.key === 'ArrowUp') {
  //       setCurrentThreadId((prev: any) => prev);
  //       console.log('sdv', currentThreadId);
  //     }
  //   };

  //   document.addEventListener('keydown', handleKeyDown);

  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, [activeChatPersonIndex, handleClickOnChannel, index]);

  const isActive = threadId === activeThreadId || communicationUserId === activeCommId;

  function formatMessageDate(timestamp: string) {
    const now = moment();
    const messageTime = moment(timestamp);

    if (messageTime.isSame(now, 'day')) {
      return messageTime.format('h:mm A');
    } else if (messageTime.isSame(now.clone().subtract(1, 'day'), 'day')) {
      return 'Yesterday';
    } else if (messageTime.isSame(now, 'week')) {
      return messageTime.format('dddd');
    } else {
      return messageTime.format('MMM D, YYYY');
    }
  }
  const refrence: React.RefObject<HTMLInputElement> = useRef(null);

  useEffect(() => {
    isActive && refrence?.current?.scrollIntoView();
  }, []);

  /** Active classes */
  const MyChatPersonWrapper = isActive ? classes.activeChatPersonWrapper : classes.chatPersonWrapper;
  // const keyChangeWrapper = activeChatPersonIndex === index && classes.activeKeyWrapper;
  const NameActiveColor = isActive ? '#FFFFFF' : 'primary';
  const DateActiveColor = isActive ? '#FFFFFF' : 'secondary.light';
  const SubTextActiveColor = isActive ? '#FFFFFF' : '';

  return (
    <Box
      className={`${MyChatPersonWrapper} ${classes.ChatPersonWrapperBox}`}
      onClick={handleClickOnChannel}
      ref={refrence}
    >
      <Box mr={1}>
        <ChatAvatarComponent
          image={image}
          firstLetter={name.slice(0, 1)}
          width="48px"
          height="48px"
          loading={loading}
        />
      </Box>
      <Box display="flex" flexDirection="column" flexGrow={1} alignItems="space-between" width="calc(100% - 60px)">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexGrow={1}
          whiteSpace="nowrap"
          gap="8px"
        >
          {/* Uses and group Name */}
          <Typography variant="h5" color={NameActiveColor} noWrap>
            {name}
          </Typography>

          {/* last Message recived time */}
          <Typography fontSize="12px" fontWeight="600" color={DateActiveColor}>
            {lastMessageReceivedOn ? formatMessageDate(lastMessageReceivedOn) : null}
          </Typography>
        </Box>

        {/* Last sent Message & Unread Message */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexGrow={1}
          whiteSpace="nowrap"
          gap="8px"
          width={'100%'}
        >
          {/* Lest send msg test */}
          {/* <Typography color={SubTextActiveColor} noWrap>
            Raj : This text for Last sent message size testing.
          </Typography> */}

          {/* Lest send msg print */}
          <Typography color={SubTextActiveColor} noWrap width="100%">
            {lstMessage && (
              <>
                {type === 'group-thread' &&
                  messageType === 'text' &&
                  (lstMessageUserName ? `${lstMessageUserName}: ` : 'You: ')}
                {deleted && <DoNotDisturbIcon sx={{ fontSize: '16px' }} />}
                <span style={{ fontStyle: deleted || messageType !== 'text' ? 'italic' : 'normal' }}>
                  {extractLastLine(lstMessage.replace(/<br>/g, ' ').replace(/<\/?p>/g, ''))}
                </span>
              </>
            )}
          </Typography>

          {/* Unread Message */}
          {unReadMessageCount > 0 ? (
            <ChatUnreadNotificationsBox count={unReadMessageCount} printAres="besideChat" />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default ChatPerson;
