import { useAuth0 } from '@auth0/auth0-react';
import { persistor } from '../redux/store';
import Cookies from 'js-cookie';
import { getUserDetails } from '../utils/orgName';

const useLogout = () => {
  const { logout } = useAuth0();
  function clearAllCookies() {
    const cookies = Object.keys(Cookies.get()); // Get all cookie names

    cookies.forEach((cookieName) => {
      Cookies.remove(cookieName, { path: '' });
    });
  }

  const doLogout = async () => {
    const { orgName } = getUserDetails();
    const queryCallback = orgName ? `/#/login?org=${orgName}&prompt=true` : '/#/login?prompt=true';
    localStorage.clear();
    localStorage.removeItem('persist:root');
    persistor.purge();
    clearAllCookies();
    logout({ returnTo: window.location.origin + queryCallback, federated: false });
  };
  return { doLogout };
};
export default useLogout;
