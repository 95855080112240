import { createSlice } from '@reduxjs/toolkit';
import { IArticleData } from './article/getAllArticleSlice';

export interface IAdminTask {
  tags?: string[];
  taskName?: string;
  taskDescription?: string;
  menteeQues?: string;
  menteeAnsType?: string;
  mentorAnswers?: any[];
  taskPosition?: number;
  maxMenteeAns?: number;
  minMenteeAns?: number;
  menteeAsmtQues?: string;
  menteeAsmtAnsType?: string;
  menteeAsmtAnsOptions?: string[];
  libraryArticles?: any;
  _id?: string;
  error?: boolean;
  topics?: string[];
  isNewTask?: boolean;
}

export interface ITaskLevels {
  levelName: string;
  level: number;
  _id?: string;
  tasks: IAdminTask[];
  isLevelAdd?: boolean;
  isTaskAdd?: boolean;
  isNewLevel?: boolean;
}

export interface ITaskData {
  goalName: string;
  levels: ITaskLevels[];
  programType: string;
  _id?: string;
}

export interface IInitialTaskState {
  data: ITaskData | null;
}

const initialState: IInitialTaskState = {
  data: null
};

const tasksSlice = createSlice({
  name: 'admin tasks',
  initialState,
  reducers: {
    updateTasks: (state, action) => {
      state.data = action.payload;
    }
  }
});

export default tasksSlice.reducer;
export const adminTaskActions = tasksSlice.actions;
