import React, { ChangeEvent, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Dialog,
  Divider,
  TextField,
  IconButton,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@mui/material';
import dosenLogo from '../../../assets/images/favicon.svg';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from '../../../redux/hooks';
import CommonModalHeader from '../../../components/DialogUi/CommonModalHeader';
import { toast } from 'react-toastify';
import { API } from '../../../api';
import { LoadingButton } from '@mui/lab';

const DeactivatedUser: React.FC = () => {
  const [openContactAdmin, setOpenContactAdmin] = useState(false);
  const [value, setValue] = useState('');
  const [loader, setLoader] = useState(false);
  const handleOpenContactAdmin = () => {
    setOpenContactAdmin(true);
  };
  const handleConcernSubmit = async () => {
    if (!value.trim()) {
      toast.error('Please add anything relevant to the concern.');
      return;
    }
    try {
      setLoader(true);
      const data = { reasonOfConcern: value.trim() };
      const responseFYE = await API.saveConcernWithEmail(data);
      if (responseFYE.status === 200 && responseFYE.statusText === 'OK') {
        setLoader(false);
        toast.success('Concern raised successfully');
        handleCloseContactAdmin();
      }
    } catch {
      setLoader(false);
      toast.error('Something went wrong!');
    }
  };

  const handleCloseContactAdmin = () => {
    setOpenContactAdmin(false);
    setValue('');
  };
  const isActive = useAppSelector((state) => state.selectedGroup.data.active);
  if (isActive) {
    window.location.replace('/');
    return null;
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };
  return (
    <>
      <Box className="bodyBox" display="flex" alignItems="center" justifyContent="center">
        <Box
          sx={{
            background: '#FFFFFF',
            border: '1px solid #EFF0F4',
            borderRadius: '8px',
            padding: '20px',
            '@media (min-width: 600px)': {
              padding: '50px' // Desktop padding
            },
            textAlign: 'center'
          }}
        >
          <Box mb={2}>
            <img src={dosenLogo} alt="logo" width="40px" height="40px" />
          </Box>
          <Typography variant="h1" color="error">
            Your account has been <br /> deactivated
          </Typography>
          <Typography variant="subtitle1" my={2}>
            Please reach out to your program administrator for
            <br /> further information
          </Typography>
          <Button fullWidth variant="contained" onClick={handleOpenContactAdmin}>
            Contact us
          </Button>
        </Box>
      </Box>
      <Dialog open={openContactAdmin} onClose={handleCloseContactAdmin} maxWidth="sm" fullWidth>
        {/* Dialog Header */}
        <CommonModalHeader title={`Message your Program Admin`} handleCloseModal={handleCloseContactAdmin} />
        <DialogContent>
          <Typography variant="subtitle1" mb={1}>
            Message
          </Typography>
          <TextField
            value={value}
            onChange={handleChange}
            multiline
            rows={10}
            placeholder="Hi,you can write here .."
            fullWidth
          />
        </DialogContent>
        <DialogActions className="pt-0">
          <LoadingButton fullWidth variant="contained" onClick={handleConcernSubmit} loading={loader}>
            Submit
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeactivatedUser;
