/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import ChatMessageContent from './ChatMessageContent';
import ChatEditorComponent from '../ChatEditorComponent';
import ChatThreadDetails from './ChatThreadDetails';
import NoChats from '../NoChats';
import ChatProfile from './ChatProfile';
import _ from 'lodash';
import ChatMainHeader from './ChatMainHeader';
import { ChatStyle } from '../ChatStyling';
import ChatGroupDetails from './ChatGroupDetails';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { fetchUserEducation } from '../../../../../redux/slices/user-education/userEducationSlice';
import { fetchUserWorkHistory } from '../../../../../redux/slices/user-work/userWorkHistorySlice';
import useUserDetailsById from '../../../../../queries/useUserDetailsById';
import { userIdUpdate } from '../../../../../redux/slices/userId/userIdSlice';
import { toast } from 'react-toastify';
import useResponsiveScreenSwitch from '../../../../../utils/useResponsiveScreenSwitch';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import { acsMessageNotificationActions } from '../../../../../redux/slices/acs-message-notifications/acsMessageNotificationsSlice';
import AccessingChatMsg from './AccessingChatMsg';
import { fetchAcsToken } from '../../../../../redux/slices/acs-token/acsTokenSlice';
import { getUserDetails } from '../../../../../utils/orgName';
import { acsChannelActions } from '../../../../../redux/slices/acs-channels/acsChannels';
import { selectedChatUserActions } from '../../../../../redux/slices/selectedChatUserSlice';
import { fetchChatUsers } from '../../../../../redux/slices/getChatUsersSlice';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { showMyLoader } from '../../../components/common';
import { ChatMsgContentLoader } from '../../../../../Loader/chat/ChatLoader';
import { eventTracking, events } from '../../../../../utils/amplitude';

interface IChatMain {
  threadValue: string;
  file: any;
  activeChat: string;
  chatType: string;
  setUpdateFile: any;
  handleFileUpload: any;
  setChatInfoForm: React.Dispatch<boolean>;
  channelImage: string;
  channel: string;
  onClickOnUserIcon: () => void;
  acsChannelParticipants: IAcsUsers[];
  formattedAcsOrgUsers: IFormattedAcsOrgUser[];
  handleAddPersonToThread: () => void;
  disabled: boolean;
  messageId: string;
  updateMessageLoading: boolean;
  timelineRef: React.MutableRefObject<any>;
  acsMessagesData: IChatMessage[];
  data: any;
  setFile: any;
  fetchNextPage: any;
  isFetchingNextPage: boolean;
  hasNextPage: boolean | undefined;
  error: any;
  isLoading: boolean;
  disableSendButton: boolean;
  updateEditor: any;
  setEditCancel: any;
  handlePostMessage: any;
  editorState: any;
  setEditorState: any;
  handleEditCancel: () => void;
  showEditCancel: boolean;
  openChatInfoForm: boolean;
  handleChatInfoForm: (flag: boolean) => void;
  groupChatInfo: any;
  removeParticipantFromThread: any;
  chatProfile: any;
  userWorkHistory: any;
  userEducation: any;
  removeBlock: any;
  onCloseChatProfile: () => void;
  setActiveChat: any;
  openGroupDetails: any;
  setopenGroupDetails: any;
  openChatProfile: any;
  setOpenChatProfile: any;
  setAnchorEl: any;
}

const ChatMain: React.FC<IChatMain> = ({
  threadValue,
  activeChat,
  chatType,
  setChatInfoForm,
  handleFileUpload,
  file,
  setUpdateFile,
  channelImage,
  channel,
  onClickOnUserIcon,
  acsChannelParticipants,
  formattedAcsOrgUsers,
  handleAddPersonToThread,
  disabled,
  messageId,
  updateMessageLoading,
  timelineRef,
  acsMessagesData,
  data,
  setFile,
  fetchNextPage,
  isFetchingNextPage,
  hasNextPage,
  error,
  isLoading,
  disableSendButton,
  updateEditor,
  removeBlock,
  setEditCancel,
  handlePostMessage,
  editorState,
  setEditorState,
  handleEditCancel,
  showEditCancel,
  openChatInfoForm,
  handleChatInfoForm,
  groupChatInfo,
  removeParticipantFromThread,
  chatProfile,
  userWorkHistory,
  userEducation,
  onCloseChatProfile,
  setActiveChat,
  openGroupDetails,
  setopenGroupDetails,
  openChatProfile,
  setOpenChatProfile,
  setAnchorEl
}) => {
  const classes = ChatStyle();

  // const [openGroupDetails, setopenGroupDetails] = useState(false);
  // const [openChatProfile, setOpenChatProfile] = useState(false);
  const { forMobile } = useResponsiveScreenSwitch(); // Use the hook
  const groupdUserWorkHistory = useAppSelector((state) => state.userWorkHistory);
  const groupUserEducation = useAppSelector((state) => state.userEducation);
  const groupChatProfile = useAppSelector((state) => state.chatProfile.chatProfileDetails);
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const selectedChatUser = useAppSelector((state) => state.selectedChatUser.data);
  const [selected, setSelected] = useState<any>('');
  const acsTokenLoading = useAppSelector((state) => state.acsToken.loading);
  const selectedMember = (mem: any) => {
    if (mem) {
      setSelected(mem);
      setOpenChatProfile(true);
      setopenGroupDetails(false);
    } else {
      toast.error('Selected member is not in your community list');
    }
  };

  // const onClickOnUserIcon = () => {
  //   if (formattedAcsOrgUsers) {
  //     dispatch(chatProfileActions.atnSetChatProfileState(channelUser));
  //     // to call member education api
  //     // const commId = _.get(channelUser, 'id.communicationId','');
  //     // const userId = _.get(formattedAcsOrgUsers, `${commId}.userId`,'');
  //     const userId = selected?.userId;
  //     if (activeChat === 'Chats') {}
  //   }
  // };

  const selectedUserId = useAppSelector((state) => state.userId.data);

  useEffect(() => {
    let newUserId = '';
    if (_.size(selected) && openChatProfile) {
      newUserId = _.get(selected, 'id.coId', '');
    } else {
      newUserId = _.get(chatProfile, 'id.coId', '');
    }
    if (newUserId) {
      dispatch(userIdUpdate(newUserId));
    }
  }, [selectedMember]);

  const userData = useUserDetailsById();
  const userDetails = userData?.data?.data?.data;

  /**
   * member chat profile data fetch after click close chat profile button
   */
  const userDataFetch = () => {
    userData.refetch();
  };
  const selectedGroup = useAppSelector((state) => state.selectedGroup.data);
  useEffect(() => {
    userData.refetch().then(() => {
      if (selectedGroup.role !== 'admin') {
        eventTracking(events.profileViewed, { page: 'Chat' });
      }
    });
  }, [selectedUserId]);

  useEffect(() => {
    if (chatType !== 'Groups' && (openGroupDetails || openChatProfile)) {
      setopenGroupDetails(false);
      setOpenChatProfile(false);
    }
  }, [chatType]);

  useEffect(() => {
    const userId = selected?.userId;
    if (userId) {
      dispatch(fetchUserEducation(userId?.trim()));
      dispatch(fetchUserWorkHistory(userId?.trim()));
    }
  }, [selected]);

  const handleClose = () => {
    setOpenChatProfile(false);
  };

  useEffect(() => {
    if (threadValue) {
      setAnchorEl(null);
    }
  }, [threadValue]);

  const exitChat = () => {
    localStorage.removeItem('acsToken');
    localStorage.setItem('chatAccessed', JSON.stringify(true));
    dispatch(
      fetchAcsToken({
        communicationId: getUserDetails().communicationUserId,
        tag: '#admin'
      })
    ).then(() => {
      dispatch(
        acsChannelActions.atnSaveChannels2({
          groupThreads: [],
          personalThreadsArr: []
        })
      );
      queryClient.fetchQuery({ queryKey: ['getAcsChatThreads'] });
    });
    dispatch(
      selectedChatUserActions.update({
        communicationId: '',
        userId: '',
        name: ''
      })
    );
    let searchTerm = '';
    dispatch(fetchChatUsers(searchTerm));
    navigate('/app/chat', { replace: true });
  };

  return (
    <Box className="containerBoxRight">
      {/* Back btn for mobile */}
      {forMobile ? (
        <Box
          display="flex"
          mb={0.5}
          alignContent="center"
          sx={{ cursor: 'pointer', '& svg': { height: '20px' } }}
          onClick={() => dispatch(acsMessageNotificationActions.atnSetChatKind('chat'))}
        >
          <ArrowBackIosNewOutlinedIcon />
          <Typography variant="h5" lineHeight="normal">
            Back
          </Typography>
        </Box>
      ) : null}

      <Grid container height={forMobile ? 'calc(100% - 26px)' : '100%'}>
        {forMobile && openGroupDetails ? null : (
          <Grid item xs className={classes.chatWrapperBody}>
            {threadValue ? (
              <>
                {/* {threadValue && activeChat === chatType ? ( */}
                <Box className={classes.chatContent}>
                  {/* Accessing Chat notification */}
                  {/* Back for mobile */}
                  {selectedChatUser.communicationId && (
                    <AccessingChatMsg
                      exitChat={exitChat}
                      displayName={selectedChatUser?.communicationId}
                      formattedAcsOrgUsers={formattedAcsOrgUsers}
                    />
                  )}

                  <ChatMainHeader
                    acsChannelParticipants={acsChannelParticipants}
                    activeChat={activeChat}
                    channelImage={channelImage}
                    chatType={chatType}
                    formattedAcsOrgUsers={formattedAcsOrgUsers}
                    handleAddPersonToThread={handleAddPersonToThread}
                    setChatInfoForm={setChatInfoForm}
                    channel={channel}
                    isLoading={isLoading}
                    onClickOnUserIcon={onClickOnUserIcon}
                    setopenGroupDetails={setopenGroupDetails}
                    removeParticipantFromThread={removeParticipantFromThread}
                  />
                  {acsTokenLoading ? (
                    <ChatMsgContentLoader />
                  ) : (
                    <ChatMessageContent
                      disabled={disabled}
                      channelName={channel}
                      file={file}
                      editingMessageId={messageId}
                      updateMessageLoading={updateMessageLoading}
                      innerRef={timelineRef}
                      data={_.uniqBy(
                        [
                          ...acsMessagesData,
                          ..._.flattenDeep(_.map(_.get(data, 'pages'), (each) => _.get(each, 'value')))
                        ].sort((a, b) => _.parseInt(_.get(b, 'sequenceId')) - _.parseInt(_.get(a, 'sequenceId'))),
                        'sequenceId'
                      )}
                      fetchNextPage={fetchNextPage}
                      isFetchingNextPage={isFetchingNextPage}
                      hasNextPage={hasNextPage}
                      error={error}
                      isLoading={isLoading}
                      disableSendButton={disableSendButton || isLoading}
                      updateEditor={updateEditor}
                      setEditCancel={setEditCancel}
                      handlePostMessage={handlePostMessage}
                    />
                  )}

                  <ChatEditorComponent
                    disableSendButton={disableSendButton}
                    editorState={editorState}
                    setFile={setFile}
                    file={file}
                    isLoading={isLoading}
                    handleFileUpload={handleFileUpload}
                    setUpdateFile={setUpdateFile}
                    handlePostMessage={handlePostMessage}
                    setEditorState={setEditorState}
                    handleEditCancel={handleEditCancel}
                    showEditCancel={showEditCancel}
                    channelName={channel}
                    setEditCancel={setEditCancel}
                    activeMessageId={messageId}
                  />
                </Box>
              </>
            ) : (
              <NoChats activeChat={activeChat} />
            )}
            {chatType === 'Groups' ? (
              <ChatThreadDetails
                openChatInfoForm={openChatInfoForm}
                handleChatInfoForm={handleChatInfoForm}
                channelName={channel}
                groupChatInfo={groupChatInfo}
                threadId={threadValue}
                removeParticipantFromThread={removeParticipantFromThread}
              />
            ) : null}
          </Grid>
        )}

        {/* Chat Group Details */}
        {openGroupDetails && chatType === 'Groups' ? (
          <Grid item width="350px" height={'100%'}>
            <ChatGroupDetails
              setopenGroupDetails={setopenGroupDetails}
              acsChannelParticipants={acsChannelParticipants}
              onClickOnUserIcon={onClickOnUserIcon}
              setChatInfoForm={setChatInfoForm}
              channel={channel}
              chatType={chatType}
              handleAddPersonToThread={handleAddPersonToThread}
              channelImage={channelImage}
              workHistory={userWorkHistory}
              educationDetails={userEducation}
              chatProfile={chatProfile}
              selectedMember={selectedMember}
              removeParticipantFromThread={removeParticipantFromThread}
              // loading={true}
            />
          </Grid>
        ) : null}

        {/* Group member Profile Details and profile Details not shoing now for mobile */}
        {!forMobile ? (
          <>
            {/* Personal Profile */}
            {_.size(chatProfile) ? (
              <Grid item width="350px" height={'100%'}>
                <ChatProfile
                  workHistory={userWorkHistory}
                  educationDetails={userEducation}
                  chatProfile={userDetails}
                  onCloseChatProfile={() => {
                    userDataFetch();
                    onCloseChatProfile();
                  }}
                  setActiveChat={setActiveChat}
                  parentComponent="sideSmallProfile"
                  digitalIntro={true}
                  // loading={true}
                />
              </Grid>
            ) : null}

            {/* Group Details to open Personal Profile */}
            {_.size(selected) && openChatProfile ? (
              <Grid item width="350px" height={'100%'}>
                <ChatProfile
                  workHistory={groupdUserWorkHistory}
                  educationDetails={groupUserEducation}
                  chatProfile={userDetails}
                  onCloseChatProfile={() => {
                    userDataFetch();
                    setOpenChatProfile(false);
                    setopenGroupDetails(true);
                  }}
                  setActiveChat={setActiveChat}
                  parentComponent="sideSmallProfile"
                  digitalIntro={true}
                  // loading={true}
                />
              </Grid>
            ) : null}
          </>
        ) : null}
      </Grid>
    </Box>
  );
};

export default ChatMain;
