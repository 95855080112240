import React, { useState, useContext, useRef, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import _ from 'lodash';
import ZoomContext from '../../context/zoom-context';
import ZoomMediaContext from '../../context/media-context';
import AvatarActionContext from './context/avatar-context';
import Avatar from './components/avatar';
import VideoFooter from './components/video-footer';
import Pagination from './components/pagination';
import ShareView from './components/share-view';
import RemoteCameraControlPanel from './components/remote-camera-control';
import { useCanvasDimension } from './hooks/useCanvasDimension';
import { useGalleryLayout } from './hooks/useGalleryLayout';
import { usePagination } from './hooks/usePagination';
import { useActiveVideo } from './hooks/useAvtiveVideo';
import { useNetworkQuality } from './hooks/useNetworkQuality';
import { useAvatarAction } from './hooks/useAvatarAction';

import { SELF_VIDEO_ID } from './video-constants';
import './video.scss';
import { useAppSelector } from '../../../../../../redux/hooks';
import { ChatRecord } from '../chat/chat-types';
import Videocallchat from '../chat/videocallchat';

interface SelfViewContainer {
  id: string;
  className: string;
  style?: Record<string, any>;
  isRenderSelfViewWithVideoElement: boolean;
}

function getStyleAttributeNumericalValue(attr: string) {
  const v = /(\d+)/.exec(attr)?.[1];
  return v ? Number(v) : 0;
}

function SelfViewContainer(props: SelfViewContainer) {
  const { isRenderSelfViewWithVideoElement, ...restProps } = props;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { style } = restProps;
  const { mediaStream } = useContext(ZoomMediaContext);
  useEffect(() => {
    if (!isRenderSelfViewWithVideoElement && canvasRef.current && style) {
      // const width = getStyleAttributeNumericalValue(style.width);
      // const height = getStyleAttributeNumericalValue(style.height);
      // const width = 1254;
      // const height = 701;
      // const width = 1244;
      // const height = 701;
      const width = 598;
      const height = 332;
      
      try {
        canvasRef.current.width = width;
        canvasRef.current.height = height;
      } catch (e) {
        mediaStream?.updateVideoCanvasDimension(canvasRef.current, width, height);
      }
    }
  }, [isRenderSelfViewWithVideoElement, style, mediaStream]);
  return isRenderSelfViewWithVideoElement ? <video {...restProps} /> : <canvas ref={canvasRef} {...restProps} />;
}

const VideoContainer: React.FunctionComponent = (props) => {
  const zmClient = useContext(ZoomContext);
  const {
    mediaStream,
    video: { decode: isVideoDecodeReady }
  } = useContext(ZoomMediaContext);
  const videoRef = useRef<HTMLCanvasElement | null>(null);
  const [isRecieveSharing, setIsRecieveSharing] = useState(false);
  const shareViewRef = useRef<{ selfShareRef: HTMLCanvasElement | HTMLVideoElement | null }>(null);
  const canvasDimension = useCanvasDimension(mediaStream, videoRef);
  const activeVideo = useActiveVideo(zmClient);
  const { page, pageSize, totalPage, totalSize, setPage } = usePagination(zmClient, canvasDimension);
  const { visibleParticipants, layout: videoLayout } = useGalleryLayout(
    zmClient,
    mediaStream,
    isVideoDecodeReady,
    videoRef,
    canvasDimension,
    {
      page,
      pageSize,
      totalPage,
      totalSize
    }
  );
  /**
   * position for self video
   */
  const currentUserIndex = visibleParticipants.findIndex(
    (user) => user.userId === zmClient.getCurrentUserInfo()?.userId
  );
  let selfVideoLayout = null;
  if (currentUserIndex > -1) {
    const item = videoLayout[currentUserIndex];
    if (item && canvasDimension) {
      selfVideoLayout = { ...item, y: canvasDimension.height - item.y - item.height };
    }
  }
  const avatarActionState = useAvatarAction(zmClient, visibleParticipants);
  const networkQuality = useNetworkQuality(zmClient);
  const [chatRecords, setChatRecords] = useState<ChatRecord[]>([]);
  const chatWrapRef = useRef<HTMLDivElement | null>(null);
  const userData = useAppSelector((state) => state.userProfile.data);
  const currentUserInfo = zmClient.getCurrentUserInfo();
  const communityMembers = useAppSelector((state) => state.acsCommunityUsers.data);
  const onChatMessage = useCallback((payload: ChatRecord) => {
    const updatedPayload = {
      ...payload,
      sender: {
        ...payload.sender
      }
    };

    setChatRecords((records: ChatRecord[]) => {
      const { length } = records;
      if (length > 0) {
        const lastRecord = records[length - 1];
        if (
          updatedPayload.sender.userId === lastRecord.sender.userId &&
          updatedPayload.receiver.userId === lastRecord.receiver.userId &&
          updatedPayload.timestamp - lastRecord.timestamp < 1000 * 60 * 5
        ) {
          if (Array.isArray(lastRecord.message)) {
            lastRecord.message.push(updatedPayload.message as string);
          } else {
            lastRecord.message = [lastRecord.message, updatedPayload.message as string];
          }
        } else {
          records.push(updatedPayload);
        }
      } else {
        records.push(updatedPayload);
      }
      return [...records];
    });

    if (chatWrapRef.current) {
      chatWrapRef.current.scrollTo(0, chatWrapRef.current.scrollHeight);
    }
  }, []);

  // Function to get the avatar for a given userId
  // function getAvatarForUserId(userId: number): string | undefined {
  //   const currentUserInfo = zmClient.getCurrentUserInfo();
  //   if (currentUserInfo?.userId === userId) {
  //     return userData?.personal_details?.headshot;
  //   } else {
  //     const participants = zmClient.getAllUser();
  //     const zoomMemberRow = participants.find((mem) => mem?.userId === userId);
  //     const memberRow = communityMembers?.find((obj) => obj?.id?.email === zoomMemberRow?.userIdentity);
  //     return memberRow?.id.headshot;
  //   }
  // }
  useEffect(() => {
    zmClient.on('chat-on-message', onChatMessage);
    return () => {
      zmClient.off('chat-on-message', onChatMessage);
    };
  }, [zmClient, onChatMessage]);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const handleChatOpen = () => {
    setIsChatOpen((prevIsChatOpen) => !prevIsChatOpen);
  };

  return (
    <div className="viewport">
      <ShareView ref={shareViewRef} onRecieveSharingChange={setIsRecieveSharing} />
      <div
        className={classnames('video-container', {
          'video-container-in-sharing': isRecieveSharing
        })}
      >
        <canvas className="video-canvas" id="video-canvas" width="800" height="600" ref={videoRef} />

        <SelfViewContainer
          id={SELF_VIDEO_ID}
          className={classnames('self-video-non-sab')}
          isRenderSelfViewWithVideoElement={!!mediaStream?.isRenderSelfViewWithVideoElement()}
          style={
            selfVideoLayout
              ? {
                  display: currentUserInfo?.bVideoOn ? 'block' : 'none',
                  width: `${selfVideoLayout.width}px`,
                  height: `${selfVideoLayout.height}px`,
                  top: `${selfVideoLayout.y}px`,
                  left: `${selfVideoLayout.x}px`,
                  pointerEvents: 'none',
                  transform: `scaleX(-1)`
                  // borderRadius: '8px'
                }
              : undefined
          }
        />
        <AvatarActionContext.Provider value={avatarActionState}>
          <ul className="avatar-list">
            {visibleParticipants.map((user, index) => {
              if (index > videoLayout.length - 1) {
                return null;
              }
              const dimension = videoLayout[index];
              const { width, height, x, y } = dimension;
              const { height: canvasHeight } = canvasDimension;
              return (
                <Avatar
                  participant={user}
                  key={user.userId}
                  isActive={activeVideo === user.userId}
                  style={{
                    width: `${width - 10}px`,
                    height: `${height}px`,
                    top: `${canvasHeight - y - height}px`,
                    left: `${x + 5}px`,
                  }}
                  networkQuality={networkQuality[`${user.userId}`]}
                />
              );
            })}
          </ul>
          <RemoteCameraControlPanel />
        </AvatarActionContext.Provider>
      </div>
      <VideoFooter
        className="video-operations"
        sharing
        selfShareCanvas={shareViewRef.current?.selfShareRef}
        onChatOpen={handleChatOpen}
      />
      {totalPage > 1 && <Pagination page={page} totalPage={totalPage} setPage={setPage} inSharing={isRecieveSharing} />}
      {isChatOpen && <Videocallchat onChatClick={isChatOpen} chatData={chatRecords} />}
    </div>
  );
};

export default VideoContainer;
