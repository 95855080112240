import { Grid, TextField, InputLabel, Typography, Autocomplete, FormHelperText } from '@mui/material';
import _ from 'lodash';
import { Controller } from 'react-hook-form';
import { lockIcon } from '../../../../../components/common';
import { useAppSelector } from '../../../../../../../redux/hooks';

interface MembersProps {
  control: any;
  updateMemberValue?: any;
  setBasicUpdateFlag?: any;
  place: string;
  // getError: (fieldName: string) => string | undefined;
  getError?: any;
  checkError?: (fieldName: string) => boolean;
  errors?: any;
}

export const Members = ({
  control,
  setBasicUpdateFlag,
  updateMemberValue,
  place,
  checkError,
  getError
}: MembersProps) => {
  const adminOptions = [
    'Student',
    'Alumni',
    'Faculty',
    'Staff',
    'Entrepreneur',
    'Advisor',
    'Employee',
    'Program Friend'
  ];
  const menteeOptions = ['mentee', 'client', 'employee', 'student'];
  const mentorOptions = ['mentor', 'coach', 'consultant', 'student', 'faculty', 'manager', 'buddy', 'employee'];
  const orgData = useAppSelector((state) => state.orgDetails.data?.userTypes);
  const disabled = false;

  return (
    <Grid container spacing={2} className={disabled ? 'opacity-25' : ''}>
      <Grid gap="5px" item xs={12} display="flex" direction="row" alignItems="center">
        <Typography variant="h5">Members</Typography>
        {disabled && lockIcon({})}
      </Grid>

      {place === 'OrganizationInformation' && (
        <Grid item xs={12}>
          <InputLabel>What member types will you have in your organization? *</InputLabel>
          <Controller
            control={control}
            name="userTypes"
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                disabled={disabled}
                options={adminOptions}
                getOptionLabel={(option) => (option ? _.startCase(option) : '')}
                renderInput={(params) => <TextField {...params} placeholder="Select" />}
                value={place === 'OrganizationInformation' ? value || [] : orgData || []}
                onChange={(_event, newValue) => onChange(newValue)}
                multiple
                disableCloseOnSelect
              />
            )}
          />
          {/* <FormHelperText error>{checkError('mentee') && getError('mentee')?.toString()}</FormHelperText> */}
          <FormHelperText error>
            {checkError && checkError('userTypes') && getError && getError('userTypes')?.toString()}
          </FormHelperText>
        </Grid>
      )}

      <Grid item xs={12}>
        <InputLabel>What name do you want to give to the person receiving advice? *</InputLabel>
        <Controller
          control={control}
          name="mentee"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              disabled={disabled}
              options={menteeOptions.sort((a, b) => (a > b ? 1 : -1))}
              getOptionLabel={(option) => (option ? _.startCase(option) : '')}
              renderInput={(params) => <TextField {...params} placeholder="Select" />}
              value={value || []}
              onChange={(_event, newValue) => {
                onChange(newValue);
                setBasicUpdateFlag(true);
                updateMemberValue();
              }}
            />
          )}
        />
        {/* <FormHelperText>{checkError('mentee') && getError('mentee')?.string()}</FormHelperText> */}
        <FormHelperText error>
          {checkError && checkError('mentee') && getError && getError('mentee')?.toString()}
        </FormHelperText>
      </Grid>

      <Grid item xs={12}>
        <InputLabel>What name do you want to give to the person providing advice? *</InputLabel>
        <Controller
          control={control}
          name="mentor"
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              disabled={disabled}
              options={mentorOptions.sort((a, b) => (a > b ? 1 : -1))}
              getOptionLabel={(option) => (option ? _.startCase(option) : '')}
              renderInput={(params) => <TextField {...params} placeholder="Select" />}
              value={value || []}
              onChange={(_event, newValue) => {
                onChange(newValue);
                setBasicUpdateFlag(true);
                updateMemberValue();
              }}
            />
          )}
        />
        <FormHelperText error>
          {checkError && checkError('mentor') && getError && getError('mentor')?.toString()}
        </FormHelperText>
      </Grid>
    </Grid>
  );
};
