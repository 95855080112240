/* eslint-disable max-depth */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react';
import {
  TextField,
  DialogActions,
  Stack,
  Typography,
  Grid,
  Autocomplete,
  Button,
  Box,
  InputLabel,
  RadioGroup,
  Radio,
  FormControlLabel
} from '@mui/material';
import _ from 'lodash';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { LoadingButton } from '@mui/lab';
import { API } from '../../../api';
import { toast } from 'react-toastify';
import { dialogActions } from '../../../redux/slices/dialog-slice/dialogSlice';
import { getUserDetails } from '../../../utils/orgName';
import { acsChannelActions } from '../../../redux/slices/acs-channels/acsChannels';
import ronan from '../../../assets/images/users/ronan.png';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import ROLES from '../../../utils/roles';
import { getChatUsers } from '../../../routes/helpers';
import { handleImageCompression, imageSizeLimit } from '../../../pages/new-designs/components/common';
import { useXpPoints } from '../../../pages/new-designs/components/menteeXpPoints';
import useProfileDetails from '../../../hooks/useProfileDetails';
import { useNavigate } from 'react-router-dom';
// import axios from "axios";
const imgSize = imageSizeLimit();

const schema = yup
  .object({
    topic: yup.string().required('Group name is required'),
    participants: yup
      .array()
      .of(
        yup.object({
          displayName: yup.string().required('display name required'),
          id: yup.object({
            communicationUserId: yup.string().required('communication id required')
          })
        })
      )
      .test('required', 'Participants are required', (value, context) => {
        if (value?.length) return true;
        return false;
      }),
    image: yup.mixed().test('fileSize', 'We cannot upload this file, please try another', (value) => {
      return !value || value[0]?.size <= imgSize.limitValue;
    })
    // .required('Image is required') // 10 MB
  })
  .required();

const ValidationError = ({ errors, fieldName }: { errors: Object; fieldName: string }) => (
  <Typography color="red" px={0.5} variant="subtitle2">
    {_.get(errors, `${fieldName}.message`, '')}
  </Typography>
);

const AcsCreateChannelDialog: React.FC = () => {
  const role = localStorage.getItem('role');
  const getXpPoints = useXpPoints();
  const dispatch = useAppDispatch();
  const [channelCreateLoading, setChannelCreateLoading] = useState(false);
  const orgDetails = useAppSelector((state) => state.orgIdReducer);
  const acsToken = useAppSelector((state) => state.acsToken.data.token);
  const acsOrgUsers = useAppSelector((state) => state.acsCommunityUsers.data);
  const acsChannels = useAppSelector((state) => state.acsChannels);
  const loginUser = useAppSelector((state) => state.userProfile.data);
  const { mentee } = useProfileDetails();
  const selectedGroup = useAppSelector((state) => state.selectedGroup.data);
  const selectedChatUser = useAppSelector((state) => state.selectedChatUser.data);

  const admins = _.get(loginUser, 'admins');
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue
  } = useForm({
    resolver: yupResolver(schema)
  });

  const desiredAcsUsers = useMemo(() => {
    const loginUserRole = localStorage.getItem('role');
    return getChatUsers(acsOrgUsers, loginUserRole, watch('participants'), admins);
  }, [acsOrgUsers, watch('participants')]);
  const navigate = useNavigate();
  const onSubmit = async (values: any) => {
    if (acsChannels?.groupThreads) {
      const filtered = acsChannels?.groupThreads?.findIndex((each, idx) => {
        return each?.topic === values?.topic;
      });
      if (filtered === -1) {
        let url = '';
        const { orgId, location } = getUserDetails();
        setChannelCreateLoading(true);
        const modifiedValues = {
          ...values,
          participants: _.map(values?.participants, (each) => ({
            ...each,
            id: { communicationUserId: _.get(each, 'id.communicationUserId', '') }
          })),
          azureToken: acsToken
        };
        let imageResponse = 200;
        try {
          const response = await API.createACSThread(acsToken, modifiedValues, orgId);
          const { status } = response;

          if (values.image) {
            const allowedExtensions = ['.jpg', '.jpeg', '.png', '.svg', '.gif'];
            const fileExtension = values.image[0].name.toLowerCase().slice(-4);
            if (!allowedExtensions.includes(fileExtension)) {
              toast.error('The unsupported image/file format');
            } else {
              const compressedImage = await handleImageCompression(values.image[0]);
              const formData = new FormData();
              formData.append('threadId', response.data.threadId);
              if (compressedImage) {
                formData.append('image', compressedImage);
                url = URL.createObjectURL(compressedImage);
              }
              const response01 = await API.updateGroupPic({
                orgId,
                location,
                formData
              });
              imageResponse = response01?.status;
            }
          }

          if (status === 200 && imageResponse === 200) {
            navigate(`/app/chat/?threadid=${response.data.threadId}&name=${values.topic}&type=Groups`);
            dispatch(
              acsChannelActions.createGroupThread({
                id: response.data.threadId,
                topic: values.topic,
                lastMessageReceivedOn: new Date().toISOString(),
                image: url
              })
            );

            setValue('image', '');
            setChannelCreateLoading(false);
            // toast.success('Channel created successfully');
            if (selectedGroup?.role === 'mentee') {
              getXpPoints('mentee');
            }
            dispatch(dialogActions.atnCloseDialog());
          }
        } catch (err) {
          setChannelCreateLoading(false);
          toast.error('Something went wrong');
          dispatch(dialogActions.atnCloseDialog());
        }
      } else {
        setChannelCreateLoading(false);
        toast.error('Channel name already exists');
      }
    }
  };

  const getName = (mem: any) => {
    return _.get(mem, 'displayName', '');
    // const chatProfileFirstName: any = _.get(mem, "id.firstName", "");
    // const chatProfileLastName: any = _.get(mem, "id.lastName", "");
    // return chatProfileFirstName
    //   ?.charAt(0)
    //   ?.toUpperCase()
    //   ?.concat(chatProfileFirstName?.slice(1)?.toLowerCase())
    //   ?.concat(
    //     " ",
    //     chatProfileLastName
    //       ?.charAt(0)
    //       ?.toUpperCase()
    //       ?.concat(chatProfileLastName?.slice(1)?.toLowerCase())
    //   );
  };
  const imageErrorMessage = errors['image']?.message;

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={2}>
        {/* should be removed */}
        <Box className="profileImageBox" style={{ position: 'relative' }}>
          <Controller
            name="image"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <>
                  <input
                    type="file"
                    onChange={(e) => {
                      if (_.get(e, 'target.files.[0]')) {
                        onChange(e.target.files);
                      }
                    }}
                    accept="image/jpeg,image/jpg,image/png"
                    id="image-upload"
                    className="d-none"
                  />
                  <label htmlFor="image-upload" className="cursor-pointer">
                    <Box className={!value && !_.head(value) ? '' : 'ProfileCameraBgOverlay'}>
                      <CameraAltOutlinedIcon
                        className="IfNoIcon"
                        sx={{ color: !value && !_.head(value) ? '#68717A' : '#FFFFFF' }}
                      />
                    </Box>

                    {value && value[0] && (
                      <img className="profileImage" src={URL.createObjectURL(value[0])} alt="Group image" />
                    )}
                  </label>
                </>
              );
            }}
          />
        </Box>

        <Box>
          <Typography variant="h6">Group image</Typography>
          <Typography className="miniText">
            jpg, png, svg or gif (max size {imgSize.limitText}, ideal dimensions 800x800 px)
          </Typography>
          {_.get(errors, 'image', '') ? <Typography color="red">{imageErrorMessage?.toString()}</Typography> : null}
        </Box>
      </Stack>
      <Grid container spacing={2} my={2}>
        {/* Group name field */}
        <Grid item xs={12}>
          <InputLabel>Group Name *</InputLabel>
          <Controller
            name="topic"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                fullWidth
                placeholder="Group Name"
                onChange={onChange}
                value={value || ''}
                id="chatGroupName"
              />
            )}
          />
          <ValidationError errors={errors} fieldName="topic" />
        </Grid>

        {/* Who should be part of this group */}
        {/* <Grid item xs={12}>
          <Typography variant="h5">
            Who should be part of this group ?
          </Typography>
          
          <RadioGroup row name="row-radio-buttons-group">
            <Grid container spacing={1}>
              <Grid item md={3}>
                <FormControlLabel value="mentors" control={<Radio />} label="All Mentors" />
              </Grid>
              <Grid item md={3}>
                <FormControlLabel value="mentee" control={<Radio />} label="All Mentee" />
              </Grid>
              <Grid item md={3}>
                <FormControlLabel value="All Mentors & Mentees" control={<Radio />} label="All Mentors & Mentees" />
              </Grid>
              <Grid item md={3}>
                <FormControlLabel value="Select individuals" control={<Radio />} label="Select individuals" />
              </Grid>
            </Grid>
          </RadioGroup>
        </Grid> */}

        {/* Add members field */}
        <Grid item xs={12}>
          <Controller
            name="participants"
            control={control}
            render={({ field: { onChange, value } }) => {
              if (!value) {
                onChange([]);
              }
              return (
                <Autocomplete
                  multiple
                  id="addMember"
                  options={desiredAcsUsers || []}
                  getOptionLabel={(option) => {
                    return getName(option);
                  }}
                  defaultValue={[]}
                  onChange={(e, value) => {
                    onChange(value);
                  }}
                  value={value || []}
                  isOptionEqualToValue={(option, value) =>
                    _.get(option, 'id.communicationUserId') === _.get(value, 'id.communicationUserId')
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={_.get(option, 'id.communicationUserId', '')}>
                        {getName(option)}
                      </li>
                    );
                  }}
                  renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Add Member" />}
                />
              );
            }}
          />
          <ValidationError errors={errors} fieldName="participants" />
        </Grid>

        {/* Group Description */}
        {/* <Grid item xs={12}>
          <InputLabel>
           Group Description *
          </InputLabel>
          <TextField
            multiline
            minRows={4}
            maxRows={10}
            placeholder="Write Description about group"
          />
        </Grid> */}
      </Grid>

      {/*  */}
      <DialogActions sx={{ p: 0 }}>
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          variant="contained"
          fullWidth
          color="primary"
          loading={channelCreateLoading}
          disabled={channelCreateLoading}
        >
          Save
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default AcsCreateChannelDialog;
