import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, TextField, IconButton, Typography } from '@mui/material';
import _ from 'lodash';
import ChatHome from './ChatHome';
import { API } from '../../../../api';
import { toast } from 'react-toastify';
import ChatbotTree from './ChatbotTree';
import { useDispatch } from 'react-redux';
import { Send } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from '../../../../redux/hooks';
import ChatAvatarComponent from '../chat/ChatAvatarComponent';
import TypingLoader from '../../../../components/TypingLoader';
import circleicon from '../../../../assets/images/circleicon.svg';
import { setCurrentConversationID } from '../../../../redux/slices/ai-chat/aiChatMessages';

type Props = {};

const AiChatMain = (props: Props) => {
  const dispatch = useDispatch();
  const conversationID = useAppSelector((state) => state.conversation.currentConversationID);
  const flag = useAppSelector((state) => state.conversation.flag);
  const userProfileData = useAppSelector((state) => state.userProfile.data);
  const { state } = useLocation();
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState('');
  const [loader, setLoader] = useState(false);
  const [conversationData, setConversationData] = useState([]);
  const [promptId, setPromptId] = useState();
  const [hasTree, setHasTree] = useState(false);
  const [allConvertionsData, setAllConvertionsData] = useState<any[]>([]);
  const messagesBoxRef = useRef<HTMLDivElement | null>(null);
  const disable = inputValue.length === 0 || loader;

  useEffect(() => {
    if (state) {
      setPromptId(state?.promptId);
      setHasTree(state?.has_tree);
      setConversationData(state?.conversationData);
      if (state.conversationID) {
        dispatch(setCurrentConversationID(state.conversationID));
      }
    }
  }, [state]);

  useEffect(() => {
    setConversationData([]);
  }, []);

  const handelOnChange = (event: any) => {
    setInputValue(event.target.value);
    setInputError('');
  };

  /** handle Key for msg inpute field */
  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter' && event.shiftKey) {
      /** Prevent the default behavior of the enter key */
      event.preventDefault();
      /** Add a new line to the input value */
      setInputValue((prevValue) => prevValue + '\n');
    } else if (event.key === 'Enter') {
      /** Submit the form if Enter is pressed without Shift */
      /** Added loading conditions loading time not msg send on click of enter */
      if (!loader) {
        event.preventDefault();
        if (!inputValue.trim()) {
        } else {
          handelOnClickBtn();
        }
      }
    }
  };

  const handleSendMessage = async () => {
    // Dismiss all existing toasts
    toast.dismiss();
    if (!inputValue.trim()) {
      setInputError('Please enter a message before sending.');
      // Show the new error toast
      toast.error('Please enter a message before sending.');
      return;
    }

    setLoader(true);

    try {
      const firstObj = {
        id: allConvertionsData.length + 1,
        sender: 'user',
        content: inputValue,
        timestamp: new Date().toISOString(),
        tree_response: false
      };

      setAllConvertionsData([...allConvertionsData, firstObj]);

      const data = {
        conversationID: conversationID,
        message: inputValue
      };

      const response = await API.aiSendMessage(data);

      if (response.status === 200) {
        const secondObj = {
          id: allConvertionsData.length + 2,
          sender: 'assistant',
          content: response.data.data,
          timestamp: new Date().toISOString(),
          tree_response: false
        };

        setAllConvertionsData([...allConvertionsData, firstObj, secondObj]);
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  const newConversationFromGeneral = async () => {
    if (!inputValue.trim()) {
      setInputError('Please enter a message before sending.');
    } else {
      const data = {
        message: inputValue
      };
      const response = await API.newConversationFromGeneral(data);
      if (response.status === 200) {
        dispatch(setCurrentConversationID(response.data.data.id));
        setInputValue('');
      }
    }
  };

  const getConversationById = async () => {
    setLoader(true);
    setInputValue('');
    const response = await API.getConversationById(conversationID);
    setConversationData([]);
    if (response.status === 200) {
      setAllConvertionsData(response.data.data);
      setLoader(false);
    }
  };

  const handelOnClickBtn = () => {
    setInputValue('');
    setInputError('');
    if (conversationID) {
      handleSendMessage();
    } else {
      newConversationFromGeneral();
    }
  };

  useEffect(() => {
    if (conversationID) {
      getConversationById();
    }
  }, [conversationID]);

  useEffect(() => {
    if (messagesBoxRef.current) {
      messagesBoxRef.current.scrollTop = messagesBoxRef.current.scrollHeight;
    }
  }, [allConvertionsData]);

  return (
    <Box position={'relative'} height={'calc(100% - 85px)'} display={'flex'} flexDirection={'column'}>
      <Box ref={messagesBoxRef} flexGrow={1} overflow={'auto'} px={2} pt={2}>
        {flag && <ChatHome />}
        {hasTree && conversationData && <ChatbotTree tree={conversationData} promptId={promptId} />}
        {allConvertionsData.length > 0 &&
          allConvertionsData.map((ele: any) => (
            <Box key={ele.id} mb={2} gap="8px" display="flex">
              <Box>
                {ele && userProfileData && (
                  <ChatAvatarComponent
                    variant="circle"
                    width="34px"
                    height="34px"
                    firstLetter={
                      ele.sender === 'user'
                        ? userProfileData?.personal_details?.firstName.slice(0, 1)
                        : 'Dosen'.slice(0, 1)
                    }
                    image={ele.sender === 'user' ? userProfileData?.personal_details?.headshot : circleicon}
                    border={ele.sender === 'user' ? 'none' : '1px solid #152536'}
                  />
                )}
              </Box>
              <Box>
                {ele?.tree_response === true && (
                  <Box>
                    <Button
                      variant="outlined"
                      disabled={true}
                      color="primary"
                      sx={{
                        marginBottom: '10px',
                        backgroundColor: '#6C757D',
                        color: 'white !important',
                        minWidth: '300px',
                        justifyContent: 'flex-start'
                      }}
                    >
                      {ele.content}
                    </Button>
                  </Box>
                )}
                {ele?.tree_response === false && (
                  <>
                    <Box display="flex" gap="8px" alignItems={'center'}>
                      <Typography variant="h5">
                        {ele && userProfileData && ele.sender === 'user'
                          ? userProfileData?.personal_details?.name
                          : 'Dosen'}
                      </Typography>
                      <Typography variant="overline" color="secondary.light">
                        {new Date(ele.timestamp).toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' })}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography key={ele.id} whiteSpace={'pre-wrap'}>
                        {ele.content}
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          ))}

        {/* 3 dots loader */}
        {loader && <TypingLoader color="#DF6438" />}
      </Box>
      <Box p={2}>
        <Box mb={'8px'}>
          {flag && (
            <Typography variant="h6" lineHeight={'normal'}>
              Ask Dosen...
            </Typography>
          )}

          {/* {inputError && (
            <Typography variant="caption" color="error" lineHeight={'normal'}>
              {inputError}
            </Typography>
          )} */}
        </Box>

        {/* Input field */}
        <Box position={'relative'}>
          <TextField
            placeholder="Start a chat with Dosen"
            id="askDosen-inpute"
            value={inputValue}
            onChange={handelOnChange}
            onKeyDown={handleKeyDown}
            // disabled={loader}
            maxRows={7}
            multiline
            inputProps={{
              spellCheck: true
            }}
            spellCheck={true}
            sx={{
              '& .MuiOutlinedInput-root': {
                minHeight: '40px',
                paddingRight: 0
                // background: `${loader ? '#EFF0F4' : '#FFFFFF'} !important`,
              },
              '& textarea': {
                paddingRight: '40px'
              }
            }}
          />

          {/* Send btn */}
          <IconButton
            onClick={!disable ? handelOnClickBtn : undefined}
            id="askDosen-SendBtn"
            disabled={disable}
            sx={{
              bottom: '3.5px',
              right: '8px',
              width: '32px',
              height: '32px',
              borderRadius: '8px',
              position: 'absolute',
              background: `${disable ? '#DEE2E6' : '#0071A9'} !important`
              // cursor: `${disable ? 'default' : 'pointer'} !important`,
            }}
          >
            <Send fontSize="small" sx={{ color: '#FFFFFF' }} />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AiChatMain;
