/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { Box, Divider, TextField, InputLabel, Typography, Autocomplete } from '@mui/material';
import _ from 'lodash';
import ChatChannels from './ChatChannels';
import ChatAvatarIcon from './ChatAvatarIcon';
// import ChatTypesTabs from './ChatTypesTabs';
import ROLES from '../../../../../utils/roles';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import MySkeleton from '../../../../../components/Skeleton';
import { getUserDetails } from '../../../../../utils/orgName';
import useProfileDetails from '../../../../../hooks/useProfileDetails';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { IChatUsers, fetchChatUsers } from '../../../../../redux/slices/getChatUsersSlice';
import { fetchAcsToken } from '../../../../../redux/slices/acs-token/acsTokenSlice';
import { selectedChatUserActions } from '../../../../../redux/slices/selectedChatUserSlice';
import { acsChannelActions } from '../../../../../redux/slices/acs-channels/acsChannels';
import {
  communityUsersActions,
  fetchCommunityMembers
} from '../../../../../redux/slices/acs-community-members/acsCommunityMembers';
import useResponsiveScreenSwitch from '../../../../../utils/useResponsiveScreenSwitch';
import { ChatLeftSideHeaderAvatar } from '../../../../../Loader/chat/ChatLoader';

export interface IChatSidebar {
  activeChat: string;
  loginUserProfileImage: string;
  loginUserFName: string;
  handleCreateGroup: () => void;
  handleCreateNewChat: () => void;
  chatType: string;
  setActiveChat: React.Dispatch<string>;
  filteredDirectMessages: IThread[];
  formattedAcsOrgUsers: IFormattedAcsOrgUser[];
  threadValue: string;
  commId: string;
  activeItemRef: React.MutableRefObject<any>;
  filterParticipantsFromDirectThreads: IChatThread[];
  groupMessages: IGroupMessage[];
  chatPopupClick: (event: any) => void;
  chatPopupClose: () => void;
  anchorEl: any;
  chatPopup: any;
  activeMessageId: any;
  loading?: boolean;
}

const ChatSideBar: React.FC<IChatSidebar> = ({
  activeChat,
  loginUserProfileImage,
  loginUserFName,
  handleCreateGroup,
  handleCreateNewChat,
  chatType,
  setActiveChat,
  filteredDirectMessages,
  formattedAcsOrgUsers,
  threadValue,
  commId,
  activeItemRef,
  filterParticipantsFromDirectThreads,
  groupMessages,
  chatPopupClick,
  chatPopupClose,
  anchorEl,
  chatPopup,
  activeMessageId,
  loading
}) => {
  console.log('sidebar');
  const chatUsersData = useAppSelector((state) => state.chatUsers);
  const navigate = useNavigate();
  const selectedChatUser = useAppSelector((state) => state.selectedChatUser.data);
  const dispatch = useAppDispatch();
  const [selectedOption, setSelectedOption] = useState<IChatUsers | null>(null);
  const queryClient = useQueryClient();
  const acsTokenLoading = useAppSelector((state) => state.acsToken.loading);
  const [searchTerm, setSearchTerm] = useState('');
  const role = localStorage.getItem('role') || '';
  const { admins } = useProfileDetails();
  const allowedAdmins = [ROLES.orgAdmin, ROLES.platAdmin];
  const [isLoading, setIsLoading] = useState(false);
  const [noOptionsText, setNoOptionsText] = useState<string | null>('Loading..');
  const loadingChatUsers = useAppSelector((state) => state.chatUsers.loading);
  const { forMobile } = useResponsiveScreenSwitch(); // Use the hook

  const handleChange = (event: React.SyntheticEvent, value: IChatUsers | null) => {
    localStorage.setItem('chatAccessed', JSON.stringify(true));
    setSelectedOption(value);
    if (value) {
      dispatch(fetchAcsToken({ communicationId: value?.communicationId || '', tag: '#admin' })).then(() => {
        dispatch(
          acsChannelActions.atnSaveChannels2({
            groupThreads: [],
            personalThreadsArr: []
          })
        );
        queryClient.fetchQuery({ queryKey: ['getAcsChatThreads'] });
      });
      dispatch(communityUsersActions.update(0));
      dispatch(
        fetchCommunityMembers({
          orgId: getUserDetails().orgId,
          location: getUserDetails().location
        })
      );
      dispatch(selectedChatUserActions.update(value));
      navigate('/app/chat', { replace: true });
    }
  };

  const debouncedFetchChatUsers = _.debounce(async (term: string) => {
    dispatch(fetchChatUsers(term));
    setIsLoading(false);
  }, 1000);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (searchTerm !== '') {
        await debouncedFetchChatUsers(searchTerm);
      }
    };

    fetchData();
    setIsLoading(false);
    // Cleanup function to cancel the debounce on component unmount
    return () => {
      debouncedFetchChatUsers.cancel();
    };
  }, [searchTerm]);

  useEffect(() => {
    // Introduce a delay before updating noOptionsText
    const timeoutId = setTimeout(() => {
      if (searchTerm.length > 3) {
        setNoOptionsText(!loadingChatUsers ? 'No members found' : 'Loading...');
      } else {
        setNoOptionsText('Loading...'); // Set to null when searchTerm is not more than three characters
      }
    }, 2000);

    // Cleanup the timeout on component unmount or when loadingChatUsers changes
    return () => clearTimeout(timeoutId);
  }, [loadingChatUsers]);

  useEffect(() => {
    setSelectedOption(selectedChatUser);
  }, [selectedChatUser]);

  const handleClose = () => {
    if (!selectedOption) {
      setSelectedOption(null);
      setSearchTerm('');
      dispatch(fetchChatUsers(searchTerm));
    }
  };

  /** For mobile we'll not show, for dasktop we will show for admin only */
  const accessUserChatShow = !forMobile && (allowedAdmins.includes(role) || admins.length > 0);

  /** Dispatch fetchChatUsers(searchTerm) when Autocomplete loses focus */
  const handleBlur = () => {
    if (selectedOption !== null) {
      dispatch(fetchChatUsers(searchTerm));
    }
  };

  return (
    <Box className="containerBoxLeft">
      <Box px={2} pt={2}>
        {/* Admin access user chat */}
        {accessUserChatShow &&
          (isLoading ? (
            <Box pb={2}>
              <MySkeleton width={150} className="mb-10" />
              <MySkeleton variant="rounded" height={37} />
            </Box>
          ) : (
            <Box pb={2}>
              <InputLabel>Access member chat</InputLabel>
              <Autocomplete
                disablePortal
                id="access-user-chat"
                options={chatUsersData.data.slice().sort((a, b) => a.name.localeCompare(b.name))}
                getOptionLabel={(option) => option.name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={selectedOption}
                onClose={handleClose}
                loading={loadingChatUsers}
                noOptionsText={noOptionsText}
                onInputChange={(event, newInputValue) => setSearchTerm(newInputValue)}
                renderInput={(params) => (
                  <TextField {...params} placeholder="None selected" disabled={acsTokenLoading} />
                )}
              />
            </Box>
          ))}

        <Box pb={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="h5"> {isLoading ? <MySkeleton width={70} /> : 'Chat'}</Typography>
        </Box>

        {/* Avatar */}
        <Box pb={2}>
          {isLoading ? (
            <ChatLeftSideHeaderAvatar />
          ) : (
            <ChatAvatarIcon
              activeChat={activeChat}
              chatPopupClick={chatPopupClick}
              chatPopupClose={chatPopupClose}
              chatPopup={chatPopup}
              handleCreateGroup={handleCreateGroup}
              anchorEl={anchorEl}
              handleCreateNewChat={handleCreateNewChat}
              loginUserFName={loginUserFName}
              loginUserProfileImage={loginUserProfileImage}
            />
          )}
        </Box>
      </Box>

      <Divider />
      {/* <ChatTypesTabs activeChat={activeChat} chatType={chatType} setActiveChat={setActiveChat} /> */}

      {/* Chat channels */}
      <ChatChannels
        activeChat={activeChat}
        activeItemRef={activeItemRef}
        commId={commId}
        loading={loading || isLoading} //loading
        filterParticipantsFromDirectThreads={filterParticipantsFromDirectThreads}
        filteredDirectMessages={filteredDirectMessages}
        formattedAcsOrgUsers={formattedAcsOrgUsers}
        groupMessages={groupMessages}
        threadValue={threadValue}
        activeMessageId={activeMessageId}
        accessUserChatShow={accessUserChatShow} // Access member chat.
      />
    </Box>
  );
};

export default ChatSideBar;
