/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Popover, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import _ from 'lodash';
import ChatAvatarComponent from './tabs/chat/ChatAvatarComponent';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useLogout } from '../../hooks';
import ROLES from '../../utils/roles';
import useProfileDetails from '../../hooks/useProfileDetails';
import { getUserDetails } from '../../utils/orgName';
// import SearchDosen from './SearchDosen';
import EditProfile from './components/EditProfile';
import DisabilityModal from './components/DisabilityModal';
import SocialHandlesModal from './components/SocialHandlesModal';
import HobbiesModal from './components/HobbiesModal';
import DigitalIntroModal from './components/DigitalIntroModal';
import EducationModal from './components/EducationModal';
import WorkHistoryModal from './components/WorkHistoryModal';
import { disabilityPopupActions } from '../../redux/slices/disability/disabilityPopupSlice';
import { popupActions } from '../../redux/slices/onboardingPopupsSlice';
import { useAuth0 } from '@auth0/auth0-react';
import { IRoleObj, fetchUserGroupList } from '../../redux/slices/groupRolesListSlice';
import GroupList from './tabs/home/GroupList';
import { updateSelectedId } from '../../redux/slices/selectedGroupSlice';
// import { API } from '../../api';
// import { toast } from 'react-toastify';
import { taskActions } from '../../redux/slices/goals-tasks/getTasksSlice';
import { fetchUserProfile } from '../../redux/slices/user-profile/userProfieSlice';
import { persistor } from '../../redux/store';
import useResponsiveScreenSwitch from '../../utils/useResponsiveScreenSwitch';
import Upvotes from './components/Upvotes';
import { useXpPoints } from './components/menteeXpPoints';
import { updateXpPoints } from '../../redux/slices/menteeXpPoints/menteeXpPointsSlice';
import { AGILE_ORG } from '../../utils/constants';
import { CheckListIcon, ProfileIcon, SignOutIcon } from '../../utils/Icons';
import useOrgSettings from '../../hooks/useOrgSettings';
import useCallDetails from '../../queries/useCallDetails';
import { CallRowData } from './tabs/calendar/Calendar';
import PostCallPopup from './tabs/chat/PostCallPopup';

const Header = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const [searchParams] = useSearchParams();
  const role = localStorage.getItem('role') || '';
  const name = useAppSelector((state) => state.orgDetails.data?.name) || '';
  const orgLoading = useAppSelector((state) => state.orgDetails.loading);
  const { headshot, firstName, mentors } = useProfileDetails();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const groupList = useAppSelector((state) => state.groupRoleList);
  const userProfileData = useAppSelector((state) => state.userProfile.data);
  const oid = useAppSelector((state) => state.orgDetails.data?.oid);
  const callsData = useCallDetails(1, 10);
  // const [xpPoints, setXpPoints] = useState<any>();
  const getXpPoints = useXpPoints();
  /** For responsive */
  const { forMobile } = useResponsiveScreenSwitch(); // Use the hook
  const url = window?.location?.hash;

  let orgName = '';

  if (url?.includes('organizations')) {
    orgName = 'Organizations';
  } else if (role === ROLES?.platAdmin) {
    orgName = name && !orgLoading ? name : searchParams?.get('org') || '';
  } else {
    orgName = name;
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const userData = useAppSelector((state) => state.userProfile.data);
  const userProfile = useAppSelector((state) => state.userProfile.data?.personal_details);
  const { updatedData: data } = useAppSelector((state) => state.userWorkHistory);
  const getAllEducationDetails: any = useAppSelector((state) => state.userEducation?.updatedData);
  const workHistoryData = data;
  const isAuth = localStorage.getItem('isAuthenticated');
  const isLoggedIn = isAuth ? JSON.parse(isAuth) : false;
  const [isPopupOn, setPopUpOn] = useState(false);
  const { ftue, upVote } = useProfileDetails();
  const selectedGroup = useAppSelector((state) => state.selectedGroup.data);
  const { doLogout } = useLogout();
  const { user } = useAuth0();
  const { groupLoc } = getUserDetails();
  const groupId = selectedGroup.id || '';
  const groupRole = selectedGroup.role || '';
  const xpPoints = useAppSelector((state) => state.menteeXpPoints.data);
  const { category, timezone, phoneNumber, lastName, lastseen, email } = useProfileDetails();
  const mandatoryFields = {
    category: true,
    timezone: true,
    phoneNumber: false,
    firstName: true,
    lastName: true,
    email: true
    // changed here for disability to be always false, just remove it completely here
  };
  const settings = useAppSelector((state) => ({
    ...state.userProfile.data?.settings,
    ...mandatoryFields
  })) || {
    gender: false,
    country: false,
    firstGenerationStudent: false,
    ethnicity: false,
    dob: false,
    disabilityType: false,
    workHistory: false,
    educationHistory: false,
    hobbiesInterests: false,
    ...mandatoryFields
  };
  const isActive = _.get(userProfile, 'isActive', false);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const dispatch: any = useAppDispatch();
  const updateSelectedObj = (selectedRole: IRoleObj) => {
    dispatch(updateSelectedId(selectedRole));
    localStorage.setItem('lastseen', JSON.stringify(selectedRole));
    dispatch(taskActions.setGroupId({ groupId: _.get(selectedRole, 'id', '') }));
  };
  const { userId } = getUserDetails();
  const callRows = callsData?.data;
  const userProfileDetails = useProfileDetails();

  const [currentMeetingIndex, setCurrentMeetingIndex] = useState(0);
  const [openPostCallPopup, setOpenPostCallPopup] = useState(true);

  let filteredCallRows: CallRowData[] = callRows.filter((callRow: CallRowData) => {
    const matchingParticipant = callRow.participantsUser.find((user) => user.mid === userId);
    return matchingParticipant?.isCallJoined === true && matchingParticipant?.voteFlag === false;
  });

  const openNextPopup = () => {
    console.log('===');
    if (currentMeetingIndex < filteredCallRows.length - 1) {
      setCurrentMeetingIndex(currentMeetingIndex + 1);
    } else {
      // when all the modals are done
      setOpenPostCallPopup(false);
      setCurrentMeetingIndex(0);
      filteredCallRows = [];
      callsData.refetch();
    }
  };

  const callWithTitle = (callRow: any) => {
    let reviewModalTitle = '';
    if (callRow?.chatType === 'Chats') {
      // p2p
      reviewModalTitle =
        userId === callRow?.callBookedWith?.id
          ? callRow?.callBookedBy?.displayName
          : callRow?.callBookedWith?.displayName;
    } else {
      // group
      reviewModalTitle = callRow?.callBookedWith?.name; // group name
    }
    return reviewModalTitle;
  };

  useEffect(() => {
    if (groupList.data && groupList.data.length > 0 && !lastseen) {
      const adminData = groupList.data.find((each: any) => each.role === 'admin');
      if (adminData) {
        updateSelectedObj(adminData);
      } else {
        const menteeIndex = groupList.data.findIndex((each: any) => each.role === 'mentee');
        const mentorIndex = groupList.data.findIndex((each: any) => each.role === 'mentor');
        const index = mentors.length > 0 ? mentorIndex : oid === AGILE_ORG ? mentorIndex : menteeIndex;
        if (index !== -1) {
          updateSelectedObj(groupList.data[index]);
        }
      }
    } else if (groupList.data) {
      if (lastseen && lastseen.role === 'admin') {
        const adminData = groupList.data?.find((each: any) => each.role === 'admin') || {
          id: '',
          name: '',
          role: '',
          active: true
        };
        updateSelectedObj(adminData);
      } else if (lastseen && lastseen.id !== '') {
        updateSelectedObj(lastseen);
      } else if (groupList.data.length > 0) {
        updateSelectedObj(groupList.data[0]);
      }
    }
  }, []);

  useEffect(() => {
    callsData.refetch();
  }, []);

  useEffect(() => {
    // getXpPoints('mentee');
    dispatch(updateXpPoints(xpPoints));
  }, []);

  // const getMenteesXpPoints = async (orgId: string, location: string, groupId: string) => {
  //   try {
  //     const response = await API.getMenteesPeersHomePage(orgId, location, groupId);
  //     if (response.status === 200 && response.statusText === 'OK') {
  //       setXpPoints(response?.data?.xpPoints);
  //       } else {
  //         setXpPoints('');
  //       }
  //   } catch (e) {
  //     // setLoading(false);
  //     // toast.error('Failed to XP points');
  //   }
  // };

  useEffect(() => {
    if (role !== ROLES.platAdmin && groupList.data && groupList.data.length > 0 && selectedGroup.role !== '') {
      const index = groupList.data.findIndex(
        (each) => each.id === selectedGroup.id && each.role === selectedGroup.role
      );
      if (index === -1) {
        localStorage.removeItem('lastseen');
        localStorage.removeItem('persist:root');
        persistor.purge();
        dispatch(fetchUserProfile());
        dispatch(fetchUserGroupList());
        window.location.replace('/');
      } else {
        if (!groupList.data[index].active) {
          localStorage.setItem('lastseen', JSON.stringify({ ...selectedGroup, active: false }));
          dispatch(updateSelectedId({ ...selectedGroup, active: false }));
          navigate('/app/deactivated');
        }
      }
    }
  }, [groupList]);

  const allowedRoutes = ['/app/programdetails', '/app/home', '/app/deactivated', '/app/profile/assessment'];

  // useEffect(() => {
  //   if (groupRole && groupRole !== 'mentee') {
  //     setXpPoints('');
  //     return;
  //   }
  //   getMenteesXpPoints(orgId, groupLoc, groupId);
  // }, [groupId, groupRole]);

  const location = useLocation();

  useEffect(() => {
    // because firstGenStudent and firstGenerationStudent are different
    const role = localStorage.getItem('role') || '';
    if (role !== ROLES.platAdmin && userData && !ftue && isLoggedIn && isAuthenticated) {
      if (!isActive && userData) {
        setPopUpOn(true);
      }
      //dispatch(popupActions.updatePopups({ profile: { open: false, disable: false } }));
      if (
        !isActive &&
        ((!userProfile?.gender && settings.gender) ||
          (!userProfile?.firstGenStudent && settings.firstGenerationStudent) ||
          (!userProfile?.country && settings.country) ||
          (!userProfile?.dob && settings.dob) ||
          (!userProfile?.ethnicity && settings.ethnicity))
        // (!userProfile?.category && settings.category) ||
        // (!userProfile?.timezone && settings.timezone) ||
        // (!userProfile?.phoneNumber && settings.phoneNumber) ||
        // (!userProfile?.firstName && settings.firstName) ||
        // (!userProfile?.lastName && settings.lastName) ||
        // (!userProfile?.email && settings.email)
      ) {
        dispatch(popupActions.updatePopups({ profile: { open: isPopupOn, disable: isPopupOn } }));
      }
      // commented this to not display disability field for now
      // also disbaled this after edit profile form, please change there also, important
      else if (!isActive && settings.hobbiesInterests && userProfile?.hobbies.length === 0) {
        dispatch(popupActions.updatePopups({ hobbies: isPopupOn, hobbiesDisable: isPopupOn }));
      } else if (
        !isActive &&
        settings.educationHistory &&
        userData?.educationHistory.length === 0 &&
        getAllEducationDetails.length === 0
      ) {
        dispatch(
          popupActions.updatePopups({
            education: { open: isPopupOn, educationPopUpDisable: isPopupOn, action: 'Add', item: {} }
          })
        );
      } else if (
        !isActive &&
        settings.workHistory &&
        userData?.workHistory.length === 0 &&
        workHistoryData.length === 0
      ) {
        dispatch(
          popupActions.updatePopups({
            workHistory: { open: isPopupOn, workHistoryDisable: isPopupOn, action: 'Add', item: {} }
          })
        );
      } else if (!isActive && settings.disabilityType && userProfile?.disability.length === 0) {
        dispatch(
          disabilityPopupActions.handleDisabilityPopup({
            open: isPopupOn,
            disable: isPopupOn
          })
        );
      }
    }
  }, [isActive, userData, isPopupOn]);
  const selectedRole = useAppSelector((state) => state.selectedGroup.data.role);
  const orgSettings = useOrgSettings();

  return (
    <>
      <Box className="topHeader">
        <Box
          gap={'8px'}
          display="flex"
          alignItems={'center'}
          justifyContent={'space-between'}
          width={forMobile ? 'calc(100% - 42px)' : 'calc(100% - 48px)'}
        >
          <Typography
            noWrap
            lineHeight={'normal'}
            variant={forMobile ? 'h3' : 'h1'}
            color={forMobile ? 'primary.dark' : 'primary'}
          >
            {orgName}
          </Typography>

          {/* Dosen Search */}
          {/* <SearchDosen/> */}

          <Box gap={'8px'} display="flex" alignItems={'center'}>
            {/* Role select dropdown not for mobile */}
            {!forMobile
              ? allowedRoutes.some((route) => location.pathname.includes(route)) &&
                groupList.data &&
                groupList.data.length > 1 && <GroupList />
              : null}

            {/* Upvotes & xpPointbox */}
            {(groupRole === 'mentee' && xpPoints) || (groupRole !== 'mentee' && upVote) ? (
              <Upvotes
                toolTip="true"
                printArea="header"
                usertype={groupRole}
                likes={groupRole === 'mentee' ? xpPoints : upVote}
                icon={groupRole === 'mentee' ? 'xpPoint' : ''}
                width={forMobile ? '100%' : '65px'}
                maxWidth={forMobile ? '100px' : '65px'}
              />
            ) : (
              <Box width={'65px'} />
            )}
          </Box>
        </Box>

        <Box display="flex" alignItems={'center'} gap={'8px'}>
          {/* Profile photo */}
          <Box onClick={(e) => handleClick(e)}>
            <ChatAvatarComponent
              image={headshot}
              firstLetter={firstName?.slice(0, 1)}
              width={forMobile ? '34px' : '40px'}
              height={forMobile ? '34px' : '40px'}
              cursor="pointer"
              type="noStatus"
            />
          </Box>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            {/*  Role select dropdown for mobile */}
            {forMobile &&
              (location.pathname.includes('/app/programdetails') || location.pathname.includes('/app/home')) &&
              groupList.data &&
              groupList.data.length > 1 && <GroupList />}

            {/* Profile */}
            {(orgSettings || role === ROLES.platAdmin) && (
              <Box
                className="PopoverListStyle"
                id="headerToProfile"
                onClick={() => {
                  navigate(role !== ROLES.platAdmin ? '/app/profile' : '/app/profile/security');
                  setAnchorEl(null);
                }}
              >
                <ProfileIcon height={24} />
                <Typography color="primary">Profile</Typography>
              </Box>
            )}

            {/* Password & Security */}
            {/* <Box
              className="PopoverListStyle"
              onClick={() => {
                navigate('/app/profile/security');
                setAnchorEl(null);
              }}
            >
              <CheckListIcon height={24} />
              <Typography color="primary">Password & Security</Typography>
            </Box> */}

            {/* Assessment Questions */}
            {mentors.length > 0 && selectedRole === 'mentor' && (
              <Box
                className="PopoverListStyle"
                id="headerToAssessment"
                onClick={() => {
                  navigate('/app/profile/assessment');
                  setAnchorEl(null);
                }}
              >
                <CheckListIcon height={24} />
                <Typography color="primary">Assessment Questions</Typography>
              </Box>
            )}

            {/* Logout */}
            <Box
              className="PopoverListStyle"
              id="headerToLogout"
              onClick={() => {
                doLogout();
                setAnchorEl(null);
              }}
            >
              <SignOutIcon height={24} />
              <Typography color="primary">Logout</Typography>
            </Box>
          </Popover>
        </Box>
      </Box>
      {/* Pages */}
      {role && role !== ROLES.platAdmin && (
        <>
          <EditProfile />
          <DisabilityModal />
          <SocialHandlesModal />
          <HobbiesModal />
          <DigitalIntroModal />
          <EducationModal />
          <WorkHistoryModal />
        </>
      )}
      {role !== ROLES.platAdmin && filteredCallRows.length > 0 && (
        <PostCallPopup
          key={currentMeetingIndex}
          reviewParticipant={filteredCallRows[currentMeetingIndex].participantsUser}
          openPostCallPopup={openPostCallPopup}
          handleClosePostCallPopup={openNextPopup} // Move to the next meeting after closing the popup
          meetingId={filteredCallRows[currentMeetingIndex].meetingId}
          title={`Please review your call with\n${callWithTitle(filteredCallRows[currentMeetingIndex])}`}
          name={callWithTitle(filteredCallRows[currentMeetingIndex])}
        />
      )}
    </>
  );
};

export default Header;
