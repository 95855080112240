/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable radix */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  Dialog,
  Button,
  Checkbox,
  IconButton,
  Typography,
  DialogContent,
  DialogActions,
  FormControlLabel,
  createFilterOptions
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { API } from '../../../api';
import { getUserDetails } from '../../../utils/orgName';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { fetchUserWorkHistory, userWorkHistoryActions } from '../../../redux/slices/user-work/userWorkHistorySlice';
import _, { isEmpty } from 'lodash';
import { popupActions } from '../../../redux/slices/onboardingPopupsSlice';
import { userActions } from '../../../redux/slices/user-profile/userProfieSlice';
import useProfileDetails from '../../../hooks/useProfileDetails';
import { popupCountActions } from '../../../redux/slices/poupsCountsSlice';
import WorkAndEduDesign from './WorkAndEduDesign';
import CommonModalHeader from '../../../components/DialogUi/CommonModalHeader';
import WorkHistoryDailogBox from './WorkHistoryDailogBox';
import BriefcaseIcon from '../../../assets/images/briefcase.svg';
import IfNoEduWork from './IfNoEduWork';
import { disabilityPopupActions } from '../../../redux/slices/disability/disabilityPopupSlice';
import { companyActions } from '../../../redux/slices/companySlice';

export interface IWorkHistory {
  company_name: string;
  roleType: string[];
  title: string;
  start_date: string | null;
  end_date: string | null;
  industry: string;
  currentlyWorking: boolean;
}
export interface IWorkHistoryError {
  company_name?: string;
  roleType?: string;
  title?: string;
  start_date?: string;
  end_date?: string | null;
  industry?: string;
  currentlyWorking?: boolean;
}

export interface endDateError {
  end_date?: string | null;
}

export interface startDateError {
  start_date?: string | null;
}
interface FilmOptionType {
  inputValue?: string;
  value?: string;
  type?: string;
}

const initialValues: IWorkHistory = {
  company_name: '',
  roleType: [],
  title: '',
  start_date: '',
  end_date: '',
  industry: '',
  currentlyWorking: true
};

interface IWorkHistoryState {
  action: string;
  activeId: string;
  roleKeyword: string;
}

const initialWorkHistoryState: IWorkHistoryState = {
  action: '',
  activeId: '',
  roleKeyword: ''
};
const filter = createFilterOptions<FilmOptionType>();

const WorkHistoryForm = () => {
  const dispatch = useAppDispatch();
  const { updatedData: data } = useAppSelector((state) => state.userWorkHistory);
  const { userId, location, orgId } = getUserDetails();
  const { open, action, item, workHistoryDisable } = useAppSelector((state) => state.onboardingPopups.data.workHistory);
  const [ftueLoading, setFtueLoading] = useState(false);
  const { user } = useAuth0();
  const { mentors } = useProfileDetails();

  const [workHistoryState, setWorkHistoryState] = useState<IWorkHistoryState>(initialWorkHistoryState);
  const [openWorkHistory, setOpenRoleWorkHistory] = useState(false);
  const [openDelete, setDelete] = useState(false);
  const [submitLoader, setSubmitLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [industryKeyword, setIndustryKeyword] = useState<string>('');
  const [companyKeyword, setCompanyKeyword] = useState<string>('');
  const { ftue } = useProfileDetails();
  const [startDateError, setStartDateError] = useState<startDateError>({});
  const [values, setValues] = useState<IWorkHistory>(initialValues);
  const [iWorkHistoryError, setIWorkHistoryError] = useState<IWorkHistoryError>({});
  const [endDateError, setEndDateError] = useState<endDateError>({});
  const [dataInAscendingDate, setDataInAscendingDate] = useState<IWorkHistory[]>([initialValues]);
  const [allCompanyList, setAllCompanyList] = useState<any[]>([]);
  const [allRoleList, setAllRoleList] = useState<any[]>([]);
  const [industryUniqueList, setIndustryUniqueList] = useState<any[]>([]);
  const flag = localStorage.getItem('workFlag');
  const workFlag = flag ? JSON.parse(flag) : false;
  const [checked, setChecked] = useState(workFlag);
  const getWorkHistoryDetails = useAppSelector((state) => state.userWorkHistory?.data);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [companyInput, setCompanyInput] = useState('');
  const [filteredCompanies, setFilteredCompanies] = useState<any>([]);
  const [openCompanyAutocomplete, setOpenCompanyAutocomplete] = useState(false);
  const [openRoleAutocomplete, setOpenRoleAutocomplete] = useState(false);
  const count = useAppSelector((state) => state.popupsCount.count);
  const userData = useAppSelector((state) => state.userProfile.data);
  const disability = useAppSelector((state) => state.userProfile.data?.personal_details?.disability) || [];
  const optionalFields = _.get(userData, 'settings', {
    gender: false,
    ethnicity: false,
    firstGenerationStudent: false,
    disabilityType: false,
    dob: false,
    country: false,
    category: false,
    timezone: false,
    workHistory: false,
    educationHistory: false,
    hobbiesInterests: false
  });
  const handleNoWorkHistory = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    if (checked) {
      setValues(initialValues);
      setWorkHistoryState({ ...workHistoryState, roleKeyword: '' });
      setCompanyKeyword('');
      setValues({
        ...values,
        [name]: '',
        company_name: '',
        title: '',
        roleType: [],
        start_date: '',
        end_date: '',
        currentlyWorking: false
      });
      setOpenRoleWorkHistory(false);
      setIWorkHistoryError({});
      setStartDateError({
        ...startDateError,
        start_date: undefined
      });
      setEndDateError({
        ...endDateError,
        end_date: undefined
      });
    }
    localStorage.removeItem('work');
    localStorage.setItem('workFlag', JSON.stringify(checked));
    setChecked(checked);
  };

  const allRoleValues = _.map(allRoleList, 'value');

  useEffect(() => {
    const sortedData = _.sortBy(data, (each) => _.parseInt(each.start_date));
    _.isArray(data) && !_.isEmpty(data) ? setDataInAscendingDate(sortedData) : setDataInAscendingDate([]);
  }, [data]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchAllCompanyList();
    }, 100);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [companyKeyword]);

  useEffect(() => {
    fetchAllIndustryList();
  }, []);

  useEffect(() => {
    if (action === 'Add') {
      const ind =
        _.isArray(allCompanyList) && !_.isEmpty(allCompanyList)
          ? allCompanyList?.find((c) => c.value === values?.company_name)
          : [];
      const data = localStorage.getItem('work');
      if (!data) {
        setValues({ ...values, industry: ind?.industry || '' });
      }
      setIWorkHistoryError({
        ...iWorkHistoryError,
        industry: undefined
      });
    }
  }, [companyKeyword]);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      fetchAllRoleList();
    }, 100);

    return () => {
      clearTimeout(debounceTimer);
    };
  }, [workHistoryState.roleKeyword]);

  const handleCloseWorkHistory = () => {
    setValues(initialValues);
    setWorkHistoryState({ ...workHistoryState, roleKeyword: '' });
    setCompanyKeyword('');
    setOpenRoleWorkHistory(false);
    setIWorkHistoryError({});
    setStartDateError({
      ...startDateError,
      start_date: undefined
    });
    setEndDateError({
      ...endDateError,
      end_date: undefined
    });
    if (!workHistoryDisable) {
      dispatch(
        popupActions.updatePopups({ workHistory: { open: false, workHistoryDisable: false, action: 'Add', item } })
      );
    }
  };

  const handleDelete = async () => {
    setDeleteLoader(true);
    try {
      const response = await API.deleteWorkHistory({
        userId,
        id: item._id,
        location
      });
      if (response?.status === 200 && response?.statusText === 'OK') {
        setDeleteLoader(false);
        if (!ftue) {
          toast.success('Work history deleted successfully');
        }

        dispatch(userWorkHistoryActions.deleteWorkHistory(item));
      }
      setDelete(false);
      handleCloseWorkHistory();
    } catch (e) {
      toast.error(_.get(e, 'response.data.message', 'Failed to delete work history'));
      setDeleteLoader(false);
    }
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const regex = /^[0-9\b]+$/;

    if ((name === 'start_date' || name === 'end_date') && value !== '' && !regex.test(value)) {
      setIWorkHistoryError({
        ...iWorkHistoryError,
        [name]: 'Please enter numbers only'
      });
      setValues({ ...values, [name]: '' });
    } else if (name === 'title' && (value === '' || value.trim() === '')) {
      setIWorkHistoryError({
        ...iWorkHistoryError,
        [name]: 'Please enter title'
      });
      setValues({ ...values, [name]: '' });
    } else {
      setValues({ ...values, [name]: value });
      setIWorkHistoryError({
        ...iWorkHistoryError,
        [name]: undefined
      });
    }
  };

  const handleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setValues({ ...values, [name]: checked, end_date: '' });
  };

  const handleValidation = () => {
    const { company_name, industry, start_date, end_date, title, roleType } = values;
    const newErrors: IWorkHistoryError = {};
    const endDateError: endDateError = {};
    const startDateError: startDateError = {};

    if (!company_name || company_name.trim() === '') {
      newErrors.company_name = 'Please enter company name';
    }
    if (!industry || industry.trim() === '') {
      newErrors.industry = 'Please enter industry';
    }
    if (!title || title.trim() === '') {
      newErrors.title = 'Please enter title';
    }
    /** Max limet Responsibilities */
    const maxResponsibilities = 5;
    if (!Array.isArray(roleType) || roleType.length === 0) {
      newErrors.roleType = 'Role type cannot be empty';
    } else if (roleType.length > maxResponsibilities) {
      newErrors.roleType = `Maximum ${maxResponsibilities} role types allowed`;
    }
    if (!start_date) {
      newErrors.start_date = 'Please Enter Start Year';
    }
    if (Number(start_date) < 1900 || Number(start_date) > Number(new Date().getFullYear())) {
      // const errorMessage = `Must be between 1900 and ${new Date().getFullYear()}`;
      const errorMessage = 'Start year must be in the past';

      startDateError.start_date = errorMessage;
      newErrors.start_date = errorMessage;
      setValues({ ...values, start_date: '' });
      setStartDateError({
        ...startDateError,
        start_date: startDateError.start_date
      });
    } else {
      setValues({ ...values, start_date: start_date });
      setStartDateError({
        ...startDateError,
        start_date: undefined
      });
    }
    if (!values.currentlyWorking && start_date && !end_date) {
      newErrors.end_date = 'Please Enter End Year';
    }
    if (
      !values.currentlyWorking &&
      start_date &&
      (Number(end_date) < Number(start_date) || Number(end_date) > Number(new Date().getFullYear()))
    ) {
      // const errorMessage = `Must be between ${start_date} and ${new Date().getFullYear()}`;
      const errorMessage = 'Start year must be in the past';

      endDateError.end_date = errorMessage;
      newErrors.end_date = errorMessage;
      setValues({ ...values, end_date: '' });
      setEndDateError({
        ...endDateError,
        end_date: endDateError.end_date
      });
    }
    return newErrors;
  };

  useEffect(() => {
    return () => {
      handleCloseWorkHistory();
    };
  }, []);

  const deleteAllWorkHistory = () => {
    if (data?.length !== 0 && checked) {
      try {
        const response = API.deleteAllEducationAndWorkHistory({
          userId,
          deleteType: 'workHistory'
        });
        dispatch(userWorkHistoryActions.deleteAllWorkHistory());
      } catch (error) {
        console.log('User work history', error);
      }
    }
  };

  const updateFtue = async () => {
    setFtueLoading(true);
    try {
      const response = await API.updateUserFtue({ ftue: false, userId });
      const data = localStorage.getItem('education');
      localStorage.removeItem('workFlag');
      localStorage.removeItem('edFlag');
      localStorage.removeItem('topics');
      let response2 = 200;
      if (data) {
        const formData = JSON.parse(data);
        const response = await API.addEducationAndSkills({ location, userId, formData });
        response2 = response.status;
      }
      if (response.status === 200 && response2 === 200 && response.statusText === 'OK') {
        dispatch(userActions.userFtueUpdate(0));
        setFtueLoading(false);
      }
      deleteAllWorkHistory();
    } catch (e) {
      toast.error(_.get(e, 'response.data.message', `Couldn't update profile details`));
      setFtueLoading(false);
    }
  };

  const totalSteps: any = localStorage.getItem('totalLength');

  const tasksData = useAppSelector((state) => state.mentorTasks);
  const { settings } = useProfileDetails();

  const updatePopups = () => {
    if (settings.disabilityType !== undefined) {
      dispatch(popupCountActions.increment(0));
      return;
    }
    if (mentors.length > 0) {
      if (tasksData.error) {
        toast.error(tasksData.errorText);
      } else if (tasksData.data.data.length === 0) {
        dispatch(companyActions.update(true));

        updateFtue();
      } else {
        dispatch(popupCountActions.increment(0));
        deleteAllWorkHistory();
      }
    } else {
      dispatch(companyActions.update(true));

      updateFtue();
    }
  };

  const handleSubmit = async () => {
    const handleValidationObject = handleValidation();
    if (Object.keys(handleValidationObject)?.length > 0) {
      setIWorkHistoryError(handleValidationObject);
    } else {
      // if (mentors.length > 0) {
      //   if (tasksData.error) {
      //     toast.error(tasksData.errorText);
      //     return;
      //   } else if (tasksData.data.data.length > 0) {
      //     localStorage.setItem('work', JSON.stringify(values));
      //     updatePopups();
      //     return;
      //   }
      // }

      setSubmitLoader(true);
      let { company_name, industry, end_date, title } = values;

      if (!values.currentlyWorking) {
        setValues({ ...values, currentlyWorking: false });
      }

      if (values.currentlyWorking) {
        end_date = null;
      }
      localStorage.setItem('work', JSON.stringify(values));
      if (action === 'Add') {
        try {
          const formData = {
            ...values,
            company_name: company_name.trim(),
            industry: industry.trim(),
            end_date,
            title: title.trim()
          };
          const response = await API.addWorkHistory({
            userId,
            location,
            formData
          });
          if (response.status === 200 && response.statusText) {
            // if (ftue) {
            //   updatePopups();
            // }
            setSubmitLoader(false);
            if (response.data.response) {
              dispatch(userWorkHistoryActions.addWorkHistory(response.data.response));
            }
            setIsDialogOpen(false);
            localStorage.removeItem('work');
            if (!ftue) {
              dispatch(popupActions.updatePopups({ workHistory: { open: false, workHistoryDisable: false } }));
              toast.success('Work history added successfully');
            }
            if (!ftue && optionalFields.disabilityType && disability.length === 0) {
              dispatch(
                disabilityPopupActions.handleDisabilityPopup({
                  open: true,
                  disable: true
                })
              );
            }
            //toast.success('Work history added successfully');
          }
          if (!ftue) {
            handleCloseWorkHistory();
          }
        } catch (e) {
          toast.error(_.get(e, 'response.data.message', 'Failed to add work history'));
          setSubmitLoader(false);
        }
      }
      if (action === 'Edit') {
        const values2: any = values;
        const withoutId: any = {};
        for (let i in values2) {
          if (i !== '_id') {
            withoutId[i] = values2[i];
          }
        }
        try {
          const formData = { ...withoutId, end_date };
          const response = await API.updateWorkHistory({
            userId,
            location,
            formData,
            id: item._id
          });
          if (response.status === 200 && response.statusText) {
            setSubmitLoader(false);
            setIsDialogOpen(false);
            localStorage.removeItem('work');
            dispatch(userWorkHistoryActions.editWorkHistory(values));
            toast.success('Work history updated successfully');
          }
          handleCloseWorkHistory();
        } catch (e) {
          toast.error(_.get(e, 'response.data.message', 'Failed to edit work history'));
          setSubmitLoader(false);
        }
      }
    }
  };

  const fetchAllCompanyList = () => {
    API.getTagsListnew(orgId, location, 'Company', companyKeyword).then((res) => {
      if (res.data?.tagListResponse) {
        setAllCompanyList(res.data?.tagListResponse);
      }
    });
  };

  const fetchAllIndustryList = useCallback(() => {
    API.getTagsListnew(orgId, location, 'industry', '', true).then((res) => {
      if (res?.data?.tagListResponse) {
        setIndustryUniqueList(res?.data?.tagListResponse);
      }
    });
  }, [industryKeyword]);

  const fetchAllRoleList = () => {
    API.getTagsListnew(orgId, location, 'Function/Role', workHistoryState.roleKeyword)
      .then((res) => {
        if (res.data?.tagListResponse) {
          setAllRoleList(res.data?.tagListResponse);
        }
      })
      .catch((e) => {
        setAllCompanyList([]);
        setIndustryUniqueList([]);
      });
  };

  const handleRoleType = (event: React.SyntheticEvent<Element, Event>, value: string[]) => {
    setValues({ ...values, roleType: value || [] });
    setIWorkHistoryError({ ...iWorkHistoryError, roleType: undefined });
  };

  // useEffect(() => {
  //   if (data?.length < 1) {
  //     // alert('dagnk')
  //     dispatch(popupActions.updatePopups({ workHistory: { item: {}, open: false, action: 'Add' } }));
  //     console.log('val', { item, values });
  //     setValues(initialValues);
  //   }
  // }, [data]);

  useEffect(() => {
    if (open && action === 'Edit') {
      setValues(item);
    } else {
      const data = localStorage.getItem('work');
      const values = ftue && data ? JSON.parse(data) : initialValues;
      setValues({
        company_name: '',
        roleType: [],
        title: '',
        start_date: '',
        end_date: '',
        industry: '',
        currentlyWorking: true
      });
    }
  }, [open, action]);

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  const handleClickOpenWorkDetailed = () => {
    setIsDialogOpen(true);
    dispatch(popupActions.updatePopups({ workHistory: { open: false, action: 'Add', item: {} } }));
    setValues({
      company_name: '',
      roleType: [],
      title: '',
      start_date: '',
      end_date: '',
      industry: '',
      currentlyWorking: true
    });
  };

  const handleEditWorkHistory = (item: IWorkHistory) => {
    dispatch(popupActions.updatePopups({ workHistory: { open: true, action: 'Edit', item } }));
  };

  const handleInputChange = (event: any, newInputValue: any) => {
    setCompanyInput(newInputValue);
    filterCompanyList(newInputValue);
    setCompanyKeyword(newInputValue);
  };

  const filterCompanyList = (inputValue: any) => {
    const filtered = allCompanyList?.filter((option) =>
      option?.value?.toLowerCase()?.includes(inputValue?.toLowerCase())
    );
    setFilteredCompanies(filtered);
  };

  return (
    <>
      {/* This plus icon for Work history */}
      {ftue && data?.length !== 0 && (
        <Box display={'inline-block'} pr={2} textAlign={'right'} position={'relative'} top={'-28px'}>
          <IconButton
            size="small"
            onClick={() => {
              handleClickOpenWorkDetailed();
            }}
          >
            <AddCircleOutlineIcon className="cursor-pointer" color="info" fontSize="small" />
          </IconButton>
        </Box>
      )}
      {/* <Typography px={2} pt={2}>Add your work history</Typography> */}

      <DialogContent>
        {checked ? (
          <IfNoEduWork WorkHistory />
        ) : (
          <>
            {ftue ? (
              <>
                <Dialog open={isDialogOpen} onClose={handleClose}>
                  <CommonModalHeader handleCloseModal={handleClose} title={'Add your Work history'} />
                  <DialogContent>
                    <WorkHistoryDailogBox
                      values={values}
                      openCompanyAutocomplete={openCompanyAutocomplete}
                      setOpenCompanyAutocomplete={setOpenCompanyAutocomplete}
                      filteredCompanies={filteredCompanies}
                      setOpenRoleAutocomplete={setOpenRoleAutocomplete}
                      companyInput={companyInput}
                      openRoleAutocomplete={openRoleAutocomplete}
                      setCompanyKeyword={setCompanyKeyword}
                      setValues={setValues}
                      handleInputChange={handleInputChange}
                      setIWorkHistoryError={setIWorkHistoryError}
                      iWorkHistoryError={iWorkHistoryError}
                      allCompanyList={allCompanyList}
                      setIndustryKeyword={setIndustryKeyword}
                      industryUniqueList={industryUniqueList}
                      handleChange={handleChange}
                      allRoleValues={allRoleValues}
                      setWorkHistoryState={setWorkHistoryState}
                      workHistoryState={workHistoryState}
                      handleRoleType={handleRoleType}
                      handleCheckbox={handleCheckbox}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} variant="outlined" fullWidth>
                      Cancel
                    </Button>
                    <LoadingButton onClick={() => handleSubmit()} variant="contained" loading={submitLoader} fullWidth>
                      Save
                    </LoadingButton>
                  </DialogActions>
                </Dialog>
                {data?.length !== 0 ? (
                  <>
                    <WorkAndEduDesign
                      data={data}
                      itemType="work"
                      wherePrint="onBoarding"
                      onEdit={(item) => {
                        if ('company_name' in item && 'roleType' in item) {
                          setWorkHistoryState({ ...workHistoryState, action: 'Edit', activeId: item.company_name });
                          setValues(item as IWorkHistory);
                          handleEditWorkHistory(item as IWorkHistory);
                        }
                      }}
                      remove={setDelete}
                    />
                  </>
                ) : (
                  <WorkHistoryDailogBox
                    values={values}
                    openCompanyAutocomplete={openCompanyAutocomplete}
                    setOpenCompanyAutocomplete={setOpenCompanyAutocomplete}
                    filteredCompanies={filteredCompanies}
                    companyInput={companyInput}
                    setOpenRoleAutocomplete={setOpenRoleAutocomplete}
                    openRoleAutocomplete={openRoleAutocomplete}
                    setCompanyKeyword={setCompanyKeyword}
                    setValues={setValues}
                    handleInputChange={handleInputChange}
                    setIWorkHistoryError={setIWorkHistoryError}
                    iWorkHistoryError={iWorkHistoryError}
                    allCompanyList={allCompanyList}
                    setIndustryKeyword={setIndustryKeyword}
                    industryUniqueList={industryUniqueList}
                    handleChange={handleChange}
                    allRoleValues={allRoleValues}
                    setWorkHistoryState={setWorkHistoryState}
                    workHistoryState={workHistoryState}
                    handleRoleType={handleRoleType}
                    handleCheckbox={handleCheckbox}
                  />
                )}
              </>
            ) : (
              <WorkHistoryDailogBox
                values={values}
                openCompanyAutocomplete={openCompanyAutocomplete}
                setOpenCompanyAutocomplete={setOpenCompanyAutocomplete}
                filteredCompanies={filteredCompanies}
                companyInput={companyInput}
                openRoleAutocomplete={openRoleAutocomplete}
                setOpenRoleAutocomplete={setOpenRoleAutocomplete}
                setCompanyKeyword={setCompanyKeyword}
                setValues={setValues}
                handleInputChange={handleInputChange}
                setIWorkHistoryError={setIWorkHistoryError}
                iWorkHistoryError={iWorkHistoryError}
                allCompanyList={allCompanyList}
                setIndustryKeyword={setIndustryKeyword}
                industryUniqueList={industryUniqueList}
                handleChange={handleChange}
                allRoleValues={allRoleValues}
                setWorkHistoryState={setWorkHistoryState}
                workHistoryState={workHistoryState}
                handleRoleType={handleRoleType}
                handleCheckbox={handleCheckbox}
              />
            )}
          </>
        )}
      </DialogContent>
      {/* No work history Checkbox */}
      {!settings.workHistory && count > 0 && (
        <Box pl={2} pb={1}>
          <Box>
            <FormControlLabel
              control={<Checkbox checked={checked} name="currentlyWorking" onChange={handleNoWorkHistory} />}
              label="No work history"
            />
          </Box>
          {/* Note */}
          {/* <Typography variant="body1" mt={1}>
            Note: You can add more experience from your profile section after sign up
          </Typography> */}
        </Box>
      )}
      <DialogActions>
        {(ftue || action === 'Edit') && (
          <Button
            variant="outlined"
            color="secondary"
            fullWidth
            disabled={ftueLoading}
            onClick={() => {
              if (ftue) {
                localStorage.setItem('work', JSON.stringify(values));
                dispatch(popupCountActions.decrement(0));
              } else {
                setDelete(true);
              }
            }}
          >
            {ftue ? 'Back' : 'Remove'}
          </Button>
        )}

        {ftue ? (
          <LoadingButton
            color="primary"
            variant="contained"
            fullWidth
            //   (ftue ? ftueLoading : submitLoader)

            onClick={() => {
              ftue && (data?.length !== 0 || checked) ? updatePopups() : handleSubmit();
            }}
            loading={submitLoader || ftueLoading}
          >
            {data.length !== 0 || checked
              ? (mentors.length > 0 && tasksData.data.data.length > 0) || settings.disabilityType !== undefined
                ? 'Next'
                : 'Finish'
              : 'Add'}

            {/* {mentors.length > 0 || settings.disabilityType ? }
            {mentors?.length > 0
              ? tasksData.data.data.length > 0 || settings.disabilityType
                ? data?.length !== 0 || checked
                  ? 'Next'
                  : 'Add'
                : data?.length !== 0 || checked
                ? 'Finish'
                : 'Add'
              : data?.length !== 0 || checked
              ? 'Finish'
              : 'Add'} */}
          </LoadingButton>
        ) : (
          <LoadingButton
            color="primary"
            variant="contained"
            fullWidth
            id="workHistorySubmit"
            //   (ftue ? ftueLoading : submitLoader)
            onClick={submitLoader ? () => {} : () => (ftue && checked ? updatePopups() : handleSubmit())}
            loading={ftue ? false : submitLoader}
          >
            Save
          </LoadingButton>
        )}
      </DialogActions>

      {/* Remove Confirmation Popup */}
      <Dialog open={openDelete} onClose={() => setDelete(false)}>
        {/* Dialog Header */}
        <CommonModalHeader title="Confirmation" handleCloseModal={() => setDelete(false)} />
        <DialogContent>
          <Typography variant="h5" textAlign="center">
            Are you sure to remove this <br /> work history?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button fullWidth variant="outlined" color="secondary" onClick={() => setDelete(false)}>
            No
          </Button>

          {deleteLoader ? (
            <LoadingButton loading={deleteLoader} fullWidth>
              Yes, Remove
            </LoadingButton>
          ) : (
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={() => {
                handleDelete();
              }}
            >
              Yes, Remove
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WorkHistoryForm;
