/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable radix */
/* eslint-disable no-constant-binary-expression */
import React, { useRef } from 'react';
import _ from 'lodash';
import { Box, Grid, Divider, Typography } from '@mui/material';
import { useAppSelector } from '../../../../../redux/hooks';
import MessageComponent from './MessageComponent';
import moment from 'moment';
import { useAuth0 } from '@auth0/auth0-react';
import UpdateChatText from './UpdateChatText';
import { formatAcsUserWithCommunicationId, getAcsUserByCommunicationId } from '../../../../../routes/helpers';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ChatStyle } from '../ChatStyling';
import useProfileDetails from '../../../../../hooks/useProfileDetails';
import { ChatMsgContentLoader } from '../../../../../Loader/chat/ChatLoader';

interface IChatMessageContent {
  disableSendButton: boolean;
  data: any;
  file: any;
  isLoading: boolean;
  error: any;
  updateEditor: any;
  setEditCancel: any;
  handlePostMessage: (contentId: number) => void;
  fetchNextPage: any;
  hasNextPage: any;
  isFetchingNextPage: boolean;
  innerRef: any;
  disabled: boolean;
  editingMessageId: string;
  updateMessageLoading: boolean;
  channelName: string;
}

const ChatMessageContent: React.FC<IChatMessageContent> = ({
  disableSendButton,
  isLoading,
  data,
  error,
  updateEditor,
  setEditCancel,
  handlePostMessage,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
  innerRef,
  disabled,
  updateMessageLoading,
  editingMessageId,
  channelName
}) => {
  const { user } = useAuth0();
  const classes = ChatStyle();
  const acsOrgUsers = useAppSelector((state) => state.acsCommunityUsers.data);
  const formattedAcsOrgUsers: any = formatAcsUserWithCommunicationId(acsOrgUsers);
  const userProfile = useAppSelector((state) => state.userProfile.data);
  const { firstName, lastName } = useProfileDetails();
  const loginUserFullName = `${firstName} ${lastName}`;
  const loginUserCommunicationId = _.get(userProfile, 'personal_details.communicationId');
  const messagesEndRef: React.LegacyRef<HTMLDivElement> | undefined = useRef(null);
  // const acsChannelParticipants = useAppSelector(
  //   (state) => state.acsChannelParticipants
  // );
  const communicationId: any = _.trim(_.get(user, 'user_metadata.communicationId', ''));

  interface Message {
    id: number;
    text: string;
    createdOn: string;
    sequenceId: string;
    type: string;
  }

  interface MessageGroup {
    time: moment.Moment | string;
    messages: Message[];
  }

  /** Sort the messages by their timestamp */
  /** Group the messages by day */
  const messageGroups: MessageGroup[] = [];
  let currentGroup: MessageGroup | null = null;
  data.forEach((message: Message) => {
    if (_.get(message, 'sequenceId') !== '1') {
      if (_.get(message, 'sequenceId') === '2') {
        messageGroups.push({ time: 'Start of Chat', messages: [] });
      } else {
        const messageTime = moment(_.get(message, 'createdOn')).startOf('day');
        if (!currentGroup || !messageTime.isSame(currentGroup.time, 'day')) {
          currentGroup = { time: messageTime, messages: [] };
          messageGroups.push(currentGroup);
        }
        currentGroup?.messages?.push(message);
      }
    }
  });

  /** Format message date time */
  function formatMessageDate(timestamp: string) {
    const now = moment();
    const messageTime = moment(timestamp);

    if (messageTime.isSame(now, 'day')) {
      return 'Today';
    } else if (messageTime.isSame(now.clone().subtract(1, 'day'), 'day')) {
      return 'Yesterday';
    } else if (messageTime.isSame(now, 'week')) {
      return messageTime.format('dddd');
    } else {
      return messageTime.format('MMM D, YYYY');
    }
  }

  return (
    <Box
      ref={innerRef}
      flexGrow={1}
      display={'flex'}
      overflow={'auto'}
      id={'scrollableDiv'}
      flexDirection={isLoading ? 'column' : 'column-reverse'}
    >
      <InfiniteScroll
        dataLength={data.length}
        next={fetchNextPage}
        className={`
          ${classes.displayFlex}
          ${classes.overflowHidden}
          ${isLoading ? classes.flexDirectionColumn : classes.flexDirectionColumnReverse}
          p-0`}
        inverse={true}
        hasMore={true && !disabled}
        loader={
          (isFetchingNextPage && hasNextPage) || isLoading ? (
            <Box>
              <ChatMsgContentLoader />
            </Box>
          ) : (
            <Box />
          )
        }
        scrollableTarget="scrollableDiv"
      >
        {messageGroups.map((group: any, idx: number) => {
          return (
            <Box key={`${group.time}-${idx}`}>
              {group?.time === 'Start of Chat' ? (
                <UpdateChatText message={group?.time} />
              ) : (
                <Box
                  width="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  position="relative"
                  // height="30px"
                  paddingTop={3}
                  paddingBottom={3}
                >
                  <Divider className="w-100p" />

                  <Typography className={classes.StartOfChat} position="absolute">
                    {formatMessageDate(group.time)}
                  </Typography>
                </Box>
              )}

              {/* <UpdateChatText message={formatMessageDate(group.time)} /> */}
              {group.messages
                .sort((a: Message, b: Message) => parseInt(a.sequenceId) - parseInt(b.sequenceId))
                .map((each: any, index: number) => {
                  const messageType = () => {
                    const thirdPerson = formattedAcsOrgUsers[_.get(each, 'senderCommunicationIdentifier.rawId')];

                    return (
                      <>
                        <Grid item key={index} xs={12}>
                          <MessageComponent
                            editedOn={_.get(each, 'editedOn') ? moment(_.get(each, 'editedOn')).format('hh:mm a') : ''}
                            senderCommunicationId={_.get(each, 'senderCommunicationIdentifier.rawId', '')}
                            status={_.get(each, 'status', '')}
                            createdOn={moment(_.get(each, 'createdOn')).format('hh:mm a')}
                            message={each}
                            file={each}
                            who={
                              _.get(each, 'senderCommunicationIdentifier.rawId') !== loginUserCommunicationId
                                ? _.get(thirdPerson, 'displayName', '')
                                : loginUserFullName
                            }
                            updateEditor={updateEditor}
                            setEditCancel={setEditCancel}
                            handlePostMessage={handlePostMessage}
                          />
                        </Grid>

                        <Box ref={messagesEndRef} />
                      </>
                    );
                  };

                  const participantAddedType = () => {
                    return (
                      <>
                        {_.get(each, 'sequenceId') === '1' ? null : (
                          <UpdateChatText
                            message={`${
                              _.get(each, 'content.initiatorCommunicationIdentifier.rawId') === communicationId
                                ? 'You'
                                : _.get(
                                    getAcsUserByCommunicationId(
                                      acsOrgUsers,
                                      _.get(each, 'content.initiatorCommunicationIdentifier.rawId')
                                    ),
                                    'displayName',
                                    ''
                                  )
                            } added ${_.map(_.get(each, 'content.participants'), (each) =>
                              _.get(each, 'displayName')
                            ).join(',')}`}
                          />
                        )}
                      </>
                    );
                  };

                  const createChannel = (message: string) => {
                    return (
                      <UpdateChatText
                        message={
                          message
                            ? message
                            : `${
                                _.get(each, 'content.initiatorCommunicationIdentifier.rawId') === communicationId
                                  ? 'You'
                                  : _.get(
                                      getAcsUserByCommunicationId(
                                        acsOrgUsers,
                                        _.get(each, 'content.initiatorCommunicationIdentifier.rawId')
                                      ),
                                      'displayName',
                                      ''
                                    )
                              } ${_.get(each, 'content.topic', '') ? 'created' : 'updated'} this channel on ${moment(
                                _.get(each, 'createdOn')
                              ).format('MMMM Do, YYYY')}.  This is the very beginning of the ${_.get(
                                each,
                                'content.topic'
                              )} channel.`
                        }
                      />
                    );
                  };

                  const topicUpdatedType = (updatedPersonName: string) => {
                    return (
                      <UpdateChatText
                        message={
                          _.get(each, 'content.topic') === '#personal'
                            ? ` ${
                                _.get(
                                  getAcsUserByCommunicationId(
                                    acsOrgUsers,
                                    _.get(each, 'content.initiatorCommunicationIdentifier.rawId')
                                  ),
                                  'displayName',
                                  ''
                                )
                                  ? `
            
                                This conversation is just between 
                                    ${channelName}
                                and you
                                `
                                  : ''
                              }
                            
                            `
                            : `${
                                _.get(each, 'content.initiatorCommunicationIdentifier.rawId') === communicationId
                                  ? 'You'
                                  : updatedPersonName
                              } renamed the channel to ${_.get(each, 'content.topic')}
                                      `
                        }
                      />
                    );
                  };

                  const removeParticipantType = () => {
                    return (
                      <UpdateChatText
                        message={
                          _.get(
                            getAcsUserByCommunicationId(
                              acsOrgUsers,
                              _.get(each, 'content.initiatorCommunicationIdentifier.rawId')
                            ),
                            'displayName',
                            ''
                          ) + ' left the channel'
                        }
                      />
                    );
                  };

                  const checkAndReturn = () => {
                    if (_.get(each, 'sequenceId') === '1') {
                      return null;
                    } else if (_.get(each, 'sequenceId') === '2') {
                      const message = _.get(each, 'content.topic') === '#personal' ? `` : '';
                      return !message ? '' : createChannel(message);
                    } else if (_.get(each, 'type').toLowerCase() === 'text') {
                      return messageType();
                    } else if (_.get(each, 'type') === 'participantAdded') {
                      return participantAddedType();
                    } else if (_.get(each, 'type') === 'topicUpdated') {
                      const updatedPersonName = _.get(
                        formattedAcsOrgUsers[_.get(each, 'content.initiatorCommunicationIdentifier.rawId', '')],
                        'displayName'
                      );
                      return topicUpdatedType(updatedPersonName);
                    } else if (_.get(each, 'type') === 'participantRemoved') {
                      return removeParticipantType();
                    } else {
                      return null;
                    }
                  };
                  return <React.Fragment key={_.get(each, 'sequenceId', '')}>{checkAndReturn()}</React.Fragment>;
                })}
            </Box>
          );
        })}
      </InfiniteScroll>
    </Box>
  );
};

export default ChatMessageContent;
