import React, { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  Divider,
  Typography
} from '@mui/material';
import AvatarRow from '../Avatars';
import { useParams } from 'react-router';
import { API } from '../../../../../api';
import MyTooltip from '../../../components/MyTooltip';
import { dummyAvatarImage, formatNumberWithCommas } from '../../../components/common';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

interface TableDataItem {
  label?: string;
  value?: number;
}

const Table = ({ tableData }: { tableData?: TableDataItem[] }) => (
  <Box height="100%">
    {tableData?.map((item, index) => (
      <React.Fragment key={item?.label}>
        <Box display="flex" justifyContent="space-between">
          <Typography>{item?.label}</Typography>
          <Typography variant="h6">{item?.value}</Typography>
        </Box>
        {index < (tableData?.length || 0) - 1 && <Divider className='my-1' />}
      </React.Fragment>
    ))}
  </Box>
);

function ActiveUser({ activeUserCount }: { activeUserCount?: number }) {
  const [selectedGrid, setSelectedGrid] = useState(0);
  const [metricsValue, setMetricsValue] = useState<any>([]);

  const toggleGrid = (index: number) => {
    setSelectedGrid(index);
  };

  let grpId = useParams().id || '';
  if (grpId === 'admin') {
    grpId = '';
  }

  const currentURL = window.location.href; // Check if 'insight' is present in the URL
  const hasInsight = currentURL.includes('insight');

  const fetchMembersInsightValues = async (grpId: string) => {
    try {
      const response = await API.fetchMembersInsights(grpId);
      if (response.status === 200) {
        setMetricsValue(response?.data?.data?.[0]);
      } else {}
    } catch (e) {}
  };

  const tableDataValues = [
    { label: 'Unclaimed invites', value: metricsValue?.invitations || 0 },
    { label: 'Accepted invites', value: metricsValue?.allCount?.accepted || 0 },
    { label: 'Onboarded', value: metricsValue?.allCount?.onboarded || 0 },
    { label: 'Matched', value: metricsValue?.allCount?.matched || 0 },
    { label: 'Engaged', value: metricsValue?.allCount?.engaged || 0 },
    { label: 'Adopted', value: metricsValue?.allCount?.live || 0 } // For 'Live', no corresponding key found in metricsValues
  ];

  const gridValues = [
    { type: 'activeUser', activeUserCount: activeUserCount ?? 0 },
    {
      type: 'table',
      tableData: tableDataValues
    }
  ];

  useEffect(() => {
    if (hasInsight) {
      fetchMembersInsightValues(grpId);
    }
  }, [grpId, hasInsight]);

  return (
    <Box className="CardBox" p={2} height={'100%'}>
      <Box mb={1}>
        <Stack direction="row">
          <Typography variant="h6">Active Members</Typography>
          <MyTooltip
            gapleft={0.5}
            iconColor="#152536"
            title="The number of members in this group that have taken some action on their account in the last 30 days"
          />
        </Stack>
          <Typography variant="body2" color="#808080">
            Last 30 days
        </Typography>
      </Box>

      <Box height="178px" display="flex" flexDirection="column" justifyContent="center" gap="10px" overflow="auto">
        {selectedGrid === 0 ? (
          <>
            <Typography variant="h1" fontSize="60px" textAlign="center" noWrap>
              {formatNumberWithCommas(activeUserCount)}
            </Typography>
            <AvatarRow avatars={dummyAvatarImage} />
          </>
        ) : (
          <Box height="100%" pr={1}>
            <Table tableData={gridValues[1].tableData} />
          </Box>
        )}
      </Box>

      {/* Dots */}
      {gridValues[1].tableData && hasInsight && (
        <Box pt={0.5} px={2} width="100%" display="flex" alignItems="center" justifyContent="center">
          {gridValues[1].tableData &&
            gridValues[1].tableData.length > 0 &&
            [0, 1].map((index) => (
              <FiberManualRecordIcon
                key={index}
                onClick={() => toggleGrid(index)}
                className='cursor-pointer fs-16'
                color={selectedGrid === index ? 'primary' : 'secondary'}
              />
            ))}
        </Box>
      )}
    </Box>
  );
}

export default ActiveUser;
